import { fetchAudits } from "../audits/auditsSlice";

const initialState = {
  start: new Date(Date.now() - 1000 * 60 * 60 * 24 * 7).toString(), // 7 days
  end: new Date(Date.now() + 1000 * 60 * 60 * 24 * 30).toString(), // 30 days
  selectedDepartment: null,
  filter: "0",
  auditViewType: "card", // list, card, calendar
  activityTab: "audits", // audits, fieldTours, workshops, meetings
};

export default function auditViewReducer(state = initialState, action) {
  switch (action.type) {
    case "auditView/auditViewDepartmentChanged": {
      return { ...state, ...action.payload };
    }
    case "auditView/auditViewTypeChanged": {
      return { ...state, ...action.payload };
    }
    case "auditView/auditViewTabChanged": {
      return { ...state, ...action.payload };
    }
    case "auditView/auditViewFilterDate": {
      return { ...state, ...action.payload };
    }
    case "auditView/auditViewFilterChanged": {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
}

export function changeAuditViewType(dispatch, type) {
  dispatch({
    type: "auditView/auditViewTypeChanged",
    payload: { auditViewType: type },
  });
}

export function changeAuditViewTab(dispatch, tab) {
  dispatch({
    type: "auditView/auditViewTabChanged",
    payload: { activityTab: tab },
  });
}

export function changeAuditViewFilterType(dispatch, type) {
  dispatch({
    type: "auditView/auditViewFilterChanged",
    payload: { filter: type },
  });
}

export function changeAuditViewDepartment(dispatch, department) {
  dispatch({
    type: "auditView/auditViewDepartmentChanged",
    payload: { selectedDepartment: department },
  });
}

export function changeAuditFilterDate(dispatch, start, end) {
  dispatch({
    type: "auditView/auditViewFilterDate",
    payload: { start: start, end: end },
  });
  dispatch(fetchAudits);
}

export const selectAuditView = (state) => state.ui.auditView;
