import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import { useState } from "react";
import { useEffect } from "react";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import DepartmentService from "../../services/DepartmentServices";

import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import { AvatarGroup, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import stringAvatar from "./avatarFunctions";
import { useDispatch } from "react-redux";
import { showNotification } from "../../features/ui/notificationSlice";

const Department = (props) => {
  const [name, setName] = useState(props.node.title);
  const [checked, setChecked] = useState(props.node.Auditable);
  const dispatch = useDispatch();

  function update() {
    DepartmentService.update({
      ...props.node,
      Adi: name,
      Auditable: Number(checked),
    })
      .then(() => {
        showNotification(dispatch, `Department updated succesfully!`);
      })
      .catch(() => {
        showNotification(
          dispatch,
          `Department could not be updated. Try again later.`,
          "danger",
        );
      })
      .finally(() => {
        props.updateCallback();
      });
  }

  useEffect(() => {
    if (props.update && props.currentNode === props.node.Id) {
      update();
    }
  }, [props.update]);

  return props.editing ? (
    <Stack gap={2}>
      <TextField
        size="small"
        label="Department Name"
        onChange={(event) => {
          const name = event.target.value;
          setName(name);
          props.onSetData?.({ Adi: name, Auditable: checked });
        }}
        value={name}
        variant="standard"
      />
      <Stack direction="row">
        <Typography>Denetlenebilir: </Typography>
        <Switch
          checked={checked}
          onChange={(event) => {
            const checked = event.target.checked;
            setChecked(checked);
            props.onSetData?.({ Adi: name, Auditable: checked });
          }}
        />
      </Stack>
    </Stack>
  ) : (
    <Stack spacing={1}>
      <div>
        {props.node.Auditable ? <TroubleshootIcon /> : ""}
        {props.node.title}
      </div>
      <AvatarGroup
        sx={{
          fontSize: 10,
        }}
        max={4}
      >
        {props.node.Users.split(",").map((userId) => {
          const user = props.users.find((user) => user.Id === Number(userId));
          if (user)
            return (
              <Avatar
                component={Link}
                key={user.Id}
                style={{ textDecoration: "none" }}
                to={`/users/${user.Id}`}
                {...stringAvatar(user.LongName)}
              />
            );
        })}
      </AvatarGroup>
    </Stack>
  );
};

export default Department;
