import React from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { useSelector, useDispatch } from "react-redux";
import { selectNotification } from "../../features/ui/notificationSlice";

function NotificationToast() {
  const dispatch = useDispatch();
  const notification = useSelector(selectNotification);

  const handleClose = () => {
    dispatch({
      type: "ui/notificationShow",
      payload: { showNotification: false, notificationMessage: "" },
    });
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );
  return (
    <Snackbar
      onClose={handleClose}
      open={notification.showNotification}
      autoHideDuration={3000}
      message={notification.notificationMessage}
      action={action}
    />
  );
}
export default NotificationToast;
