import { MAIN_URL } from "./ServiceConstants";
import axios from "axios";

const departmentUrl = MAIN_URL + "/Quality/SetPortalDepartmentStatistics";
const userUrl = MAIN_URL + "/Quality/SetPortalUserStatistics";
const scoresUrl = MAIN_URL + "/Quality/GetDepAuditScore";

async function getPortalDepartmentStatistics(departmentId, startDate, endDate) {
  return axios.post(departmentUrl, {
    dataobject: {
      DepartmentId: departmentId,
      StartDate: startDate,
      EndDate: endDate,
    },
  });
}


async function getPortalUserStatistics(inChargeUserId) {
  return axios.post(userUrl, {
    dataobject: {
      InchargeUserId: inChargeUserId,
    },
  });
}

async function getPeriodicScoresForDepartments(start, end) {
  let dataObject = {};
  if (start !== undefined && end !== undefined) {
    dataObject = {
      StartDate: start,
      EndDate: end,
    };
  }
  return axios.post(scoresUrl, {
    dataobject: dataObject,
  });
}

async function getPeriodicScoresForDepartmentsYear(year) {
  const dataObject = {
    Year: year,
  };
  return axios.post(scoresUrl, {
    dataobject: dataObject,
  });
}

const PortalStatisticsService = {
  getPortalUserStatistics,
  getPortalDepartmentStatistics,
  getPeriodicScoresForDepartments,
  getPeriodicScoresForDepartmentsYear,
};

export default PortalStatisticsService;
