import * as React from "react";
import {
  Autocomplete,
  TextField,
  useTheme,
  useMediaQuery,
  Divider,
  Slide,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { selectDepartments } from "../../../features/departments/departmentsSlice";
import { selectAuditView } from "../../../features/ui/auditViewSlice";
import {
  changeAuditFilterDate,
  changeAuditViewFilterType,
} from "../../../features/ui/auditViewSlice";
import { changeAuditViewDepartment } from "../../../features/ui/auditViewSlice";
import { Stack } from "@mui/material";
import { FormControl } from "@mui/material";
import { ToggleButtonGroup, ToggleButton, Box } from "@mui/material";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import ViewStreamIcon from "@mui/icons-material/ViewStream";
import { changeAuditViewType } from "../../../features/ui/auditViewSlice";
import { Chip } from "@mui/material";
import PrettyDepartment from "../../common/PrettyDepartment";
import YearMonthSelector from "../../common/YearMonthSelector";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

export default function AuditFilter() {
  const dispatch = useDispatch();
  const { selectedDepartment, filter } = useSelector(selectAuditView);
  const departments = useSelector(selectDepartments);
  const { auditViewType } = useSelector(selectAuditView);
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const containerRef = React.useRef(null);
  const {t} = useTranslation()

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      changeAuditViewType(dispatch, newAlignment);
    }
  };

  const handleFilterChange = (type) => {
    changeAuditViewFilterType(dispatch, type);
  };

  function generateProps(num) {
    return {
      sx: { m: 1 },
      size: filter == num || (num != 5 && filter == 0) ? "medium" : "small",
      variant:
        filter == num || (num != 5 && filter == 0) ? "filled" : "outlined",
      onClick: () => handleFilterChange(num),
    };
  }

  return (
    <Stack spacing={2}>
      <Stack alignItems="center">
        <Box
          ref={containerRef}
          sx={{
            display: "flex",
            flexWrap: "wrap",
            p: 1,
            m: 1,
            bgcolor: "background.paper",
            borderRadius: 1,
          }}
        >
          <Chip label={t('ui.all')} color="neutral" {...generateProps("0")} />
          <Chip
            label={t("ui.hasFindings")}
            color="yellow"
            {...generateProps("1")}
          />
          <Chip label={t('ui.completed')} color="success" {...generateProps("2")} />
          <Chip label={t('ui.expired')} color="error" {...generateProps("3")} />
          <Chip
            label={t("ui.aboutToExpire")}
            color="warning"
            {...generateProps("4")}
          />
          <Divider orientation="vertical" flexItem />
          <Chip
            label={t('ui.allTimesExpired')}
            color="secondary"
            {...generateProps("5")}
          />
        </Box>
      </Stack>

      <Slide in={filter !== "5"} container={containerRef.current} unmountOnExit>
        <div>
          <YearMonthSelector
            onChange={(date) =>
              changeAuditFilterDate(
                dispatch,
                dayjs(date).startOf("month").toISOString(),
                dayjs(date).endOf("month").toISOString(),
              )
            }
          />
        </div>
      </Slide>
      <Stack gap={2} direction={smallScreen ? "column" : "row"}>
        <ToggleButtonGroup
          sx={{ padding: 1, margin: 3 }}
          value={auditViewType}
          exclusive
          onChange={handleAlignment}
          aria-label="text alignment"
        >
          <ToggleButton value="list" aria-label="left aligned">
            <FormatListNumberedIcon />
          </ToggleButton>
          <ToggleButton value="card" aria-label="centered">
            <ViewStreamIcon />
          </ToggleButton>
          {/* <ToggleButton value="calendar" aria-label="right aligned"> */}
          {/*   <CalendarMonthIcon /> */}
          {/* </ToggleButton> */}
        </ToggleButtonGroup>

        <FormControl fullWidth>
          <Autocomplete
            options={[...departments].sort((a, b) => {
              if (a.Adi > b.Adi) return 1;
              else if (a.Adi < b.Adi) return -1;
              else return 0;
            })}
            isOptionEqualToValue={(option, value) => option.Id === value.Id}
            getOptionLabel={(d) => d.Adi}
            value={selectedDepartment}
            onChange={(event, newValue) => {
              changeAuditViewDepartment(dispatch, newValue);
            }}
            renderOption={(props, option, { selected }) => {
              return (
                <li {...props}>
                  <PrettyDepartment department={option} />
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField {...params} label={t('ui.department')} variant="standard" />
            )}
          />
        </FormControl>
      </Stack>
    </Stack>
  );
}
