import { MAIN_URL } from "./ServiceConstants";
import axios from "axios";

const settingsUrl = MAIN_URL + "/Quality/SetFactorySettings";
const parametersUrl = MAIN_URL + "/Quality/SetParameters";

async function getSettings() {
  return axios.post(settingsUrl, {
    dataobject: {
      Action: "S",
    },
  });
}

async function getParameters() {
  return axios.post(parametersUrl, {
    dataobject: {
      Action: "S",
    },
  });
}

async function updateSettings(data) {
  return axios.post(settingsUrl, {
    dataobject: {
      Action: "U",
      ...data,
    },
  });
}

async function updateParameters(data) {
  return axios.post(parametersUrl, {
    dataobject: {
      Action: "U",
      ...data,
    },
  });
}

const FactorySettingsService = {
  getSettings,
  getParameters,
  updateSettings,
  updateParameters,
};

export default FactorySettingsService;
