import { useState } from "react";
import Button from "@mui/material/Button";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Stack, TextField } from "@mui/material";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FindingsService from "../../services/FindingsService";
import { selectUsers } from "../../features/users/usersSlice";
import { useDispatch, useSelector } from "react-redux";
import { dialogActions, selectDialog } from "../../features/ui/dialogsSlice";
import { fetchFindings } from "../../features/findings/findingsSlice";
import { isFindingCompleted } from "../../utils/commonFunctions";
import { LoginService } from "../../services";

export default function TransferFindingDialog(props) {
  const users = useSelector(selectUsers).users;
  const currentFinding = useSelector(selectDialog).currentFinding;
  const approving = isFindingCompleted(currentFinding);
  const currentUser = LoginService.getUser().UserId;
  const open = useSelector(selectDialog).transferFinding;
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const defaultValues = {
    transferUserId: "",
    date: "",
    description: "",
  };
  const [formValues, setFormValues] = useState(defaultValues);

  function hideDialog() {
    dispatch(dialogActions.toggleTransferFinding());
    setFormValues(defaultValues);
    setError(false);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (approving) {
      FindingsService.approveFinding({
        ...formValues,
        findingId: currentFinding.Id,
      }).then(() => {
        dispatch(fetchFindings());
        hideDialog();
      });
    } else {
      FindingsService.transferFinding({
        ...formValues,
        findingId: currentFinding.Id,
      }).then(() => {
        dispatch(fetchFindings());
        hideDialog();
      });
    }
    console.log(formValues);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>
        {approving ? "Bulguyu onayla" : "Bulguyu yönlendir"}
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Stack gap={2}>
            <FormControl
              fullWidth
              required
              error={!approving && error && !formValues.transferUserId}
            >
              <InputLabel>
                {approving ? "Onaylayan" : "Yeni Sorumlu"}
              </InputLabel>
              <Select
                size="small"
                value={approving ? currentUser : formValues.transferUserId}
                readOnly={Boolean(approving)}
                label={approving ? "Onaylayan" : "Yeni Sorumlu"}
                name="transferUserId"
                onChange={handleInputChange}
              >
                {users.map((user) => {
                  return (
                    <MenuItem key={user.Id} value={user.Id}>
                      {user.LongName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={approving ? "Onaylanma Tarihi" : "Yeni Termin Tarihi"}
                inputFormat="DD/MM/YYYY"
                value={approving ? new Date() : formValues.date}
                readOnly={Boolean(approving)}
                onChange={(date) => {
                  setFormValues({ ...formValues, date: date });
                }}
                renderInput={(params) => (
                  <TextField
                    required
                    error={!approving && error && !formValues.date}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
            <TextField
              label="Açıklama"
              name="description"
              required={Boolean(!approving)}
              error={error && !formValues.description}
              onChange={handleInputChange}
              multiline
              rows={4}
              value={formValues.description}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={hideDialog}>
            Cancel
          </Button>
          <Button
            variant="outlined"
            type="submit"
            onClick={() => setError(true)}
          >
            Ok
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
