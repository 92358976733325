import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Stack, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { useDispatch } from "react-redux";
import { showConfirmDialog } from "../../../features/ui/confirmDialogSlice";
import { addPeriodicAudit } from "../../../features/audits/auditsSlice";
import dayjs from "dayjs";

export default function InsertPeriodicAuditDialog(props) {
  const defaultValues = {
    start: dayjs().format("YYYY/MM/DD"),
    end: dayjs().add(1, "month").format("YYYY/MM/DD"),
    period: 1,
  };

  const [formValues, setFormValues] = useState(defaultValues);
  const dispatch = useDispatch();

  function hideDialog() {
    props.onClose();
    setFormValues(defaultValues);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formValues);
    const confirmed = await showConfirmDialog(
      dispatch,
      "Periyodik denetim oluşturmayı onaylıyor musunuz?",
    );

    if (!confirmed) return;

    addPeriodicAudit(dispatch, formValues).then(() => hideDialog());
    console.log(formValues);
  };

  const handleInputChange = (e) => {
    console.log(e.target);
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  return (
    <Dialog fullWidth="true" open={props.show}>
      <DialogTitle>Yeni Periodic Denetim Ekle</DialogTitle>
      <DialogContent style={{ height: "600px" }} dividers={true}>
        <Stack gap={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Başlangıç Tarihi"
              inputFormat="DD/MM/YYYY"
              value={formValues.start}
              onChange={(date) => {
                setFormValues({
                  ...formValues,
                  start: dayjs(date).format("YYYY/MM/DD"),
                });
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Bitiş Tarihi"
              inputFormat="DD/MM/YYYY"
              value={formValues.end}
              onChange={(date) => {
                setFormValues({
                  ...formValues,
                  end: dayjs(date).format("YYYY/MM/DD"),
                });
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>

          <FormControl fullWidth>
            <InputLabel id="select-label">Periyot</InputLabel>
            <Select
              labelId="select-label"
              id="simple-select"
              name="period"
              value={formValues.period}
              label="Periyot"
              onChange={handleInputChange}
            >
              <MenuItem value={1}>Haftalık</MenuItem>
              <MenuItem value={2}>İki Haftalık</MenuItem>
              <MenuItem value={3}>Aylık</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={hideDialog}>
          Cancel
        </Button>
        <Button variant="outlined" onClick={handleSubmit}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
