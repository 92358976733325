import { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { selectDialog, dialogActions } from "../../features/ui/dialogsSlice";
import { useDispatch, useSelector } from "react-redux";
import MultiUserSelect from "../common/MultiUserSelect";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Stack, TextField, Typography } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { AuditService } from "../../services";
import { selectDepartments } from "../../features/departments/departmentsSlice";
import { fetchAudits } from "../../features/audits/auditsSlice";
import {LoginService} from "../../services";
import { useTranslation } from "react-i18next";

export default function CreateFieldTourDialog() {
  const currentUserID = LoginService.getUser()?.UserId;
  const dialogVisibility = useSelector(selectDialog);
  const dispatch = useDispatch();
  const [userList, setUserList] = useState([]);
  const [date, setDate] = useState(new Date());
  const departments = useSelector(selectDepartments);
  const { t } = useTranslation()

  function hideDialog() {
    dispatch(dialogActions.toggleCreateFieldTour());
  }

  useEffect(() => {
    setDate(new Date());
  }, [dialogVisibility.createFieldTour]);

  function handleSubmit(e) {
    e.preventDefault();
    const master = departments.find((d) => d.master_id == 0).Id;
    const participants = userList.map((u) => u.Id).join(",");
    AuditService.createFieldTour({
      date,
      departmentId: master,
      participants,
      auditorUser: currentUserID,
    }).then(dispatch(fetchAudits));
  }
  return (
    <div>
      <Dialog open={dialogVisibility.createFieldTour} fullWidth>
        <DialogTitle>Saha Turu Ekle</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Stack gap={2}>
              <MultiUserSelect
                onChangeUserList={(i) => setUserList(i)}
                value={userList}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="Başlama Zamanı"
                  required
                  inputFormat="L LT"
                  value={date}
                  onChange={(date) => {
                    setDate(date);
                  }}
                  renderInput={(params) => <TextField required {...params} />}
                />
              </LocalizationProvider>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="secondary" onClick={hideDialog}>
              {t('buttons.cancel')}
            </Button>
            <Button variant="outlined" type="submit" onClick={hideDialog}>
              {t('buttons.createFieldTour')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
