import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  selectUsersAuditPerformance,
  selectUsersVisibleToCurrentUser,
} from "../../features/users/usersSlice";
import {
  getImageUrl,
  sumArrayValues,
  sumSubArrayValues,
} from "../../utils/commonFunctions";
import { useTranslation } from "react-i18next";
import {
  Avatar,
  Button,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { renderProgress } from "../common/ProgressBar";
import { DataGrid } from "@mui/x-data-grid";
import { selectSettings } from "../../features/settings/settingsSlice";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Legend from "./Legend";
import { selectFilterUser } from "../../features/ui/listFiltersSlice";

function Title({ title, year, month, yearly }) {
  const settings = useSelector(selectSettings);
  const { t } = useTranslation();
  return (
    <Stack justifyContent="space-between" direction="row">
      <img
        height={50}
        src={getImageUrl(settings.Logo)}
        alt={settings.FactoryName}
      />
      <h4>{title}</h4>
      <Stack>
        <Button>{t("buttons.export2Pdf")}</Button>
        <Typography sx={{ fontWeight: "bold", textDecoration: "underline" }}>
          {yearly ? "" : t(`months.${month}`)} {year}
        </Typography>
      </Stack>
    </Stack>
  );
}

export default function GeneralPerformance(props) {
  const performance = useSelector(selectUsersAuditPerformance);
  const selectedUser = useSelector(selectFilterUser)
  const usersVisibleToCurrentUser = useSelector(
    selectUsersVisibleToCurrentUser
  );
  const filteredUsers = selectedUser == null ? usersVisibleToCurrentUser : [selectedUser]
  const { t } = useTranslation();
  const performanceConstants = {
    managers: 1 / 6,
    inchargeUsers: 1 / 6,
    auditors: 1,
    findings: 100,
    participants: 1,
  };

  const monthlyData = (type, month) =>
    performance[type]
      .filter(
        (u) => u.M == month + 1 && filteredUsers.find(m=>m.Id == u.userID)
      )
      .map((u) => {
        return {
          userID: u.userID,
          id: u.id,
          photo: u.photo,
          LongName: u.LongName,
          performance:
            u.Score == 0 ? 0 : (u.Score / u.Cnt) * performanceConstants[type],
        };
      });
  function getMonthlyPerformance(month) {
    const arr = [
      ...monthlyData("managers", month),
      ...monthlyData("inchargeUsers", month),
      ...monthlyData("auditors", month),
      ...monthlyData("findings", month),
    ];
    const performanceData = sumArrayValues(arr, "userID");
    const participants = monthlyData("participants", month);
    return performanceData.map((u) => {
      const user = participants.find((p) => p.userID == u.userID);
      let participationRate = 1;
      if (user) {
        participationRate = user.performance;
      }
      const performance = (u.performance / u.totalCount) * participationRate;
      const result = { ...u, performance: performance };
      delete result.totalCount;
      return result;
    });
  }

  function getYearlyPerformance() {
    const monthlyPerformances = [];
    for (let i = 0; i < 12; i++) {
      monthlyPerformances.push(...getMonthlyPerformance(i));
    }
    const performanceData = sumArrayValues(monthlyPerformances, "userID");
    return performanceData.map((u) => {
      const performance = u.performance / u.totalCount;
      const result = { ...u, performance: performance };
      delete result.totalCount;
      return result;
    });
  }

  const [sortModel, setSortModel] = useState([
    {
      field: "performance",
      sort: "desc",
    },
  ]);

  function getPerformanceTypeForCurrentUser(type, userId) {
    return performance[type].find(
      (u) => u.userID == userId && u.M == props.currentMonth + 1
    );
  }

  function createCellWithTooltip(userData, cellData) {
    Object.keys(userData).forEach(key => {
      if (userData[key] === undefined) {
        delete userData[key];
      }
    });
    const tooltipTitle = Object.entries(userData).map(([k,v]) => {
      const score = v.Score
      const count = v.Cnt

      return t(`performances.singleUser.${k}`) + ': %' + (score / count * performanceConstants[k]).toFixed(2)
    });
    return <Tooltip followCursor title={<Stack>{tooltipTitle.map(i=><div>{i}</div>)}</Stack>}>{cellData}</Tooltip>
  }

  const columns = [
    {
      headerName: t("ui.nameSurname"),
      field: "LongName",
      flex: 1,
      renderCell: (params) => {
        const cellData = <Stack
            direction="row"
            gap={1}
            className="pointer"
            onClick={async () => {
              props.onSelectUser(params.row);
            }}
          >
            <Avatar
              sx={{ width: 28, height: 28 }}
              alt={params.value}
              src={getImageUrl(params.row.photo)}
            />
            <Typography variant="p">{params.value}</Typography>
          </Stack>
        if (yearly) {
          return cellData
        }
        const userData = {
          managers: getPerformanceTypeForCurrentUser("managers", params.row.userID),
          auditors: getPerformanceTypeForCurrentUser("auditors", params.row.userID),
          inchargeUsers: getPerformanceTypeForCurrentUser("inchargeUsers", params.row.userID),
          findings: getPerformanceTypeForCurrentUser("findings", params.row.userID),
        };

        return createCellWithTooltip(userData, cellData)
      },
    },
    {
      headerName: t("ui.performance"),
      renderHeader: (params) => (
        <Tooltip
          title={t("performances.generalPerformanceInfo")}
          style={{ display: "flex", alignItems: "center" }}
        >
          <div>
            {params.colDef.headerName}{" "}
            <InfoOutlinedIcon
              fontSize="small"
              style={{ color: "blue", marginLeft: 4 }}
            />
          </div>
        </Tooltip>
      ),
      type: "number",
      field: "performance",
      valueGetter: (params) => params.value.toFixed(2),
      flex: 1,
      renderCell: renderProgress,
    },
  ];
  const yearly = props.yearly;
  const data = yearly
    ? getYearlyPerformance()
    : getMonthlyPerformance(props.currentMonth);
  return (
    <Stack component={Paper} gap={1} elevation={12}>
      <DataGrid
        autoHeight
        rows={data}
        components={{ Toolbar: Title }}
        componentsProps={{
          toolbar: {
            title: t(`performances.general`),
            month: props.currentMonth,
            year: props.currentYear,
            yearly: yearly,
          },
        }}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        columns={columns}
        getRowId={(row) => row.id}
        hideFooter={true}
      />
      <Legend />
    </Stack>
  );
}
