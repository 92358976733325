import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import PortalStatisticsService from "../../services/PortalStatisticsService";
import { filterByAllFilters } from "../findings/findingsSlice";

export const fetchDepartmentStatistics = createAsyncThunk(
  "findingStats/fetchDepartmentStatistics",
  async ({ department, start, end }) => {
    const response =
      await PortalStatisticsService.getPortalDepartmentStatistics(
        department?.Id,
        start,
        end,
      );
    return response;
  },
);

export const fetchUserStatistics = createAsyncThunk(
  "findingStats/fetchUserStatistics",
  async (data) => {
    const response = await PortalStatisticsService.getPortalUserStatistics(
      data,
    );
    return response.Table[0];
  },
);

export const fetchPeriodicScoresForDepartments = createAsyncThunk(
  "findingStats/fetchPeriodicScoresForDepartments",
  async (start, end) => {
    const response =
      await PortalStatisticsService.getPeriodicScoresForDepartments(start, end);
    return response.Table;
  },
);

const initialState = {
  stats: {
    Total: 0,
    Completed: 0,
    Competable: 0,
    NotCompetable: 0,
  },
  findings: [],
  activities: [],
  periodicScores: [],
};

const findingStatsSlice = createSlice({
  name: "findingStats",
  initialState,
  reducers: {
    setFindingStats: (state, action) => {
      state.stats = action.payload;
    },
  },

  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchDepartmentStatistics.fulfilled, (state, action) => {
      state.stats = action.payload.Table[0];
      state.findings = action.payload.Table1;
      state.activities = action.payload.Table2;
    });

    builder.addCase(fetchUserStatistics.fulfilled, (state, action) => {
      state.stats = action.payload;
    });

    builder.addCase(
      fetchPeriodicScoresForDepartments.fulfilled,
      (state, action) => {
        state.periodicScores = action.payload;
      },
    );
  },
});

export const { setFindingStats } = findingStatsSlice.actions;
export const selectFindingStats = (state) => state.ui.findingStats.stats;
export const selectFindingList = (state) => state.ui.findingStats.findings;
export const selectFilteredFindingList = (state) =>
  filterByAllFilters(state, state.ui.findingStats.findings);
export const selectPeriodicScores = (state) =>
  state.ui.findingStats.periodicScores;
export default findingStatsSlice.reducer;
