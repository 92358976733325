import { Container, Stack } from "@mui/material";

import FactorySettings from "./FactorySettings";
import ParametersTab from "./ParametersTab";

const Settings = () => {
  return (
    <Container>
      <h1>Settings</h1>
      <Stack gap={3}>
        <FactorySettings />
        <ParametersTab />
      </Stack>
    </Container>
  );
};

export default Settings;
