import React from "react";
import { useTable } from "react-table";
import { useState } from "react";
import { useFilters, useSortBy } from "react-table";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import QuestionModal from "./QuestionModal";
import { FiveSService } from "../../services";
import { useEffect } from "react";
import AddMasterQuestionModal from "./AddMasterQuestionModal";
import { useDispatch } from "react-redux";
import { showNotification } from "../../features/ui/notificationSlice";
import { useMemo } from "react";
import { showConfirmDialog } from "../../features/ui/confirmDialogSlice";
import {
  Paper,
  Grid,
  Chip,
  Typography,
  Divider,
  Button,
  Checkbox,
} from "@mui/material";
import {
  Box,
  Container,
  List,
  ListItem,
  ListItemText,
  IconButton,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Stack } from "@mui/material";
import FlagIcon from "@mui/icons-material/Flag";
import FlagBorderIcon from "@mui/icons-material/Flag";
import { FiveSGroups, getFiveSTypeColor } from "../../utils/commonFunctions";

export default function QuestionPool() {
  const [filterInput, setFilterInput] = useState("");
  const [data, setQuestions] = useState([]);
  const [filteredType, setFilteredType] = useState("");
  const dispatch = useDispatch();
  const FiveSTypes = [
    "TERTIP",
    "DUZEN",
    "TEMIZLIK",
    "STANDARTLASTIRMA",
    "DISIPLIN",
    "ISG",
  ];

  function refreshData() {
    FiveSService.getAllQuestions().then((response) => setQuestions(response));
  }

  const handleFilterChange = (e) => {
    const value = e.target.value;
    setFilterInput(value);
  };

  useEffect(() => {
    refreshData();

    return () => {};
  }, []);

  const Render5SInfo = ({ type }) => {
    const questions = data.filter((t) => t.FiveSType == type);
    const count = questions.length;
    const subCount = questions.reduce((c, q) => c + q.Count, 0);

    return (
      <Paper sx={{ padding: 1 }} elevation={3} square>
        <Chip
          label={type}
          variant={filteredType == type ? "filled" : "outlined"}
          color={getFiveSTypeColor(type)}
          onClick={() => {
            if (filteredType != type) {
              setFilteredType(type);
            } else {
              setFilteredType("");
            }
          }}
        />
        <Divider sx={{ margin: 1 }}></Divider>
        <Typography>Temel Soru: {count}</Typography>
        <Divider sx={{ margin: 1 }}></Divider>
        <Typography>Alt Soru:{subCount}</Typography>
      </Paper>
    );
  };

  /* 
    Render the UI for your table
    - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
  */
  return (
    <>
      <AddMasterQuestionModal
        onAddNewQuestionCallback={() => {
          refreshData();
        }}
      />

      <Container
        sx={{ width: "100%", maxWidth: 720, bgcolor: "background.paper" }}
      >
        <Box
          width="100%"
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            p: 1,
            m: 1,
            bgcolor: "background.paper",
            borderRadius: 1,
            gap: 3,
          }}
        >
          {FiveSTypes.map((t) => (
            <Render5SInfo key={t} type={t} />
          ))}
        </Box>

        <TextField
          fullWidth
          color="primary"
          variant="outlined"
          placeholder="Ara"
          value={filterInput}
          onChange={handleFilterChange}
        />
        <Paper elevation={3}>
          <List dense>
            {data.length > 0 &&
              data
                .filter((item) => {
                  return (
                    (filteredType == "" || item.FiveSType == filteredType) &&
                    (filterInput == "" ||
                      item.question
                        .toLowerCase()
                        .includes(filterInput.toLowerCase()))
                  );
                })
                .map((mainQuestion) => (
                  <ListItem key={mainQuestion.Id}>
                    <Stack
                      width="100%"
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Stack direction="row">
                        <ListItemText
                          primary={mainQuestion.question}
                          secondary={"Alt soru sayısı: " + mainQuestion.Count}
                        />
                      </Stack>

                      <Stack direction="row">
                        <Checkbox
                          checked={mainQuestion.Mandatory == 1}
                          onChange={async (event) => {
                            FiveSService.updateQuestion(
                              mainQuestion.Id,
                              mainQuestion.question,
                              mainQuestion.master_id,
                              mainQuestion.FiveSType,
                              event.target.checked ? 1 : 0,
                            ).then((response) => {
                              if (response[0].Mandatory == 1) {
                                showNotification(
                                  dispatch,
                                  "Question is marked as mandatory",
                                );
                              }
                              refreshData();
                            });
                          }}
                          icon={<FlagBorderIcon />}
                          checkedIcon={<FlagIcon />}
                        />
                        <FiveSGroups value={mainQuestion.FiveSType} />
                        <QuestionModal
                          className="m-2"
                          questionId={mainQuestion.Id}
                        />
                        <IconButton
                          color="error"
                          edge="end"
                          aria-label="delete"
                          onClick={async () => {
                            const confirmed = await showConfirmDialog(
                              dispatch,
                              "Are you sure?",
                            );
                            if (confirmed) {
                              FiveSService.deleteQuestion(mainQuestion.Id).then(
                                () => {
                                  showNotification(
                                    dispatch,
                                    "Question deleted succesfully",
                                  );
                                  refreshData();
                                },
                              );
                            }
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Stack>
                    </Stack>
                  </ListItem>
                ))}
          </List>
        </Paper>
      </Container>
    </>
  );
}
