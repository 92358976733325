import RoleService from "../../services/RolesServices";

const initialState = [];

export default function rolesReducer(state = initialState, action) {
  switch (action.type) {
    case "roles/rolesLoaded": {
      return action.payload;
    }
    default:
      return state;
  }
}

export async function fetchRoles(dispatch, getState) {
  const response = await RoleService.getAll();
  dispatch({ type: "roles/rolesLoaded", payload: response });
}

export const selectRoles = (state) => state.roles;
