import { combineReducers, configureStore } from "@reduxjs/toolkit";
import departmentsReducer from "./features/departments/departmentsSlice";
import notificationReducer from "./features/ui/notificationSlice";
import usersReducer from "./features/users/usersSlice";
import questionsReducer from "./features/questions/questionsSlice";
import deviationsReducer from "./features/deviations/deviationsSlice";
import findingsReducer from "./features/findings/findingsSlice";
import modalDialogReducer from "./features/ui/confirmDialogSlice";
import modalDialogWithInputReducer from "./features/ui/confirmDialogWithInputSlice";
import rolesReducer from "./features/roles/rolesSlice";
import roleUsersReducer from "./features/roleUsers/roleUsersSlice";
import commonReducer from "./features/common";
import auditsReducer from "./features/audits/auditsSlice";
import auditViewReducer from "./features/ui/auditViewSlice";
import findingFiltersReducer from "./features/ui/findingFiltersSlice";
import findingStatsReducer from "./features/ui/findingStatsSlice";
import listFiltersReducer from "./features/ui/listFiltersSlice";
import currentAuditReducer from "./features/audits/currentAuditSlice";
import dialogReducer from "./features/ui/dialogsSlice";
import settingsReducer from "./features/settings/settingsSlice";

const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ["modalDialog/show"],
      },
    }),
  reducer: {
    departments: departmentsReducer,
    user: usersReducer,
    finding: findingsReducer,
    roles: rolesReducer,
    roleUsers: roleUsersReducer,
    audits: auditsReducer,
    settings: settingsReducer,
    questions: questionsReducer,
    deviations: deviationsReducer,
    ui: combineReducers({
      notification: notificationReducer,
      confirmModal: modalDialogReducer,
      confirmModalWithInput: modalDialogWithInputReducer,
      auditView: auditViewReducer,
      findingFilters: findingFiltersReducer,
      findingStats: findingStatsReducer,
      listFilters: listFiltersReducer,
      dialog: dialogReducer,
    }),
    currentAudit: currentAuditReducer,
    common: commonReducer,
  },
});

export default store;
