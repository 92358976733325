import React from "react";
import { useState } from "react";
import { dialogActions, selectDialog } from "../../../features/ui/dialogsSlice";
import { AuditService } from "../../../services";
import { useEffect } from "react";
import {
  Button,
  Box,
  Typography,
  Paper,
  Stack,
  CircularProgress,
  Slide,
  Container,
} from "@mui/material";
import AuditQuestionComponent from "./AuditQuestionComponent";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AuditCard from "./AuditCard";
import Grid from "@mui/material/Grid"; // Grid version 1
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Title } from "chart.js";
import { Radar } from "react-chartjs-2";
import DetailsDialog from "../../Findings/DetailsDialog";
import TransferFindingDialog from "../../Findings/TransferFindingDialog";
import CompleteFindingDialog from "../../Findings/CompleteFindingDialog";
import AuditMainQuestionCard from "./AuditMainQuestionCard";
import InsertFindingDialog from "../../Findings/InsertFindingDialog";
import MobileStepper from "@mui/material/MobileStepper";
import { useTheme } from "@mui/material/styles";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

import { useSelector, useDispatch } from "react-redux";
import { selectNotification } from "../../../features/ui/notificationSlice";
import { showNotification } from "../../../features/ui/notificationSlice";
import { currentAuditActions } from "../../../features/audits/currentAuditSlice";
import {
  selectCurrentAudit,
  selectCurrentQuestionIndex,
} from "../../../features/audits/currentAuditSlice";

import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import SaveIcon from "@mui/icons-material/Save";

import {
  fetchCurrentAudit,
  generateAuditQuestions,
} from "../../../features/audits/currentAuditSlice";
import { selectUsers } from "../../../features/users/usersSlice";
import { selectDepartments } from "../../../features/departments/departmentsSlice";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CreateIcon from "@mui/icons-material/Create";
import FindingsList from "../../Findings/FindingsList";
import { getRadarChartData } from "../../../utils/commonFunctions";
import { fetchAudits } from "../../../features/audits/auditsSlice";
import ActivityParticipants from "../../common/ActivityParticipants";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";

import LockOpenIcon from "@mui/icons-material/LockOpen";
import PrettyDepartment from "../../common/PrettyDepartment";
import DepartmentDetail from "../../Tree/DepartmentDetail";
import FindingsTable from "../../Findings/FindingsTable";
import { useTranslation } from "react-i18next";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  [`&.${tableRowClasses.head}`]: {
    border: "2px solid black",
  },
  // hide last border
  "&:last-child td": {
    border: 0,
  },
}));

const options = {
  scale: {
    ticks: {
      beginAtZero: true,
      stepSize: 1,
    },
    r: {
      angleLines: {
        display: false,
      },
      suggestedMin: 0,
      suggestedMax: 5,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

ChartJS.register(
  Title,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
);

function CircularProgressWithLabel() {
  const [progress, setProgress] = useState(0);
  const meeting_duration = 30 * 60; // seconds
  const [seconds, setSeconds] = useState(meeting_duration);
  const increment_by = (1 / meeting_duration) * 100;
  const [timer, setTimer] = useState();
  const { t } = useTranslation()

  function toggleTimer() {
    if (timer) {
      clearInterval(timer);
      setTimer(null);
    } else {
      const t = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 100 ? 100 : prevProgress + increment_by,
        );
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
      setTimer(t);
    }
  }

  function getLabel(time) {
    if (time === meeting_duration) {
      return t('buttons.startTimer');
    }
    return (
      formatTime(Math.floor(seconds / 60)) +
      ":" +
      formatTime(Math.abs(seconds % 60))
    );
  }

  const formatTime = (time) => {
    return time < 0 ? `-${Math.abs(time + 1)}` : time < 10 ? `0${time}` : time;
  };

  return (
    <div>
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          variant="determinate"
          size={60}
          color={progress == 100 ? "error" : "primary"}
          value={progress}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button component="div" onClick={() => toggleTimer()}>
            <Typography color={seconds > 0 ? "primary" : "error"}>
              {getLabel(seconds)}
            </Typography>
          </Button>
        </Box>
      </Box>
    </div>
  );
}

export default function AuditComponent() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dialogVisibility = useSelector(selectDialog);

  const { users } = useSelector(selectUsers);
  const {
    auditInfo,
    data,
    auditFindings,
    currentQuestionIndex,
    participants,
    previousMeetingFindings,
    previousAuditFindingsToBeApproved
  } = useSelector(selectCurrentAudit);
  const [showAuditQuestionModal, setShowAuditQuestionModal] = useState(false);
  const [selectedAuditQuestionId, setSelectedAuditQuestionId] = useState(-1);
  const departments = useSelector(selectDepartments);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const { t } = useTranslation()

  function last5Departments() {
    return departments
      .filter((d) => d.Auditable)
      .sort((a, b) => {
        if (a.Score > b.Score) {
          return 1;
        } else if (b.Score > a.Score) {
          return -1;
        } else return 0;
      })
      .slice(0, 5);
  }

  function isShowRadarChart() {
    return auditInfo.CompleteDate && auditInfo.ActivityType == "Audit";
  }

  function isShowQuestions() {
    return auditInfo.ActivityType == "Audit";
  }

  function isActivityCompleted() {
    return auditInfo.CompleteDate != null;
  }

  function isShowParticipants() {
    return (
      auditInfo.ActivityType == "Workshop" ||
      auditInfo.ActivityType == "FieldTour"
    );
  }

  function hasFindings() {
    return auditFindings.length > 0 && auditInfo.ActivityType !== "Meeting";
  }

  function isQuestionsInitialized() {
    if (!data) return false;

    return data.length > 0;
  }

  const toggleInsertFindingDialog = () => {
    dispatch(dialogActions.toggleInsertFinding(0));
  };

  async function onNextAuditQuestion() {
    if (verifyQuestion(data[currentQuestionIndex]) == false) return;

    dispatch(
      currentAuditActions.setCurrentQuestionId(currentQuestionIndex + 1),
    );
  }

  function onPreviousAuditQuestion() {
    dispatch(
      currentAuditActions.setCurrentQuestionId(currentQuestionIndex - 1),
    );
  }

  function MobileQuestionStepper() {
    const theme = useTheme();
    const maxSteps = data.length;

    const handleNext = () => {
      onNextAuditQuestion();
    };

    const handleBack = () => {
      onPreviousAuditQuestion();
    };

    return (
      <Box sx={{ flexGrow: 1 }}>
        <AuditMainQuestionCard
          auditInfo={auditInfo}
          auditQuestion={data[currentQuestionIndex]}
          displayActions={true}
          onShowAuditQuestionModal={onShowAuditQuestionModal}
        ></AuditMainQuestionCard>
        <MobileStepper
          variant="text"
          steps={maxSteps}
          position="static"
          activeStep={currentQuestionIndex}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={currentQuestionIndex === maxSteps - 1}
            >
              {t('buttons.next')}
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={currentQuestionIndex === 0}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              {t('buttons.prev')}
            </Button>
          }
        />
      </Box>
    );
  }

  function handleClose() {
    navigate(-1);
  }

  function createAuditQuestions(id) {
    dispatch(generateAuditQuestions(id));
  }

  function verifyQuestion(question) {
    if (question.Response == -1)
      // NA question
      return true;

    if (question.Answ != question.Cnt) {
      showNotification(
        dispatch,
        t('notification.answerAllQuestions'),
      );
      return false;
    }

    if (question.Response < 300 && question.FindingId == 0) {
      showNotification(
        dispatch,
        t('notification.createFindingsForLowScoreQuestions'),
      );
      return false;
    }

    return true;
  }
  function onComplete() {
    console.log(params.id);
    // verify if all questions answered
    if (isShowQuestions()) {
      let verified = true;
      let questionIndex = 0;
      data.every((question) => {
        verified = verifyQuestion(question);
        if (verified == false) return false;
        questionIndex++;
        return true; // continue for every
      });

      if (verified == false) {
        dispatch(currentAuditActions.setCurrentQuestionId(questionIndex));
        return;
      }
    }

    AuditService.completeAudit(params.id).then(() => {
      dispatch(fetchCurrentAudit(params.id));
      dispatch(fetchAudits);
    });
  }

  function onShowAuditQuestionModal(id) {
    dispatch(
      currentAuditActions.setCurrentQuestionId(
        data.findIndex((item) => id == item.Id),
      ),
    );
    setShowAuditQuestionModal(true);
  }

  function onCloseAuditQuestionModal() {
    dispatch(fetchCurrentAudit(params.id));
    setShowAuditQuestionModal(false);
  }

  const actions = [
    { icon: <ArrowBackIcon />, name: t('buttons.goBack'), onClick: handleClose },
  ];

  if (!isActivityCompleted()) {
    actions.push({
      icon: <AddPhotoAlternateIcon />,
      name: t('buttons.addFinding'),
      onClick: toggleInsertFindingDialog,
    });

    actions.push({
      icon: <SaveIcon />,
      name: t('buttons.finishActivity'),
      onClick: onComplete,
    });
  }

  if (isShowQuestions() && isQuestionsInitialized() == false) {
    actions.push({
      icon: <CreateIcon />,
      name: t('buttons.createAuditQuestions'),
      onClick: () => createAuditQuestions(params.id),
    });
  }

  if (isActivityCompleted()) {
    actions.push({
      icon: <PictureAsPdfIcon />,
      name: t('buttons.export2Pdf'),
      onClick: () => navigate("/auditreport/" + params.id),
    });
  }

  function CardView() {
    return (
      <Grid container spacing={2}>
        {data.map((mainQuestion) => (
          <>
            <Grid item xs={12} md={6}>
              {
                <AuditMainQuestionCard
                  auditInfo={auditInfo}
                  auditQuestion={mainQuestion}
                  displayActions={true}
                  onShowAuditQuestionModal={onShowAuditQuestionModal}
                ></AuditMainQuestionCard>
              }
            </Grid>
          </>
        ))}
      </Grid>
    );
  }

  function isMeeting() {
    return auditInfo.ActivityType == "Meeting";
  }

  useEffect(() => {
    dispatch(currentAuditActions.setCurrentQuestionId(0))
    dispatch(fetchCurrentAudit(params.id));

  }, []);

  return (
    <div>
      {showAuditQuestionModal && (
        <AuditQuestionComponent
          className="m-2"
          show={showAuditQuestionModal}
          onPrevious={onPreviousAuditQuestion}
          onNext={onNextAuditQuestion}
          onClose={onCloseAuditQuestionModal}
        />
      )}

      {dialogVisibility.insertFinding && (
        <InsertFindingDialog
          user={auditInfo.InchargeUserId}
          mainActivityId={auditInfo.Id}
          nophoto={auditInfo.ActivityType == "Meeting"}
          departmentId={
            auditInfo.ActivityType == "FieldTour" ||
              auditInfo.ActivityType == "Meeting"
              ? undefined
              : auditInfo.DepartmentId
          }
          callback={() => dispatch(fetchCurrentAudit(params.id))}
        />
      )}

      <Grid container alignItems="top" justifyContent="center" spacing={2}>
        <Grid item xs={10} md={4}>
          <AuditCard audit={auditInfo}></AuditCard>
        </Grid>
        {isMeeting() && (
          <>
            <Grid item xs={2} md={1} marginLeft={5}>
              <CircularProgressWithLabel />
            </Grid>
          </>
        )}
        {isShowRadarChart() && (
          <>
            <Grid item xs={12} md={2}>
              <Typography textAlign="center">
                {t('ui.auditScore')}: {auditInfo.Score / 100}
              </Typography>

              <Radar data={getRadarChartData(auditInfo)} options={options} />
            </Grid>
          </>
        )}

        {isShowQuestions() && !isQuestionsInitialized() && (
          <>
            <Grid item xs={12} />
            <Grid item xs={12} md={4}>
              <Paper elevation={3} sx={{ padding: 2, textAlign: "center" }}>
                <Typography
                  alignContent="center"
                  textAlign="center"
                  variant="h5"
                >
                  {t('audits.startAuditInfo')}
                </Typography>
                <Button
                  variant="outlined"
                  onClick={() => createAuditQuestions(params.id)}
                >
                  {t('buttons.createAuditQuestions')}
                </Button>
              </Paper>
            </Grid>
          </>
        )}

        {isShowParticipants() && (
          <>
            <Grid item xs={12} />
            <Grid item xs={12} md={4}>
              <ActivityParticipants disabled={isActivityCompleted()} />
            </Grid>
          </>
        )}

        {isShowQuestions() &&
          isQuestionsInitialized() &&
          (!isActivityCompleted() ? (
            <>
              <Grid item xs={12} />
              <Grid item xs={12} md={4}>
                <MobileQuestionStepper />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} />
              <Grid item xs={12} md={6} sx={{ bgcolor: "background.paper" }}>
                <CardView />
              </Grid>
            </>
          ))}

        {hasFindings() && (
          <Grid item xs={12} md={12}>
            <h4>{t('audits.findingsOfThisAudit')}</h4>
            <FindingsTable autoHeight={true} data={auditFindings} />
          </Grid>
        )}


        {isMeeting() && (
          <>
            <Grid item xs={12}>
              <Slide
                direction="right"
                in={selectedDepartment === null}
                unmountOnExit
              >
                <Container maxWidth="md">
                  <Stack direction="column" gap={2}>
                    <Paper elevation={2}>
                      <ActivityParticipants disabled={isActivityCompleted()} />
                    </Paper>
                    <h4>{t('fiveSActivities.prevMeetingDecisions')}</h4>
                    <FindingsTable
                      autoHeight={true}
                      data={previousMeetingFindings}
                      noImage
                    />
                    <h4>{t('fiveSActivities.prevMonthDepartmentScores')}</h4>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <StyledTableRow>
                            <StyledTableCell>{t('ui.department')}</StyledTableCell>
                            <StyledTableCell align="right">
                              {t('ui.score')}
                            </StyledTableCell>
                          </StyledTableRow>
                        </TableHead>
                        <TableBody>
                          {last5Departments().map((department) => (
                            <StyledTableRow
                              key={department.Id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                              onClick={() =>
                                setSelectedDepartment(department.Id)
                              }
                            >
                              <StyledTableCell component="th" scope="row">
                                <Stack direction="row">
                                  <LockOpenIcon />
                                  <PrettyDepartment department={department} />
                                </Stack>
                              </StyledTableCell>
                              <TableCell align="right">
                                {(department.Score / 100).toFixed(2)}
                              </TableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <h4>{t('fiveSActivities.meetingDecisions')}</h4>
                    <FindingsTable
                      autoHeight={true}
                      data={auditFindings}
                      noImage
                    />
                  </Stack>
                </Container>
              </Slide>
            </Grid>
            <Slide
              timeout={500}
              direction="left"
              in={selectedDepartment !== null}
            >
              <Stack>
                <Button
                  variant="contained"
                  onClick={() => setSelectedDepartment(null)}
                >
                  Geri dön...
                </Button>
                <Grid item xs={12}>
                  {selectedDepartment && (
                    <div>
                      <DepartmentDetail id={selectedDepartment} />
                    </div>
                  )}
                </Grid>
              </Stack>
            </Slide>
          </>
        )}
      </Grid>

      <DetailsDialog />
      <TransferFindingDialog />
      <CompleteFindingDialog />

      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={action.onClick}
          />
        ))}
      </SpeedDial>
    </div>
  );
}
