import { MAIN_URL } from "./ServiceConstants";
import axios from "axios";

async function getAllQuestions() {
  return axios.post(MAIN_URL + "/Quality/SetQuestions", {
    dataobject: {
      Action: "S",
    },
  });
}

async function getAllRestrictedQuestions() {
  return axios.post(MAIN_URL + "/Quality/SetRestrictedQuestions", {
    dataobject: {
      Action: "S",
    },
  });
}

async function approveRestrictedQuestions(questionId) {
  return axios.post(MAIN_URL + "/Quality/SetRestrictedQuestions", {
    dataobject: {
      Action: "U",
      ActivityQuestionId: questionId,
    },
  });
}

async function denyRestrictedQuestions(questionId) {
  return axios.post(MAIN_URL + "/Quality/SetRestrictedQuestions", {
    dataobject: {
      Action: "D",
      ActivityQuestionId: questionId,
    },
  });
}

async function getQuestion(questionId) {
  return axios.post(MAIN_URL + "/Quality/SetQuestions", {
    dataobject: {
      Action: "S",
      Id: questionId,
    },
  });
}

async function insertQuestion(question, masterId, type) {
  return axios.post(MAIN_URL + "/Quality/SetQuestions", {
    dataobject: {
      Action: "I",
      Question: question,
      master_id: masterId,
      FiveSType: type,
    },
  });
}

async function updateQuestion(id, question, masterId, type, mandatory) {
  return axios.post(MAIN_URL + "/Quality/SetQuestions", {
    dataobject: {
      Action: "U",
      Id: id,
      Question: question,
      master_id: masterId,
      FiveSType: type,
      Mandatory: mandatory,
    },
  });
}

async function deleteQuestion(id) {
  return axios.post(MAIN_URL + "/Quality/SetQuestions", {
    dataobject: {
      Action: "D",
      Id: id,
    },
  });
}

const FiveSService = {
  getAllQuestions,
  getQuestion,
  insertQuestion,
  updateQuestion,
  deleteQuestion,
  getAllRestrictedQuestions,
  approveRestrictedQuestions,
  denyRestrictedQuestions,
};

export default FiveSService;
