import ForgotPassword from "../components/Login/ForgotPassword";
import background from "../resources/Login/background.jpg";

export default function ForgotPasswordPage() {
  return (
    <div
      style={{ backgroundImage: `url(${background})`, backgroundSize: "cover" }}
    >
      <ForgotPassword />
    </div>
  );
}
