import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useSelector } from "react-redux";
import { selectConfirmDialog } from "../../features/ui/confirmDialogSlice";

const Transition = React.forwardRef(function Transition(
  props,
  ref,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmModal = () => {
  const { prompt, isOpen, proceed, cancel } = useSelector(selectConfirmDialog);
  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{prompt}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {prompt}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus variant="outlined" color="secondary" onClick={cancel}>
          Cancel
        </Button>
        <Button className="ml-4" variant="outlined" onClick={proceed}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;
