import * as React from "react";
import { useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  Title,
} from "chart.js";
import { Radar } from "react-chartjs-2";
import { getRadarChartData } from "../../utils/commonFunctions";
import { getKins } from "../../utils/commonFunctions";

const options = {
  scale: {
    ticks: {
      beginAtZero: true,
      stepSize: 1,
    },
    r: {
      angleLines: {
        display: false,
      },
      suggestedMin: 0,
      suggestedMax: 5,
    },
  },
  plugins: {
    legend: {
      display: true,
    },
  },
};

ChartJS.register(
  Title,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
);

export default function RadarChart({ department, allDepartments }) {
  const [compareDepartment, setCompareDepartment] = React.useState("");
  const [radarData, setRadarData] = React.useState("");
  const availableDepartments = getKins(allDepartments, department);

  const handleChange = (event) => {
    setCompareDepartment(event.target.value);
  };

  useEffect(() => {
    const rdata = getRadarChartData(department);
    if (compareDepartment != "") {
      const compareRadarData = getRadarChartData(compareDepartment);
      const ds = {
        label: compareDepartment.Adi,
        data: compareRadarData.datasets[0].data,
        backgroundColor: "rgba(0, 255, 132, 0.2)",
        borderColor: "rgba(0, 255, 132, 1)",
        borderWidth: 1,
      };
      rdata.datasets.push(ds);
    }
    setRadarData(rdata);
  }, [department, compareDepartment]);

  return (
    <>
      {allDepartments && (
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="demo-select-small-label">Karşılaştır</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={compareDepartment}
            label="Karşılaştır"
            onChange={handleChange}
          >
            <MenuItem value={""}>
              <em>Karşılaştırma Yok</em>
            </MenuItem>
            {availableDepartments.map((d) => {
              return <MenuItem value={d}>{d.Adi}</MenuItem>;
            })}
          </Select>
        </FormControl>
      )}
      {radarData && <Radar data={radarData} options={options} />}
    </>
  );
}
