import { TextField } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectFilterText,
  setFilterText,
} from "../../features/ui/listFiltersSlice";

const Filter = ({placeholder}) => {
  const filterText = useSelector(selectFilterText);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setFilterText(""));
  }, []);

  return (
    <TextField
      fullWidth
      color="primary"
      variant="outlined"
      placeholder={placeholder}
      value={filterText}
      onChange={(e) => dispatch(setFilterText(e.target.value))}
    />
  );
};

export default Filter;
