import { MAIN_URL } from "./ServiceConstants";
import axios from "axios";
import LoginService from "./LoginServices";

const url = MAIN_URL + "/Quality/SetFinding";

async function getAllFindings() {
  return axios.post(url, {
    dataobject: {
      Action: "S",
    },
  });
}

async function getFinding(findingId) {
  return axios.post(url, {
    dataobject: {
      Action: "S",
      Id: findingId,
    },
  });
}

async function insertFinding({
  departmentId,
  inChargeUserId,
  description,
  image,
  date,
  fiveSType,
  mainActivityId = 0,
  activityQuestionId = 0,
}) {
  return axios.post(url, {
    dataobject: {
      Action: "I",
      Id: 0,
      DepartmentId: departmentId,
      InchargeUserId: inChargeUserId,
      Dsc: description,
      DueDate: date,
      FiveSType: fiveSType,
      imagebefore: image,
      MainActivityId: mainActivityId,
      ActivityQuestionId: activityQuestionId,
    },
  });
}

async function updateFinding(data) {
  return axios.post(url, {
    dataobject: {
      Action: "U",
      ...data,
    },
  });
}

async function approveFinding({ description, findingId }) {
  return axios.post(url, {
    dataobject: {
      Action: "A",
      ApproveNote: description,
      Id: findingId,
    },
  });
}

// async function updateFinding(findingId, loginName, longName, email, pass) {
//   return axios.post(url, {
//     dataobject: {
//       Action: "U",
//       Id: findingId,
//       LoginName: loginName,
//       LongName: longName,
//       email: email,
//       Pass: pass,
//     },
//   });
// }

async function transferFinding({
  findingId,
  description,
  transferUserId,
  date,
}) {
  return axios.post(MAIN_URL + "/Quality/SetFindingTransfer", {
    dataobject: {
      Id: findingId,
      TransferDsc: description,
      TransferUserId: transferUserId,
      DueDate: date,
    },
  });
}

async function completeFinding({ findingId, description, image }) {
  return axios.post(MAIN_URL + "/Quality/SetFindingComplete", {
    dataobject: {
      Id: findingId,
      DscOk: description,
      imageafter: image,
      CompletedUserId: LoginService.getUser()?.UserId,
      Completed: 1,
    },
  });
}
async function deleteFinding(id) {
  return axios.post(url, {
    dataobject: {
      Action: "D",
      Id: id,
    },
  });
}


async function transferFindingInchargeUser(oldUser, newUser) {
  return axios.post(MAIN_URL + "/Quality/SetTransferTask", {
    dataobject: {
      Action: "INCHARGEFINDING",
      OldUserId: oldUser,
      NewUserId: newUser,
    },
  });
}

const FindingsService = {
  getAllFindings,
  getFinding,
  insertFinding,
  updateFinding,
  transferFinding,
  approveFinding,
  completeFinding,
  deleteFinding,
  transferFindingInchargeUser
};

export default FindingsService;
