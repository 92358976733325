import { useEffect, useState } from "react";
import DetailsDialog from "./DetailsDialog";
import { showConfirmDialog } from "../../features/ui/confirmDialogSlice";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Divider,
  Typography,
  ListItemIcon,
  Paper,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Chip,
  useTheme,
  Tabs,
  Tab,
  useMediaQuery,
} from "@mui/material";

import TransferFindingDialog from "./TransferFindingDialog";
import CompleteFindingDialog from "./CompleteFindingDialog";
import { DateFormat } from "../../utils/DateFormat";

import { fetchFindings } from "../../features/findings/findingsSlice";
import { showNotification } from "../../features/ui/notificationSlice";
import { useDispatch, useSelector } from "react-redux";
import FindingsService from "../../services/FindingsService";
import FindingActionsButton from "./FindingActions";
import { selectDialog, dialogActions } from "../../features/ui/dialogsSlice";
import {
  TabPanel,
  checkDeadline,
  getImageUrl,
  isFindingCompleted,
  isFindingExpired,
  isFindingTransferred,
} from "../../utils/commonFunctions";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

const FindingsList = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [currentTab, setCurrentTab] = useState("upcoming");
  const expiredFindings = props.data.filter(isFindingExpired);
  const upcomingFindings = props.data.filter((i) =>
    checkDeadline(i, "upcoming"),
  );
  const completedFindings = props.data.filter(isFindingCompleted);

  function renderList(data) {
    return (
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          overflow: "auto",
          maxHeight: 700,
        }}
      >
        {data.map((finding) => {
          const completed = isFindingCompleted(finding);
          const expired = isFindingExpired(finding);
          const conforming = checkDeadline(finding, "conforming");
          const color = conforming
            ? theme.findings.conforming
            : completed
            ? theme.findings.completed
            : expired
            ? theme.findings.expired
            : theme.findings.upcoming;
          return (
            <>
              <ListItem
                sx={{
                  borderLeft: `${conforming ? "8px" : "5px"} solid ${color}`,
                }}
                index={finding.Id}
                disablePadding
              >
                <ListItemButton>
                  <ListItemText
                    primary=<Stack gap={2} m={1} direction="row">
                      {`Departman: ${finding.Department} (Bulgu Id: ${finding.Id})`}
                      {finding.FiveSType && (
                        <Chip label={finding.FiveSType} color="primary" />
                      )}
                    </Stack>
                    secondary={
                      <Stack>
                        <Typography>
                          {"Oluşturulma Tarihi: " +
                            DateFormat.monthNameDayYear(finding.SystemDate)}
                        </Typography>
                        <Typography>
                          {"Termin: " +
                            DateFormat.monthNameDayYear(finding.DueDate)}
                        </Typography>
                        <Typography>
                          {"Sorumlu Kişi: " + finding.InchargeUser}
                        </Typography>
                        <Typography>{"Açıklama: " + finding.Dsc}</Typography>
                        <Link to={"/audits/" + finding.MainActivityId}>
                          {"Kaynak: " +
                            (finding.ActivityType
                              ? finding.ActivityType
                              : "Anlık")}
                        </Link>
                        {finding.question && (
                          <Typography>{"Soru: " + finding.question}</Typography>
                        )}
                      </Stack>
                    }
                  />
                  <Stack gap={2} m={1} direction="row">
                    {smallScreen ? (
                      <ListItemIcon>
                        <VisibilityIcon
                          color={completed ? "success" : "error"}
                          onClick={(e) => toggleDetails(e, finding)}
                        />
                      </ListItemIcon>
                    ) : (
                      <>
                        <img
                          src={getImageUrl(finding.imagebefore)}
                          height={100}
                          style={{ border: "3px solid red" }}
                          onClick={(e) => toggleDetails(e, finding)}
                        />
                        {finding.imageafter && (
                          <img
                            src={getImageUrl(finding.imageafter)}
                            height={100}
                            style={{ border: "3px solid green" }}
                            onClick={(e) => toggleDetails(e, finding)}
                          />
                        )}
                      </>
                    )}
                  </Stack>
                </ListItemButton>

                <FindingActionsButton finding={finding} />
              </ListItem>
              <Divider />
            </>
          );
        })}
      </List>
    );
  }

  const toggleDetails = (e, finding) => {
    e?.stopPropagation();
    dispatch(dialogActions.toggleFindingDetail(finding));
  };

  function tabView() {
    return (
      <>
        <Tabs
          value={currentTab}
          onChange={(e, val) => setCurrentTab(val)}
          variant="fullWidth"
        >
          <Tab
            label={`${t('findings.upcoming')} (${upcomingFindings.length})`}
            disabled={!upcomingFindings.length}
            value="upcoming"
          />
          <Tab
            label={`${t('findings.expired')} (${expiredFindings.length})`}
            disabled={!expiredFindings.length}
            value="expired"
          />
          <Tab
            label={`${t('findings.completed2')} (${completedFindings.length})`}
            disabled={!completedFindings.length}
            value="completed"
          />
        </Tabs>
        <TabPanel currentTab={currentTab} value="expired">
          {renderList(expiredFindings)}
        </TabPanel>
        <TabPanel currentTab={currentTab} value="upcoming">
          {renderList(upcomingFindings)}
        </TabPanel>
        <TabPanel currentTab={currentTab} value="completed">
          {renderList(completedFindings)}
        </TabPanel>
      </>
    );
  }

  function listView() {
    return renderList(props.data);
  }
  return (
    <Paper elevation={3}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h5" p={2}>
          {props.title}
        </Typography>
        <Typography variant="h5" p={2}>
          #{props.data.length}
        </Typography>
      </Stack>
      <Divider />

      {props.listView ? listView() : tabView()}
      <DetailsDialog />
      <TransferFindingDialog />
      <CompleteFindingDialog />
    </Paper>
  );
};

export default FindingsList;
