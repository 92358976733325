import { Stack, Typography } from "@mui/material";
import fuzzysort from "fuzzysort";

const PrettyDepartment = ({ department, match }) => {
  function highlightName() {
    if (!match) {
      return department.Adi
    }
    const result = fuzzysort.highlight(match[0], (m, i) => <b>{m}</b>);
    if (!result || !result.length) return department.Adi;
    else return result;
  }

  function highlightFullname() {
    const split = department.FullName.split(" - ");
    const detail = split.slice(0, -1).join(" ❯ ");
    if (!match) {
      return detail
    }
    const result = fuzzysort.highlight(match[1], (m, i) => <b>{m}</b>);
    if (!result || !result.length) return detail
    else return result
  }

  return (
    <Stack>
      <Typography sx={{ fontSize: 14 }}>{highlightName()}</Typography>
      <Typography color="gray" sx={{ fontSize: 10 }}>
        {highlightFullname()}
      </Typography>
    </Stack>
  );
};

export default PrettyDepartment;
