import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectDeviations } from "../../features/deviations/deviationsSlice";
import DeviationRequests from "../DeviationRequests/DeviationRequests";
import { selectDepartments } from "../../features/departments/departmentsSlice";
import { Button, Paper, Stack } from "@mui/material";
import { selectRestrictedQuestions } from "../../features/questions/questionsSlice";
import RestrictedQuestions from "../QuestionPool/RestrictedQuestions";
import { selectFilteredUsers } from "../../features/users/usersSlice";
import UserDetail from "../Users/UserDetail";
import { setFilterText } from "../../features/ui/listFiltersSlice";
import CreateDeviationRequestDialog from "../DeviationRequests/CreateDeviationRequestDialog";
import { dialogActions } from "../../features/ui/dialogsSlice";
import {
  currentUserIsAdmin,
  getChildDepartmentsOfRoleCode,
} from "../../utils/authFunctions";
import { selectAudits } from "../../features/audits/auditsSlice";
import AuditListComponent from "../MainActivity/Audit/AuditListComponent";
import { changeAuditFilterDate } from "../../features/ui/auditViewSlice";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

export default function DepartmentProfile() {
  const params = useParams();
  const users = useSelector(selectFilteredUsers);
  const departments = useSelector(selectDepartments);
  const department = departments.find((d) => d.Id === Number(params.id));
  const deviationRequestsDepartments = getChildDepartmentsOfRoleCode(
    departments,
    "AL"
  );
  const deviations = useSelector(selectDeviations);
  const [deviationsData, setDeviationsData] = useState([])
  const questions = useSelector(selectRestrictedQuestions);
  const [questionsData, setQuestionsData] = useState([])
  const dispatch = useDispatch();
  const [depUsers, setDepUsers] = useState([])
  const { t } = useTranslation()
  const audits = useSelector(selectAudits).filter(a=>a.DepartmentId == Number(params.id))

  useEffect(() => {
    dispatch(setFilterText(""));
  }, []);

  useEffect(() => {
    const today = dayjs().toISOString()
    const threeMonthsBefore = dayjs().subtract(3, 'month').toISOString()
    changeAuditFilterDate(dispatch, threeMonthsBefore, today)
    if (department) {
      setDepUsers(users.filter((item) =>
        department.Users.split(",").map(Number).includes(item.obj.Id)))
      setDeviationsData(deviations.filter((d) => d.Adi == department.Adi))
      setQuestionsData(questions.filter((d) => d.Adi == department.Adi))
    }

  }, [department, deviations])

  function toggleCreateDevReq(e) {
    e.stopPropagation();
    dispatch(dialogActions.toggleCreateDeviationRequest(department.Id));
  }
  return department ? (
    <Stack gap={1}>
      <h2>{t('ui.department')}: {department.Adi}</h2>
      {depUsers.length > 0 ? (
        <Paper elevation={6}>
          <h3 style={{marginLeft:10}}>{t('departments.usersInThisDepartment')}:</h3>
          {depUsers.map((match) => (
            <UserDetail user={match.obj} match={match} noDelete />
          ))}
        </Paper>
      ) : (
        <h3>{t('departments.noUsers')}</h3>
      )}
      <Paper elevation={6}>
        <h3 style={{marginLeft: 10}}>{t('tasks.deviationRequests')}: {deviationsData.length}</h3>
        {(deviationRequestsDepartments.includes(department.Id) ||
          currentUserIsAdmin()) && (
          <Button
            onClick={(e) => toggleCreateDevReq(e, department)}
            variant="contained"
            sx={{ml: 1, mb: 1}}
          >
              {t('buttons.createDeviationRequest')}
          </Button>
        )}
      {deviationsData.length > 0 && (
        <DeviationRequests hideActions data={deviationsData} />
      )}
      </Paper>
      <Paper elevation={6}>
        <h3 style={{marginLeft: 10}}>{t('tasks.unadaptableQuestions')}: {questionsData.length}</h3>
        {questionsData.length > 0 && (
          <RestrictedQuestions hideActions data={questionsData} />
        )}
      </Paper>

      <Paper elevation={6}>
        <h3 style={{marginLeft: 10}}>{t('audits.recentAudits')}</h3>
        {audits.length > 0 ? <AuditListComponent audits={audits}/> : <p style={{marginLeft: 10}}>{t('audits.noAudits')}</p>}

      </Paper>
      <CreateDeviationRequestDialog />
    </Stack>
  ) : <div>
  </div>;
}
