import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FiveSService } from "../../services";
import DeviationService from "../../services/DeviationService";

export const fetchDeviationRequests = createAsyncThunk(
  "deviations/fetchDeviationRequests",
  async () => {
    const response = await DeviationService.getDeviations();
    return response;
  },
);

export const approveDeviation = createAsyncThunk(
  "deviations/approveDeviation",
  async (deviationId) => {
    const response = await DeviationService.approveDeviation(deviationId);
    return response;
  },
);


export const createDeviation = createAsyncThunk(
  "deviations/createDeviation",
  async (data) => {
    const response = await DeviationService.createDeviation(...data);
    return response;
  },
);

export const denyDeviation = createAsyncThunk(
  "deviations/denyDeviation",
  async (deviationId) => {
    const response = await DeviationService.denyDeviation(deviationId);
    return response;
  },
);


export const deleteDeviation = createAsyncThunk(
  "deviations/deleteDeviation",
  async (deviationId) => {
    const response = await DeviationService.deleteDeviation(deviationId);
    return response;
  },
);

const initialState = {
  deviationRequests: []
}

const deviationsSlice = createSlice({
  name: "deviations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDeviationRequests.fulfilled, (state, action) => {
      state.deviationRequests = action.payload.Table
    });
    builder.addCase(approveDeviation.fulfilled, (state, action) => {
      const deviationReq = state.deviationRequests.find(
        (d) => d.Id === action.payload.Table[0].Id
      );
      if (deviationReq) {
        deviationReq.ApproverId = action.payload.Table[0].ApproverId
      }
    });

    builder.addCase(createDeviation.fulfilled, (state, action) => {
      const deviationReq = action.payload.Table[0]
      if (deviationReq) {
        state.deviationRequests.push(deviationReq)
      }
    });
    builder.addCase(denyDeviation.fulfilled, (state, action) => {
      const deviationReq = state.deviationRequests.find(
        (d) => d.Id === action.payload.Table[0].Id
      );
      if (deviationReq) {
        deviationReq.ApproverId = action.payload.Table[0].ApproverId
      }
    });
  },
});

export const selectDeviations = (state) =>
  state.deviations.deviationRequests;

export default deviationsSlice.reducer;
