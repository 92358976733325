import { useState } from "react";
import Button from "@mui/material/Button";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Stack } from "@mui/material";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { AuditService } from "../../../services";
import { useSelector } from "react-redux";
import { selectUsers } from "../../../features/users/usersSlice";
import { selectDepartments } from "../../../features/departments/departmentsSlice";
import { TextField } from "@mui/material";
import DepartmentUserSelect from "../../common/DepartmentUserSelect";
import { useDispatch } from "react-redux";
import { addAudit } from "../../../features/audits/auditsSlice";
import { useTranslation } from "react-i18next";

export default function InsertAuditDialog(props) {
  const { users } = useSelector(selectUsers);
  const { t } = useTranslation()
  const departments = useSelector(selectDepartments);
  const defaultValues = {
    responsibleUser: props.inChargeUserId || "",
    auditorUser: props.auditorId || "",
    department:
      departments.find((item) => item.Id === props.departmentId) || null,
    start: new Date(),
    due: new Date(),
  };

  const [formValues, setFormValues] = useState(defaultValues);
  const dispatch = useDispatch();

  function hideDialog() {
    props.onClose();
    setFormValues(defaultValues);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const values = { ...formValues, departmentId: formValues.department.Id };
    delete values["department"];
    console.log(values);
    addAudit(dispatch, { ...values }).then(() => hideDialog());
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  return (
    <Dialog fullWidth open={props.show}>
      <DialogTitle>{t('audits.addNewAudit')}</DialogTitle>
      <DialogContent style={{ height: "600px" }}>
        <Stack gap={2}>
          <DepartmentUserSelect
            {...props}
            valueDepartment={formValues.department}
            onChangeDepartment={(event, newValue) => {
              setFormValues({ ...formValues, department: newValue });
            }}
            valueUser={formValues.inChargeUserId}
            onChangeUser={(value) =>
              setFormValues({ ...formValues, responsibleUser: value })
            }
          />

          <FormControl fullWidth>
            <InputLabel>{t('ui.auditor')}</InputLabel>
            <Select
              size="small"
              value={formValues.inChargeUserId}
              label={t('ui.auditor')}
              name="auditorUser"
              readOnly={props.auditor !== undefined}
              onChange={handleInputChange}
            >
              {users
                .filter((user) => user.UserRolesCode.includes("DE"))
                .map((user) => {
                  return (
                    <MenuItem key={user.Id} value={user.Id}>
                      {user.LongName}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={t('ui.auditDate')}
              inputFormat="DD/MM/YYYY"
              value={formValues.start}
              onChange={(date) => {
                setFormValues({ ...formValues, start: date, due: date });
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={hideDialog}>
          Cancel
        </Button>
        <Button variant="outlined" onClick={handleSubmit}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
