import { Stack } from '@mui/material';
import './Legend.css';

const Legend = () => {
  return (
    <Stack sx={{my: 2}} direction="row" justifyContent="space-around" className="legend">
      <Stack direction="row" gap={1} alignItems="center">
        <div className="legend-item red"></div>
        <div className="legend-label">0% - 40%</div>
      </Stack>
      <Stack direction="row" gap={1} alignItems="center">
        <div className="legend-item yellow"></div>
        <div className="legend-label">40% - 70%</div>
      </Stack>
      <Stack direction="row" gap={1} alignItems="center">
        <div className="legend-item green"></div>
        <div className="legend-label">70% - 100%</div>
      </Stack>
    </Stack>
  );
};

export default Legend;
