import { Button, FormControlLabel, Grid, Paper, Slide, Stack, Switch } from "@mui/material";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import dayjs from "dayjs";
import YearMonthSelector from "../common/YearMonthSelector";
import UserMonthlyFindingsReport from "../Reports/UserMonthlyFindingsReport";
import {
    fetchUsersAuditPerformance,
  fetchUsersWithPeriodicScores,
  selectUsersVisibleToCurrentUser,
  selectUsersWithPeriodicScores,
} from "../../features/users/usersSlice";
import { useEffect, useRef, useState } from "react";
import UserDetail from "./UserDetail";
import FindingsList from "../Findings/FindingsList";
import {
  fetchUserStatistics,
  selectFindingList,
  setFindingStats,
} from "../../features/ui/findingStatsSlice";
import FindingStats from "../common/FindingStats";
import { setFilter } from "../../features/ui/findingFiltersSlice";
import { selectFilteredFindings } from "../../features/findings/findingsSlice";
import { useTranslation } from "react-i18next";
import PerformanceReport from "../Reports/PerformanceReport";
import YearlyAuditGraphUser from "./YearlyAuditGraphUser";
import AveragePerformance from "./AveragePerformance";
import FindingsPerformanceReport from "../Reports/FindingsPerformanceReport";
import ParticipantsPerformanceReport from "../Reports/ParticipantsPerformanceReport";
import GeneralPerformance from "../Reports/GeneralPerformance";
import UserSelector from "../common/UserSelector";

var isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)

const UserMonthlyDetails = () => {
  const dispatch = useDispatch();
  const [selectedUser, setSelectedUser] = useState(null);
  const data = useSelector(selectFilteredFindings, shallowEqual);
  const [year, setYear] = useState();
  const [findings, setFindings] = useState([])
  const usersWithPeriodicScores = useSelector(selectUsersWithPeriodicScores);
  const [startDate, setStartDate] = useState(dayjs().startOf("month").toDate())
  const currentMonth = startDate.getMonth()
  const [endDate, setEndDate] = useState()
  const [yearly, setYearly] = useState(false)
  const visibleUsers = useSelector(selectUsersVisibleToCurrentUser)
  const { t } = useTranslation()

  function changeSelectedDate(newDate) {

    const start = dayjs(newDate).startOf("month").toDate();

    const end = dayjs(newDate).endOf("month").toDate();
    setStartDate(start);
    setEndDate(end)
    const nYear = dayjs(newDate).year();
    if (nYear !== year) {
      setYear(nYear);
      dispatch(fetchUsersWithPeriodicScores(nYear));
      dispatch(fetchUsersAuditPerformance({year: nYear, performance_type: "MNG_AUDIT_PERFORMANCE"}))
      dispatch(fetchUsersAuditPerformance({year: nYear, performance_type: "INCHARGE_AUDIT_PERFORMANCE"}))
      dispatch(fetchUsersAuditPerformance({year: nYear, performance_type: "AUDITOR_AUDIT_PERFORMANCE"}))
      dispatch(fetchUsersAuditPerformance({year: nYear, performance_type: "PARTICIPANT_PERFORMANCE"}))
      dispatch(fetchUsersAuditPerformance({year: nYear, performance_type: "FINDING_PERFORMANCE"}))
    }
  }

  function setFindingStatsForUser() {
    if (selectedUser) {
      const userData = usersWithPeriodicScores[currentMonth].find(
        (u) => (u.ID == selectedUser.ID || u.ID == selectedUser.userID)
      );
      if (userData) {
        const id = userData.ID || userData.userID
        const findingStats = {
          Total: userData.Cnt,
          Completed: userData.CompCnt,
          Competable: userData.TermCompCnt,
          NotCompetable: userData.DueExpCnt,
        };
        dispatch(setFindingStats(findingStats));
        dispatch(setFilter.inChargeUserId(id));
      }
    }
  }

  useEffect(() => {
    setFindingStatsForUser();
  }, [usersWithPeriodicScores, selectedUser, currentMonth]);

  useEffect(() => {
    setFindings(data.filter(f=>
      dayjs(f.SystemDate).isBetween(dayjs(startDate), dayjs(endDate), 'day', '()')))
    // setFindings()
  }, [startDate, data])

  return (
    <Stack gap={1}>
      <Stack component={Paper} direction="row" sx={{ position: "sticky", top: 108, zIndex: 5 }}>
        <Grid item style={{ flexGrow: 1 }}>
          <YearMonthSelector onChange={changeSelectedDate} />
          <UserSelector userList={visibleUsers} />
        </Grid>
        <FormControlLabel control={<Switch value={yearly} onChange={(e)=>setYearly(e.target.checked)}/>} label={t('ui.yearly')} />
      </Stack>
      <Slide direction="right" in={selectedUser === null} unmountOnExit>
        <Stack gap={2}>
          <GeneralPerformance  onSelectUser={(u) => setSelectedUser(u)} currentMonth={currentMonth} currentYear={startDate.getFullYear()} yearly={yearly} />
          <PerformanceReport type="managers" currentMonth={currentMonth} currentYear={startDate.getFullYear()} onSelectUser={(u) => setSelectedUser(u)} yearly={yearly}/>
          <PerformanceReport type="inchargeUsers" currentMonth={currentMonth} currentYear={startDate.getFullYear()} onSelectUser={(u) => setSelectedUser(u)} yearly={yearly}/>
          <PerformanceReport type="auditors" currentMonth={currentMonth} currentYear={startDate.getFullYear()} onSelectUser={(u) => setSelectedUser(u)} yearly={yearly} />
          <FindingsPerformanceReport currentMonth={currentMonth} currentYear={startDate.getFullYear()} onSelectUser={(u) => setSelectedUser(u)} yearly={yearly} />
          <ParticipantsPerformanceReport currentMonth={currentMonth} currentYear={startDate.getFullYear()} onSelectUser={(u) => setSelectedUser(u)} yearly={yearly}/>
        </Stack>
      </Slide>
      <Slide timeout={500} direction="left" in={selectedUser !== null}>
        <Stack gap={2}>
          <Button variant="contained" onClick={() => setSelectedUser(null)}>
            {t('buttons.goBack')}
          </Button>
          {/* <AveragePerformance selectedUser={selectedUser}/> */}
          <YearlyAuditGraphUser selectedUser={selectedUser}/>
          <FindingStats />
          <FindingsList data={findings} />
        </Stack>
      </Slide>
    </Stack>
  );
};

export default UserMonthlyDetails;
