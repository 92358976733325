import { useState, useEffect } from "react";
import {
  Button,
  Typography,
  Dialog,
  FormControl,
  TextField,
  DialogTitle,
  DialogActions,
  DialogContent,
  Stack,
} from "@mui/material";
import { FiveSService } from "../../services";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { showNotification } from "../../features/ui/notificationSlice";
import { useDispatch } from "react-redux";
import { showConfirmDialog } from "../../features/ui/confirmDialogSlice";
import DeleditableTextView from "../common/DeleditableTextView";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";

function QuestionModal({ questionId }) {
  const [show, setShow] = useState(false);
  const [newQuestionText, setNewQuestionText] = useState("");
  const [question, setQuestion] = useState([]);
  const dispatch = useDispatch();
  const handleClose = () => setShow(false);

  const handleShow = () => {
    setShow(true);
    refreshData();
  };

  function refreshData() {
    FiveSService.getQuestion(questionId).then((response) =>
      setQuestion(response),
    );
  }

  async function deleteSubQuestion(id) {
    var result = await showConfirmDialog(dispatch, "Are you sure?");
    if (result) {
      FiveSService.deleteQuestion(id)
        .then(() => {
          showNotification(
            dispatch,
            "Question deleted successfully.",
            "primary",
          );
          refreshData();
        })
        .catch(() => {
          showNotification(
            dispatch,
            "Question cannot deleted. Try later.",
            "danger",
          );
        });
    }
  }

  async function addSubQuestion(questionText) {
    var result = await showConfirmDialog(dispatch, "Are you sure to add?");
    if (result) {
      let masterQ = question[0];
      FiveSService.insertQuestion(
        questionText,
        masterQ.Id,
        masterQ.FiveSType,
      ).then((response) => {
        showNotification(dispatch, "added", "primary");
        setNewQuestionText("");
        refreshData();
      });
    }
  }

  async function updateQuestion(question, newText) {
    var result = await showConfirmDialog(dispatch, "Are you sure to update?");
    if (result) {
      return FiveSService.updateQuestion(
        question.Id,
        newText,
        question.master_id,
        question.FiveSType,
        question.Mandatory,
      )
        .then((response) => {
          showNotification(dispatch, "Question updated successfully");
          refreshData();
          return true;
        })
        .catch(() => false);
    }
  }

  return (
    <>
      <IconButton
        color="primary"
        edge="end"
        aria-label="edit"
        onClick={handleShow}
      >
        <EditIcon />
      </IconButton>

      <Dialog maxWidth="md" fullWidth open={show}>
        <DialogTitle>
          {question.length > 0 && (
            <DeleditableTextView
              value={question[0].question}
              size="small"
              onDelete={() => deleteSubQuestion(question[0].Id)}
              onUpdate={(newValue) => updateQuestion(question[0], newValue)}
            >
              <Typography sx={{ flexGrow: 1, fontWeight: "bold" }} variant="h6">
                {question[0].question}
              </Typography>
            </DeleditableTextView>
          )}
        </DialogTitle>
        <DialogContent>
          <Stack gap={2} mt={1}>
            <Stack gap={1} direction="row">
              <FormControl fullWidth>
                <TextField
                  size="small"
                  label="Question title"
                  value={newQuestionText}
                  onChange={(e) => setNewQuestionText(e.target.value)}
                  placeholder="Add new question"
                />
              </FormControl>
              <Button
                onClick={() => addSubQuestion(newQuestionText)}
                color="primary"
                variant="contained"
              >
                Add
              </Button>
            </Stack>

            <Table>
              <TableBody>
                {question.slice(1).map((subquestion, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      <DeleditableTextView
                        value={subquestion.question}
                        size="small"
                        onDelete={() => deleteSubQuestion(subquestion.Id)}
                        onUpdate={(newValue) =>
                          updateQuestion(subquestion, newValue)
                        }
                      >
                        <Typography sx={{ flexGrow: 1 }} variant="h6">
                          {subquestion.question}
                        </Typography>
                      </DeleditableTextView>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default QuestionModal;
