import Button from "@mui/material/Button";
import MagicDropzone from "react-magic-dropzone";
import Box from "@mui/material/Box";
import { useState } from "react";
import ImageEdit from "./ImageEdit";
import { MAIN_URL } from "../services/ServiceConstants";
import axios from "axios";
import { fabric } from "fabric";
import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";

export function getRadarChartData(auditInfo) {
  const S1 = auditInfo.S1 || 0;
  const S2 = auditInfo.S2 || 0;
  const S3 = auditInfo.S3 || 0;
  const S4 = auditInfo.S4 || 0;
  const S5 = auditInfo.S5 || 0;
  const S6 = auditInfo.S6 || 0;

  return {
    labels: ["TER", "DÜZ", "TEM", "STA", "DİS", "İSG"],
    datasets: [
      {
        label: auditInfo.Adi ? auditInfo.Adi : "No: " + auditInfo.ActivityNo,
        data: [S1 / 100, S2 / 100, S3 / 100, S4 / 100, S5 / 100, S6 / 100],
        backgroundColor: "rgba(255, 0, 0, 0.5)",
        borderColor: "rgba(255, 0, 0, 1)",
        borderWidth: 1,
      },
    ],
  };
}


export function getFiveSTypeColor(type) {
  if (type == "TERTIP") return "primary";
  else if (type == "DUZEN") return "secondary";
  else if (type == "TEMIZLIK") return "success";
  else if (type == "DISIPLIN") return "warning";
  else if (type == "STANDARTLASTIRMA") return "error";
  else if (type == "ISG") return "error";
};

export function sumSubArrayValues(inputArray, groupKey) {
  // Create an object to store the summed values
  const resultMap = {};

  // Iterate through the inputArray
  for (const subArray of inputArray) {
    for (const obj of subArray) {
      const keyValue = obj[groupKey];
      const { [groupKey]: _, ...rest } = obj;
      if (!resultMap[keyValue]) {
        resultMap[keyValue] = { [groupKey]: keyValue, ...rest };
      } else {
        for (const key in rest) {
          if (typeof obj[key] == 'string' || key == 'master_id') {
          resultMap[keyValue][key] = obj[key];
          } else resultMap[keyValue][key] = (resultMap[keyValue][key] || 0) + (obj[key] || 0);
        }
      }
    }
  }

  // Convert the resultMap object back to an array
  return Object.values(resultMap);
}


export function sumArrayValues(inputArray, groupKey) {
  // Create an object to store the summed values
  const resultMap = {};

  // Iterate through the inputArray
  for (const obj of inputArray) {
    const keyValue = obj[groupKey];
    const { [groupKey]: _, ...rest } = obj;

    if (!resultMap[keyValue]) {
      resultMap[keyValue] = { [groupKey]: keyValue, totalCount: 1, ...rest };
    } else {
      resultMap[keyValue]['totalCount'] += 1
      for (const key in rest) {
        if (typeof obj[key] === 'string' || ['id', 'RoleCode', 'Adi'].includes(key)) {
          resultMap[keyValue][key] = obj[key];
        } else {
          resultMap[keyValue][key] = (resultMap[keyValue][key] || 0) + (obj[key] || 0);
        }
      }
    }
  }

  // Convert the resultMap object back to an array
  return Object.values(resultMap);
}


export const months = [
  "Ocak",
  "Şubat",
  "Mart",
  "Nisan",
  "Mayıs",
  "Haziran",
  "Temmuz",
  "Ağustos",
  "Eylül",
  "Ekim",
  "Kasım",
  "Aralık",
];


export function FiveSGroups({ value }) {
  const { t } = useTranslation()
  return (
    <Chip color={getFiveSTypeColor(value)} variant="outlined" label={t(`fiveSActivities.types.${value}`)} />
  );
};

export function getImageUrl(imageId, thumbnail = true) {
  const userSession = sessionStorage.getItem("user");
  const user = JSON.parse(userSession);
  const newImageId = thumbnail ? `TN-${imageId}` : imageId;
  return `${MAIN_URL}/QualityEX/GetImage?ImageId=${newImageId}&GUID=${user.SessionGUID}&UserId=${user.UserId}`;
}

export function isFindingCompleted(finding) {
  return finding?.CompletedDate !== null;
}

export function isFindingApproved(finding) {
  return finding.ApproveDate !== null;
}

export function isFindingTransferred(finding) {
  return finding.FType !== 0;
}

export function TabPanel(props) {
  const { children, currentTab, value, ...other } = props;

  return (
    <div role="tabpanel" hidden={currentTab !== value} {...other}>
      {currentTab === value && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export function isFindingExpired(finding) {
  const today = new Date();
  const dueDate = new Date(finding.DueDate);
  return !isFindingCompleted(finding) && dueDate < today;
}

export function checkDeadline(finding, deadline) {
  const today = new Date();
  const dueDate = new Date(finding.DueDate);

  if (deadline === "past") {
    return isFindingExpired(finding);
  } else if (deadline === "nextWeek") {
    return (
      !isFindingCompleted(finding) &&
      dueDate - today > 0 &&
      dueDate - today < 7 * 24 * 60 * 60 * 1000
    );
  } else if (deadline === "nextMonth") {
    return (
      !isFindingCompleted(finding) &&
      dueDate - today > 0 &&
      dueDate - today < 30 * 24 * 60 * 60 * 1000
    );
  } else if (deadline === "upcoming") {
    return !isFindingCompleted(finding) && dueDate > today;
  } else if (deadline === "completed") {
    return isFindingCompleted(finding);
  } else if (deadline === "waitingForApproval") {
    return isFindingCompleted(finding) && !isFindingApproved(finding);
  } else if (deadline === "approved") {
    return isFindingApproved(finding);
  } else if (deadline === "conforming") {
    return (
      isFindingCompleted(finding) && new Date(finding.CompletedDate) < dueDate
    );
  } else return true;
}

export const isAuditCompleted = (audit) => {
  return audit.CompleteDate !== null;
};

export const isAuditExpired = (audit) => {
  const today = new Date();
  const startDate = new Date(audit.StartDate);
  return !isAuditCompleted(audit) && startDate - today < 0;
};

export const auditHasOpenFinding = (audit) => {
  return isAuditCompleted(audit) && audit.FindingCompleted < audit.FindingTotal;
};

export const auditAboutToExpire = (audit) => {
  const today = new Date();
  const startDate = new Date(audit.StartDate);
  return (
    !isAuditCompleted(audit) &&
    startDate - today > 0 &&
    startDate - today < 3 * 24 * 60 * 60 * 1000
  );
};

export function parentDepartmentIds(departments, departmentId) {
  const d = departments.find((q) => q.Id === departmentId);
  if (!d) return [];
  if (d.master_id === 0) {
    return [];
  } else return [d.master_id, ...parentDepartmentIds(departments, d.master_id)];
}

export function parentDepartments(departments, departmentId) {
  const d = departments.find((q) => q.Id === departmentId);
  if (!d) return [];
  if (d.master_id === 0) {
    return [];
  } else {
    const parent = departments.find((q) => q.Id === d.master_id);
    return [parent, ...parentDepartments(departments, parent.Id)];
  }
}

export function getChildren(departments, departmentId) {
  return departments.filter((d) => d.master_id === departmentId);
}

export function getDescendants(departments, departmentId, acc) {
  let accum = acc || [];
  const children = getChildren(departments, departmentId);
  for (let i = 0; i < children.length; i++) {
    accum.push(children[i]);
    getDescendants(departments, children[i].Id, accum);
  }
  return accum;
}

export async function uploadImage(imageURL, progressCallback) {
  // TODO: currently request interceptor disabled since it changes content-type
  // find a way to handle this inside interceptor. Do not change content-type for this request
  // http://144.91.100.115:8450/QualityEX/FileUpload?GUID=0059cc1a-1448-489a-9a31-6b382d4c5bd7&UserId=2&Sequence=19&imgtyp=0

  const imageFile = await axios({
    method: "get",
    url: imageURL,
    responseType: "blob",
  });
  const userSession = sessionStorage.getItem("user");
  const user = JSON.parse(userSession);
  const UserId = user.UserId;
  const SessionGUID = user.SessionGUID;
  var sequence = sessionStorage.getItem("sequence", "0");
  sequence = Number(sequence) + 1;
  sessionStorage.setItem("sequence", sequence);
  const url =
    MAIN_URL +
    "/QualityEX/FileUpload?GUID=" +
    SessionGUID +
    "&UserId=" +
    UserId +
    "&Sequence=" +
    sequence +
    "&imgtyp=0";
  const formData = new FormData();
  const totalSize = imageFile.size;
  console.log("total size", totalSize);
  formData.append("file", imageFile);

  const config = {
    onUploadProgress: (progressEvent) => {
      var pr = (100 * Number(progressEvent.loaded)) / totalSize;
      typeof progressCallback === "function" && progressCallback(pr);
    },
  };
  const uninterceptedAxiosInstance = axios.create();
  return uninterceptedAxiosInstance
    .post(url, formData, config)
    .then((data) => data.data)
    .then((data) => {
      return data[0].Name;
    });
}

export function getKins(departments, department) {
  let departmentList = [];
  const siblings = getChildren(departments, department.master_id);
  const descendants = getDescendants(departments, department.Id);
  const parents = parentDepartments(departments, department.Id);
  departmentList.push(...parents);
  departmentList.push(...siblings);
  departmentList.push(...descendants);
  return departmentList;
}

export function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

export function MagicDropZoneComponent(props) {
  const [previews, setPreviews] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [imageEditUrl, setImageEditUrl] = useState(null);
  const [imageId, setImageId] = useState(0);

  const onDrop = (accepted, rejected, links) => {
    accepted = accepted.map((v) => v.preview);
    console.log(accepted);
    var newPreviews = [...accepted, ...links];

    fabric.Image.fromURL(newPreviews[0], function(oImg) {
      // scale image down
      const height = Math.min(oImg.height, 720);
      oImg.scaleToHeight(height, false);
      const dataURL = oImg.toDataURL();
      const blob = dataURLtoBlob(dataURL);
      const resizedImageUrl = URL.createObjectURL(blob);
      setPreviews([resizedImageUrl]);
      props.setImage([resizedImageUrl]);
    });
  };

  const showImageEditor = (e, i) => {
    e.stopPropagation();
    setImageEditUrl(previews[i]);
    setImageId(i);
    console.log(imageEditUrl);
    console.log(imageId);
    setModalShow(true);
  };

  const setEditedImage = (id, url) => {
    let newPreviews = previews.slice();
    newPreviews[id] = url;
    setPreviews(newPreviews);
    props.setImage(newPreviews);
  };

  return (
    <div className="Dropzone-page">
      {modalShow && (
        <ImageEdit
          imagePath={imageEditUrl}
          imageId={imageId}
          show={modalShow}
          onHide={() => setModalShow(false)}
          onSetEditedImage={setEditedImage}
        />
      )}
      <MagicDropzone className="Dropzone" onDrop={onDrop}>
        <div className="Dropzone-content">
          {previews.length > 0
            ? previews.map((v, i) => (
              <div key={i}>
                <Button
                  variant="primary"
                  className="edit-image-button"
                  onClick={(e) => {
                    showImageEditor(e, i);
                  }}
                >
                  <i className="fa fa-pencil-square-o"></i>
                </Button>
                <img key={i} alt="" className="Dropzone-img" src={v} />
              </div>
            ))
            : "Resim seç veya bu alana sürükleyip bırak"}
        </div>
      </MagicDropzone>
    </div>
  );
}
