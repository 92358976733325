import TextField from "@mui/material/TextField";
import { useState } from "react";
import { useEffect } from "react";
import RoleService from "../../services/RolesServices";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import { AvatarGroup } from "@mui/material";
import stringAvatar from "./avatarFunctions";
import { useDispatch } from "react-redux";
import { showNotification } from "../../features/ui/notificationSlice";
import { Link } from "react-router-dom";

const Role = (props) => {
  const [name, setName] = useState(props.node.title);
  const dispatch = useDispatch();

  function update() {
    RoleService.update({ ...props.node, Adi: name })
      .then(() => {
        showNotification(dispatch, `Role updated succesfully!`);
      })
      .catch(() => {
        showNotification(
          dispatch,
          `Role could not be updated. Try again later.`,
          "danger",
        );
      })
      .finally(() => {
        props.updateCallback();
      });
  }

  useEffect(() => {
    if (props.update && props.currentNode === props.node.Id) {
      update();
    }
  }, [props.update]);

  return props.editing ? (
    <Stack spacing={2}>
      <TextField
        size="small"
        label="Role Name"
        onChange={(event) => {
          const name = event.target.value;
          setName(name);
          props.onSetData?.({ Adi: name });
        }}
        value={name}
        variant="standard"
      />
    </Stack>
  ) : (
    <Stack spacing={0}>
      <div>{props.node.title}</div>
      <AvatarGroup
        sx={{
          fontSize: 10,
        }}
        max={4}
      >
        {props.node.Users.split(",").map((userId) => {
          const user = props.users.find((user) => user.Id === Number(userId));
          if (user)
            return (
              <Avatar
                component={Link}
                style={{ textDecoration: "none" }}
                key={user.Id}
                to={`/users/${user.Id}`}
                {...stringAvatar(user.LongName)}
              />
            );
        })}
      </AvatarGroup>
    </Stack>
  );
};

export default Role;
