import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Fab from "@mui/material/Fab";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import AddIcon from "@mui/icons-material/Add";
import FilterListIcon from "@mui/icons-material/FilterList";
import InsertFindingDialog from "./InsertFindingDialog";
import { useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchFindings,
  selectFilteredFindings,
} from "../../features/findings/findingsSlice";
import FindingsFilter from "./FindingsFilter";
import FilterFindingsDialog from "./FilterFindingsDialog";

import FindingsList from "./FindingsList";
import { dialogActions } from "../../features/ui/dialogsSlice";
import { setFilter } from "../../features/ui/findingFiltersSlice";

import TransferFindingDialog from "./TransferFindingDialog";
import CompleteFindingDialog from "./CompleteFindingDialog";
import DetailsDialog from "./DetailsDialog";
import FindingsTable from "./FindingsTable";
import { LoginService } from "../../services";
import { userHasPermission } from "../../utils/authFunctions";
import { useTranslation } from "react-i18next";

const FindingsComponent = (props) => {
  const findings = useSelector(selectFilteredFindings);
  const data = props.data || findings;
  const approvalPage = props.approval;
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const { t } = useTranslation()

  function refreshFindings() {
    dispatch(fetchFindings());
  }

  useEffect(() => {
    refreshFindings(); // FindingsService.getAllFindings().then((response) => setData(response));
    dispatch(setFilter.department(null));
    dispatch(setFilter.status(null));
    dispatch(setFilter.type(null));
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container alignItems="flex-end" justifyContent="center">
      {smallScreen ? null : (
        <Grid item xs={12} md={11} m={{ md: 2, xs: 0 }} mt={{ xs: 1 }}>
          <FindingsFilter approval={approvalPage} />
        </Grid>
      )}{" "}
      <Grid item xs={12} mt={{ xs: 1 }}>
        {smallScreen ? (
          <FindingsList title="BULGULAR" data={data} listView />
        ) : (
          <FindingsTable data={data} />
        )}
      </Grid>
      {!smallScreen && (
        <Fab
          sx={{
            position: "fixed",
            bottom: (theme) => theme.spacing(2),
            right: (theme) => theme.spacing(2),
          }}
          variant={smallScreen ? "contained" : "extended"}
          color="primary"
          onClick={() => dispatch(dialogActions.toggleInsertFinding())}
        >
          <AddIcon /> {smallScreen ? "" : t('findings.addFinding')}
        </Fab>
      )}
      {smallScreen && (
        <SpeedDial
          ariaLabel="SpeedDial basic example"
          sx={{ position: "fixed", bottom: 16, right: 16 }}
          icon={<SpeedDialIcon />}
        >
          <SpeedDialAction
            key="add"
            icon={<AddIcon />}
            tooltipTitle="Bulgu Ekle"
            onClick={() => dispatch(dialogActions.toggleInsertFinding())}
          />
          <SpeedDialAction
            key="filter"
            icon={<FilterListIcon />}
            onClick={() => dispatch(dialogActions.toggleFilterFindings())}
            tooltipTitle="Filtrele"
          />
        </SpeedDial>
      )}
      <FilterFindingsDialog />
      <DetailsDialog />
      <TransferFindingDialog />
      <CompleteFindingDialog />
      <InsertFindingDialog callback={refreshFindings} />
    </Grid>
  );
};

export default FindingsComponent;
