import { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import * as React from "react";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { useMediaQuery, useTheme, Grid } from "@mui/material";
import InsertUserDialog from "./InsertUserDialog";
import { useSelector } from "react-redux";
import {
  selectFilteredUsers,
  selectUsers,
} from "../../features/users/usersSlice";

import { Container } from "@mui/material";
import UserDetail from "./UserDetail";
import Filter from "../common/Filter";
import { currentUserIsAdmin } from "../../utils/authFunctions";
import { useTranslation } from "react-i18next";

const Users = () => {
  const users = useSelector(selectUsers);
  const filteredUsers = useSelector(selectFilteredUsers);
  const isAdmin = currentUserIsAdmin();
  const [showInsertUserDialog, setShowInsertUserDialog] = useState(false);
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation()

  function toggleInsertUserDialog() {
    setShowInsertUserDialog(!showInsertUserDialog);
  }

  return (
    <div>
      {isAdmin && <Fab
          sx={{
            position: "fixed",
            bottom: (theme) => theme.spacing(2),
            right: (theme) => theme.spacing(2),
          }}
          variant={smallScreen ? "contained" : "extended"}
          color="primary"
          onClick={(event) => toggleInsertUserDialog()}
        >
          <AddIcon /> {smallScreen ? "" : "Add User"}
        </Fab>
      }
      {users.loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          {" "}
          <CircularProgress color="inherit" />{" "}
        </Backdrop>
      )}
      {!users.loading && users.error ? <div>Error: {users.error}</div> : null}
      {users.users.length > 0 ? (
        <Grid container alignItems="flex-end" justifyContent="center">
          <Container sx={{ width: { xs: "100%", md: "80%" }, marginX: "auto" }}>
            <Filter placeholder={t('ui.filterUsers')}/>
          </Container>

          {filteredUsers.map((match) => {
            return (
              <Grid item xs={12} mt={2} key={match.obj.Id}>
                <Container
                  sx={{ width: { xs: "100%", md: "80%" }, marginX: "auto" }}
                >
                  <UserDetail user={match.obj} match={match} />
                </Container>
              </Grid>
            );
          })}
        </Grid>
      ) : null}
      <InsertUserDialog
        show={showInsertUserDialog}
        onClose={toggleInsertUserDialog}
      />
    </div>
  );
};

export default Users;
