import { useState } from "react";
import { showConfirmDialog } from "../../features/ui/confirmDialogSlice";
import Divider from "@mui/material/Divider";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SummarizeIcon from "@mui/icons-material/Summarize";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ListItemIcon, Menu, MenuItem, IconButton } from "@mui/material";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DoneIcon from "@mui/icons-material/Done";

import { deleteFinding } from "../../features/findings/findingsSlice";
import { showNotification } from "../../features/ui/notificationSlice";
import { useDispatch } from "react-redux";
import { dialogActions } from "../../features/ui/dialogsSlice";
import {
  isFindingApproved,
  isFindingCompleted,
  isFindingTransferred,
} from "../../utils/commonFunctions";
import { useNavigate } from "react-router-dom";
import { LoginService } from "../../services";
import {
  currentUserIsAdmin,
  userHasPermission,
  currentUserIsAuditor
} from "../../utils/authFunctions";

export default function MoreActionsButton(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const completed = isFindingCompleted(props.finding);
  const transferred = isFindingTransferred(props.finding);
  const approved = isFindingApproved(props.finding);
  const currentUserId = LoginService.getUser().UserId;
  const isAnotherUser = props.finding.InchargeUserId !== currentUserId;
  const isAdmin = currentUserIsAdmin();
  const isAuditor = currentUserIsAuditor();
  const deletePermission = userHasPermission("delete");
  const navigate = useNavigate();

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    handleMenuClose();
    const confirmed = await showConfirmDialog(
      dispatch,
      "Bulguyu silmek istediğinize emin misiniz?",
    );
    if (confirmed) {
      dispatch(deleteFinding(props.finding.Id)).then(() => {
        showNotification(dispatch, "Bulgu başarıyla silindi");
      });
    }
  };

  const handleGetReport = async (qId, finding) => {
    handleMenuClose();
    navigate("/findingreport", { state: { finding: props.finding } });
  };

  const handleComplete = () => {
    dispatch(dialogActions.toggleCompleteFinding(props.finding));
    handleMenuClose();
  };

  const handleTransfer = () => {
    dispatch(dialogActions.toggleTransferFinding(props.finding));
    handleMenuClose();
  };

  const handleShowDetails = () => {
    dispatch(dialogActions.toggleFindingDetail(props.finding));
    handleMenuClose();
  };

  const canUserApprove = (finding) => {
    if (isAdmin)
      return true

    if (finding.ActivityType=="Audit" && isAuditor)
      return true

    return false

  }

  return (
    <>
      <IconButton onClick={handleMenuClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {!props.inDetailsDialog && (
          <MenuItem onClick={handleShowDetails}>
            <ListItemIcon>
              <VisibilityIcon fontSize="small" />
            </ListItemIcon>
            Show details
          </MenuItem>
        )}
        {!approved && (
          <MenuItem
            disabled={completed ? !canUserApprove(props.finding) : isAnotherUser}
            onClick={() => (completed ? handleTransfer() : handleComplete())}
          >
            <ListItemIcon>
              {completed ? (
                <DoneAllIcon color="success" />
              ) : (
                <DoneIcon color="success" />
              )}
            </ListItemIcon>
            {completed ? "Onayla" : "Tamamla"}
          </MenuItem>
        )}
        {!approved && (
          <MenuItem
            disabled={completed ? !canUserApprove(props.finding) : transferred || isAnotherUser}
            onClick={() =>
              completed ? console.log("doing nothing") : handleTransfer()
            }
          >
            <ListItemIcon>
              {completed ? (
                <CloseIcon color="error" />
              ) : (
                <ArrowForwardIcon color="primary" />
              )}
            </ListItemIcon>
            {completed ? "Reddet" : "Transfer"}
          </MenuItem>
        )}
        {deletePermission && (
          <MenuItem onClick={handleDelete}>
            <ListItemIcon>
              <DeleteIcon color="error" fontSize="small" />
            </ListItemIcon>
            Delete
          </MenuItem>
        )}
        <Divider />
        <MenuItem onClick={handleGetReport}>
          <ListItemIcon>
            <SummarizeIcon color="primary" fontSize="small" />
          </ListItemIcon>
          Rapor Al
        </MenuItem>
      </Menu>
    </>
  );
}
