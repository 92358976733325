import { MAIN_URL } from "./ServiceConstants";
import axios from "axios";

import dayjs from "dayjs";

async function getAudits(start, end) {
  return axios.post(MAIN_URL + "/Quality/SetMainActivity", {
    dataobject: {
      Action: "S",
      StartDate: start,
      EndDate: end,
    },
  });
}

async function updateAudits(audit) {
  return axios.post(MAIN_URL + "/Quality/SetMainActivity", {
    dataobject: {
      Action: "U",
      ...audit,
    },
  });
}

async function getNonCompleteAudits() {
  return axios.post(MAIN_URL + "/Quality/SetMainActivity", {
    dataobject: {
      Action: "N",
    },
  });
}

async function getAuditById(id) {
  return axios.post(MAIN_URL + "/Quality/SetMainActivity", {
    dataobject: {
      Action: "S",
      Id: id,
    },
  });
}

async function getPeriodicAudits() {
  return axios.post(MAIN_URL + "/Quality/SetMainActivity", {
    dataobject: {
      Action: "S",
      MasterId: -1,
    },
  });
}

async function createNewAudit({
  start,
  due,
  departmentId,
  responsibleUser,
  auditorUser,
}) {
  return axios.post(MAIN_URL + "/Quality/SetMainActivity", {
    dataobject: {
      Action: "I",
      ActivityType: "Audit",
      StartDate: start,
      DueDate: due,
      DepartmentId: departmentId,
      InchargeUserId: responsibleUser,
      AuditorId: auditorUser,
    },
  });
}

async function createFieldTour({
  date,
  participants,
  auditorUser,
  departmentId,
}) {
  return axios.post(MAIN_URL + "/Quality/SetMainActivity", {
    dataobject: {
      Action: "I",
      ActivityType: "FieldTour",
      StartDate: date,
      DueDate: dayjs(date).add(3, "day"),
      DepartmentId: departmentId,
      Participants: participants,
      AuditorId: auditorUser,
    },
  });
}

async function createMeeting({
  date,
  participants,
  auditorUser,
  departmentId,
}) {
  return axios.post(MAIN_URL + "/Quality/SetMainActivity", {
    dataobject: {
      Action: "I",
      ActivityType: "Meeting",
      StartDate: date,
      DueDate: dayjs(date).add(3, "day"),
      DepartmentId: departmentId,
      Participants: participants,
      AuditorId: auditorUser,
    },
  });
}

async function createWorkshop({
  date,
  participants,
  auditorUser,
  departmentId,
}) {
  return axios.post(MAIN_URL + "/Quality/SetMainActivity", {
    dataobject: {
      Action: "I",
      ActivityType: "Workshop",
      StartDate: date,
      DueDate: dayjs(date).add(3, "day"),
      DepartmentId: departmentId,
      Participants: participants,
      AuditorId: auditorUser,
    },
  });
}

async function createPeriodicAudit({ start, end, period }) {
  return axios.post(MAIN_URL + "/Quality/SetPeriodicMainActivity", {
    dataobject: {
      DepartmentId: 0,
      AuditStart: start,
      AuditEnd: end,
      Period: period,
    },
  });
}

async function createAuditQuestions(auditId) {
  return axios.post(MAIN_URL + "/Quality/SetCreateQuestions", {
    dataobject: {
      Id: auditId,
    },
  });
}

async function setAuditResponseValue(id, response) {
  return axios.post(MAIN_URL + "/Quality/SetActivityQuestionResponse", {
    dataobject: {
      Id: id,
      Response: response,
    },
  });
}

async function updateParticipants(activityId, participants) {
  return axios.post(MAIN_URL + "/Quality/SetParticipants", {
    dataobject: {
      Action: "U",
      MainActivityId: activityId,
      Participants: participants,
    },
  });
}

async function updateAuditCompleteTime(auditId, date) {
  return axios.post(MAIN_URL + "/Quality/SetMainActivity", {
    dataobject: {
      Action: "U",
      Id: auditId,
      CompleteDate: date,
    },
  });
}

async function transferAuditInchargeUserOrAuditor(oldUser, newUser, action) {
  return axios.post(MAIN_URL + "/Quality/SetTransferTask", {
    dataobject: {
      Action: action,
      OldUserId: oldUser,
      NewUserId: newUser,
    },
  });
}

async function getAuditQuestionById(id) {
  return axios.post(MAIN_URL + "/Quality/GetActivityQuestion", {
    dataobject: {
      Action: "S",
      Id: id,
    },
  });
}

async function deleteAuditById(id) {
  return axios.post(MAIN_URL + "/Quality/SetMainActivity", {
    dataobject: {
      Action: "D",
      Id: id,
    },
  });
}

async function completeAudit(id) {
  return axios.post(MAIN_URL + "/Quality/SetMainActivity", {
    dataobject: {
      Action: "C",
      Id: id,
    },
  });
}

const AuditService = {
  getAudits,
  updateAudits,
  getNonCompleteAudits,
  getAuditById,
  setAuditResponseValue,
  createNewAudit,
  getAuditQuestionById,
  completeAudit,
  createPeriodicAudit,
  createFieldTour,
  createWorkshop,
  createMeeting,
  getPeriodicAudits,
  deleteAuditById,
  createAuditQuestions,
  updateParticipants,
  transferAuditInchargeUserOrAuditor,
};

export default AuditService;
