const initialState = {
  prompt: "",
  approve: "",
  isOpen: false,
  proceed: null,
  cancel: null,
};

export default function modalDialogWithInputReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case "modalDialogWithInput/show": {
      return action.payload;
    }
    default:
      return state;
  }
}

export function showConfirmDialogWithInput(dispatch, message, approveText) {
  const promise = new Promise((resolve, reject) => {
    dispatch({
      type: "modalDialogWithInput/show",
      payload: {
        isOpen: true,
        prompt: message,
        proceed: resolve,
        cancel: reject,
        approve: approveText,
      },
    });
  });
  return promise.then(
    () => {
      dispatch({
        type: "modalDialogWithInput/show",
        payload: {
          isOpen: false,
          prompt: "",
          proceed: null,
          cancel: null,
          approve: "",
        },
      });
      return true;
    },
    () => {
      dispatch({
        type: "modalDialogWithInput/show",
        payload: {
          isOpen: false,
          prompt: "",
          proceed: null,
          cancel: null,
          approve: "",
        },
      });
      return false;
    },
  );
}

export const selectConfirmDialogWithInput = (state) =>
  state.ui.confirmModalWithInput;
