import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Calendar from "@toast-ui/react-calendar";
import { Button, Typography } from "@mui/material";
import { Container, Stack } from "@mui/material";
import AuditService from "../../../services/AuditService";
import { DateFormat } from "../../../utils/DateFormat";
import { useTranslation } from "react-i18next";

export default function AuditCalendarComponent() {
  let calendarRef = React.createRef();
  const [events, setEvents] = useState([]);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [infoText, setInfoText] = useState("");
  const { t } = useTranslation()
  const navigate = useNavigate();

  function updateCalendarStartEnd() {
    const calendarInstance = calendarRef.current.getInstance();
    const calDate = calendarInstance.getDate();
    const rangeStart = calendarInstance.getDateRangeStart();
    const rangeEnd = calendarInstance.getDateRangeEnd();

    let year = calDate.getFullYear();
    let month = calDate.getMonth() + 1;
    let date = calDate.getDate();
    let dateRangeText = `${year}-${month}`;
    setInfoText(dateRangeText);

    var firstDayOfMonth = new Date(year, month - 1, 1);
    var lastDayOfMonth = new Date(year, month, 1);

    //console.log(firstDayOfMonth.toDateString() )
    //console.log(lastDayOfMonth.toDateString() )

    setStart(firstDayOfMonth);
    setEnd(lastDayOfMonth);
  }

  const handleClickTodayButton = () => {
    const calendarInstance = calendarRef.current.getInstance();
    calendarInstance.today();
    updateCalendarStartEnd();
  };

  const handleClickNextButton = () => {
    const calendarInstance = calendarRef.current.getInstance();
    calendarInstance.next();
    updateCalendarStartEnd();
  };

  const onClickEvent = (res) => {
    navigate("/audits/" + res.event.id);
  };

  const handleClickPrevButton = () => {
    const calendarInstance = calendarRef.current.getInstance();
    calendarInstance.prev();
    updateCalendarStartEnd();
  };

  useEffect(() => {
    if (start == null) updateCalendarStartEnd();

    AuditService.getAudits(
      DateFormat.convert2ddmmyyyy(start),
      DateFormat.convert2ddmmyyyy(end),
    ).then((response) => {
      let ev = response.Table.filter(
        (audit) => audit.Department && audit.MasterId !== -1,
      ).map((audit) => {
        return {
          id: audit.Id,
          calendarId: "cal1",
          title: audit.Department,
          category: "time",
          start: audit.StartDate,
          end: new Date(new Date(audit.StartDate) + 3600 * 1000),
        };
      });

      setEvents(ev);
    });

    return () => {};
  }, [start, end]);

  const calendars = [{ id: "cal1", name: "Personal" }];

  return (
    <Container>
      <Stack direction="row" gap={1} p={1}>
        <Button
          variant="contained"
          className="m-3"
          onClick={handleClickTodayButton}
        >
          {t('buttons.today')}
        </Button>

        <Button
          variant="contained"
          className="m-3"
          onClick={handleClickPrevButton}
        >
          {t('buttons.prevMonth')}
        </Button>
        <Button
          variant="contained"
          className="m-3"
          onClick={handleClickNextButton}
        >
          {t('buttons.nextMonth')}
        </Button>

        <Typography>{infoText}</Typography>
      </Stack>

      <Stack>
        <div>
          <Calendar
            ref={calendarRef}
            usageStatistics={false}
            height="600px"
            view="month"
            week={{
              taskView: "time",
              eventView: true,
            }}
            allDaySlot="false"
            isReadOnly="true"
            calendars={calendars}
            events={events}
            onClickEvent={onClickEvent}
          />
        </div>
      </Stack>
    </Container>
  );
}
