import { Navigate } from "react-router-dom";
import { useEffect } from "react";
// import { ResponsiveAppBar } from "../components/Navbar/Navbar";
import ResponsiveDrawer from "../components/Navbar/Navbar";
import { initialize } from "../features/common";
import { fetchDepartments } from "../features/departments/departmentsSlice";
import { fetchRoles } from "../features/roles/rolesSlice";
import { fetchRoleUsers } from "../features/roleUsers/roleUsersSlice";
import { fetchUsers } from "../features/users/usersSlice";
import {
  fetchAudits,
  fetchNonCompleteAudits,
} from "../features/audits/auditsSlice";
import { fetchPeriodicAudits } from "../features/audits/auditsSlice";
import { LoginService } from "../services";
import store from "../store";
import { fetchFindings } from "../features/findings/findingsSlice";
import { fetchSettings } from "../features/settings/settingsSlice";
import { fetchRestrictedQuestions } from "../features/questions/questionsSlice";
import { fetchDeviationRequests } from "../features/deviations/deviationsSlice";

export default function ProtectedPage({ showNavbar, children }) {
  useEffect(() => {
    const user = LoginService.getUser();
    if (user) {
      if (!store.getState().common.initialized) {
        console.log("init data for redux");
        store.dispatch(initialize());
        store.dispatch(fetchDepartments());
        store.dispatch(fetchRoles);
        store.dispatch(fetchRoleUsers);
        store.dispatch(fetchUsers());
        store.dispatch(fetchFindings());
        store.dispatch(fetchAudits);
        store.dispatch(fetchPeriodicAudits);
        store.dispatch(fetchNonCompleteAudits);
        store.dispatch(fetchSettings());
        store.dispatch(fetchRestrictedQuestions())
        store.dispatch(fetchDeviationRequests())

        console.log(store.getState());
      }
    }

    return () => {};
  }, []);

  const user = LoginService.getUser();
  if (!user) {
    console.log(user);
    return <Navigate to="/login" replace />;
  } else if (showNavbar === true) {
    return (
      <div>
        {/* <Navbar /> */}
        {/* <ResponsiveAppBar>{children}</ResponsiveAppBar> */}
        <ResponsiveDrawer>{children}</ResponsiveDrawer>
      </div>
    );
  } else {
    return children;
  }
}
