import QuestionPool from "../components/QuestionPool/FiveSQuestions";

function FiveSQuestionPoolPage(props) {
  return (
    <div className="col-10 mx-auto">
      <QuestionPool></QuestionPool>
    </div>
  );
}

export default FiveSQuestionPoolPage;
