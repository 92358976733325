import { useEffect, useRef, useState } from "react";
import {
  Button,
  LinearProgress,
  Card,
  CardContent,
  Typography,
  TextField,
  Stack,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import EditableAvatar from "../common/EditableAvatar";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSettings,
  selectSettings,
  updateSettings,
} from "../../features/settings/settingsSlice";

const FactorySettings = () => {
  const settings = useSelector(selectSettings);
  const [editMode, setEditMode] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [progress, setProgress] = useState(0);
  const avatarRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    setFormValues(settings);
  }, [settings, editMode]);

  useEffect(() => {
    dispatch(fetchSettings());
  }, []);

  function toggleEditMode() {
    setEditMode(!editMode);
    setProgress(0);
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  async function handleSubmit() {
    const imageId = await avatarRef.current.upload();
    const data = {
      ...formValues,
      Logo: imageId,
    };
    setFormValues({ ...data });
    dispatch(updateSettings(data)).then(toggleEditMode);
  }

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <LinearProgress variant="determinate" value={progress} />
        <CardContent>
          <Stack gap={2} alignItems="center">
            <EditableAvatar
              ref={avatarRef}
              imageId={formValues.Logo}
              alt={formValues.FactoryName}
              setProgress={setProgress}
              editable={editMode}
            />
            {editMode ? (
              <TextField
                value={formValues.FactoryName}
                variant="outlined"
                name="FactoryName"
                label="Factory Name"
                onChange={handleInputChange}
              />
            ) : (
              <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                {settings.FactoryName}
              </Typography>
            )}
            {editMode ? (
              <TextField
                value={formValues.Email}
                variant="outlined"
                name="Email"
                label="Email"
                onChange={handleInputChange}
              />
            ) : (
              <Typography variant="h5">{settings.Email}</Typography>
            )}
            {editMode ? (
              <TextField
                value={formValues.Phone}
                variant="outlined"
                name="Phone"
                label="Phone"
                onChange={handleInputChange}
              />
            ) : (
              <Typography variant="h5">{settings.Phone}</Typography>
            )}
          </Stack>
          <Stack gap={2} justifyContent="flex-end" width="100%" direction="row">
            {editMode ? (
              <>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={toggleEditMode}
                  startIcon={<CloseIcon />}
                >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  color="success"
                  onClick={handleSubmit}
                  startIcon={<CheckIcon />}
                >
                  Save
                </Button>
              </>
            ) : (
              <Button
                variant="outlined"
                onClick={toggleEditMode}
                startIcon={<EditIcon />}
              >
                Edit Settings
              </Button>
            )}
          </Stack>
        </CardContent>
      </Card>
    </form>
  );
};

export default FactorySettings;
