import { Stack, Switch, FormControlLabel, Paper, Grid, Button, Skeleton } from "@mui/material";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchDepartmentsWithPeriodicScores,
  fetchDepartmentsWithPeriodicScoresYear,
  selectDepartments,
  selectComparableDepartments,
  selectDepartmentsWithPeriodicScores,
  selectDepartmentsWithPeriodicScoresYear,
  selectDepartmentsVisibleToCurrentUser,
  selectDepartmentsOfCurrentUser,
} from "../../features/departments/departmentsSlice";
import FindingStats from "../common/FindingStats";
import FindingsList from "../Findings/FindingsList";
import { useEffect, useRef, useState } from "react";
import { setFilter } from "../../features/ui/findingFiltersSlice";
import {
  fetchDepartmentStatistics,
  selectFilteredFindingList,
  selectFindingList,
} from "../../features/ui/findingStatsSlice";
import AnimatedNumber from "react-animated-number/build/AnimatedNumber";
import RadarChart from "../common/RadarChart";
import { DoughnutChart } from "../common/DoughnutChart";

import dayjs from "dayjs";
import EasyDepartmentSelect from "../common/EasyDepartmentSelect";
import YearMonthSelector from "../common/YearMonthSelector";
import SubDepartmentsReport from "../Reports/SubDepartmentsReport";
import SubDepartments5SPoints from "../Reports/SubDepartments5SPoints";
import SubDepartmentsAuditCounts from "../Reports/SubDepartmentsAuditCounts";
import YearlyGraph from "../Reports/YearlyGraph";
import {
  fetchAuditorsWithAuditScores,
  selectAuditorsWithAuditScores,
} from "../../features/users/usersSlice";
import AuditorScores from "../Reports/AuditorScores";
import PrettyDepartment from "../common/PrettyDepartment";
import YearlyFindingGraph from "../Reports/YearlyFindingGraph";
import YearlyAuditGraph from "../Reports/YearlyAuditGraph";
import { sumSubArrayValues } from "../../utils/commonFunctions";
import Department5SPoints from "../Reports/Department5SPoints";
import { useTranslation } from "react-i18next";

const DepartmentDetail = (props) => {
  const params = useParams();
  const id = !props.id ? params.id : props.id;
  const navigate = props.id == undefined;
  const ref = useRef()
  const departments = useSelector(selectDepartments);
  const viewableDepartments = useSelector(
    selectDepartmentsVisibleToCurrentUser
  );
  const departmentsOfCurrentUser = useSelector(
    selectDepartmentsOfCurrentUser
  );
  const [department, setDepartment] = useState(
    departments.find((d) => d.Id === Number(id))
  );
  const [viewable, setViewable] = useState(true);
  const [startDate, setStartDate] = useState(dayjs().startOf("month").toDate());
  const [year, setYear] = useState();
  const dispatch = useDispatch();
  const departmentsWithPeriodicScores = useSelector(
    selectDepartmentsWithPeriodicScoresYear
  );
  const auditors = useSelector(selectAuditorsWithAuditScores);
  const [yearly, setYearly] = useState(false)
  const { t } = useTranslation()

  function changeSelectedDepartment(department) {
    setViewable(
      Boolean(viewableDepartments.find((d) => d.Id == department.Id))
    );
    dispatch(setFilter.department(department));
    dispatch(setFilter.inChargeUserId(null));
    setDepartment(department);
  }

  function departmentChange(id, goParent=false) {
    const department = departments.find(d=>d.Id == id)
    let correctId = id
    let correctDepartment = department
    if (goParent) {
      correctDepartment = departments.find(d=>d.Id == department.master_id)
      if (correctDepartment) {
        correctId = correctDepartment.Id
      } else {
        correctDepartment = department
      }
    }
    ref.current.setDepartment(correctId)
    changeSelectedDepartment(correctDepartment)
  }

  useEffect(() => {
    changeSelectedDepartment(department);
  }, []);

  function changeSelectedDate(newDate) {
    const start = dayjs(newDate).startOf("month").toDate();
    setStartDate(start);
    const nYear = dayjs(newDate).year();
    if (nYear !== year) {
      setYear(nYear);
      dispatch(fetchDepartmentsWithPeriodicScoresYear(nYear));
      dispatch(fetchAuditorsWithAuditScores(nYear));
    }
  }

  return (
    <Stack gap={1}>
      {!(params.id && props.id) && (
        <Paper sx={{ position: "sticky", top: 108, zIndex: 5 }}>
          <Stack direction="horizontal" justifyContent="space-between">
            <EasyDepartmentSelect
              id={id}
              ref={ref}
              navigatePages={navigate}
              onChange={changeSelectedDepartment}
            />
            <FormControlLabel control={<Switch value={yearly} onChange={(e)=>setYearly(e.target.checked)}/>} label={t('ui.yearly')} />

          </Stack>

          <YearMonthSelector onChange={changeSelectedDate} />
        </Paper>
      )}
      {department && viewable ? (
        <>
          {departmentsWithPeriodicScores.length > 0 ? (
            <Stack gap={2}>
                <Department5SPoints
                  parent={department}
                  currentMonth={startDate.getMonth()}
                  currentYear={startDate.getFullYear()}
                  yearly={yearly}
                />

              <YearlyAuditGraph department={department} currentYear={startDate.getFullYear()} />
              <YearlyFindingGraph department={department} currentYear={startDate.getFullYear()} />
              <YearlyGraph department={department} currentYear={startDate.getFullYear()} />
            </Stack>
          ) : <Stack gap={2}>
              <Skeleton component={Paper} elevation={12} variant="rectangular" height={412} />
              <Skeleton component={Paper} elevation={12} variant="rectangular" height={590} />
              <Skeleton component={Paper} elevation={12} variant="rectangular" height={590} />
              <Skeleton component={Paper} elevation={12} variant="rectangular" height={552} />
              <Skeleton component={Paper} elevation={12} variant="rectangular" height={327} />
            </Stack>}
        </>
      ) : <div></div>}
      {department && viewable ? (
        <div>
          <>
            {departmentsWithPeriodicScores.length > 0 ? (
              <Stack gap={2}>
                <SubDepartmentsAuditCounts
                  parent={department}
                  currentMonth={startDate.getMonth()}
                  currentYear={startDate.getFullYear()}
                  yearly={yearly}
                  onDepartmentChange={departmentChange}
                />
                <SubDepartmentsReport
                  parent={department}
                  currentMonth={startDate.getMonth()}
                  currentYear={startDate.getFullYear()}
                  yearly={yearly}
                />
                <SubDepartments5SPoints
                  parent={department}
                  currentYear={startDate.getFullYear()}
                  currentMonth={startDate.getMonth()}
                  yearly={yearly}
                  onDepartmentChange={departmentChange}
                />
              </Stack>
            ) : <Stack gap={2}>
                  <Skeleton component={Paper} elevation={12} variant="rectangular" height={590} />
                  <Skeleton component={Paper} elevation={12} variant="rectangular" height={327} />
                  <Skeleton component={Paper} elevation={12} variant="rectangular" height={590} />
              </Stack>}
          </>
        </div>
      ) : (<div>
        <h3>Bu sayfayı görüntüleme yetkiniz yok!</h3>
        <p>Aşağıdaki departmanların raporlarını görüntüleyebilirsiniz:</p>
        <ul>
          {departmentsOfCurrentUser.map(d =>
            <li><Button sx={{ textAlign: "left" }} onClick={() => {
              changeSelectedDepartment(d)
              ref.current.setDepartment(d.Id)
            }}><PrettyDepartment department={d} /></Button></li>
          )}
        </ul>
      </div>

      )}
    </Stack>
  );
};

export default DepartmentDetail;
