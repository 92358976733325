import { useDispatch, useSelector } from "react-redux";
import {
  approveDeviation,
  deleteDeviation,
  denyDeviation,
  fetchDeviationRequests,
  selectDeviations,
} from "../../features/deviations/deviationsSlice";
import { useEffect } from "react";
import { userHasPermission } from "../../utils/authFunctions";
import { Button, Chip, Stack } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { DateFormat } from "../../utils/DateFormat";
import { FiveSGroups } from "../../utils/commonFunctions";
import DeviationTypes from "./DeviationTypes";

import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { showConfirmDialog } from "../../features/ui/confirmDialogSlice";
import { useTranslation } from "react-i18next";

const DeviationRequests = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchDeviationRequests());
  }, []);
  const { t } = useTranslation()

  const updatePermission = userHasPermission("update");
  const columns = [
    { headerName: "Id", field: "Id", maxWidth: 60 },
    { headerName: t('ui.createdBy'), field: "LongName", minWidth: 170 },
    { headerName: t('ui.department'), field: "Adi", minWidth: 150 },
    {
      headerName: t('ui.beginning'),
      field: "StartDate",
      minWidth: 100,
      valueGetter: (params) => DateFormat.dayMonthYear(params.value),
    },
    {
      headerName: t('ui.ending'),
      field: "EndDate",
      minWidth: 100,
      valueGetter: (params) => DateFormat.dayMonthYear(params.value),
    },
    {
      headerName: t('ui.deviationType'),
      field: "SapmaType",
      minWidth: 230,
      renderCell: (params) => {
        return (
          <Stack direction="row" gap={1}>
            {params.value.split(",").map((item) => {
              const type = DeviationTypes.find((type, i) => i == item);
              return (
                <img
                  height={25}
                  title={type.name}
                  alt={type.name}
                  src={type.image}
                />
              );
            })}
          </Stack>
        );
      },
    },
    {
      headerName: t('ui.fiveSType'),
      field: "FiveSType",
      minWidth: 250,
      renderCell: (params) => {
        return (
          <Stack gap={1} direction="row">
            {params.value.split(",").map((item) => (
              <FiveSGroups value={item} />
            ))}
          </Stack>
        );
      },
    },
    { headerName: t('ui.desc'), field: "Note", minWidth: 300 },
    {
      headerName: t('ui.status'),
      renderCell: (params) => {
        const approved = params.row.ApproverId !== 0;
        return (
          <Chip
            label={t(approved ? "ui.approved" : "ui.waiting")}
            color={approved ? "success" : "warning"}
          />
        );
      },
      minWidth: 170,
    },
    {
      headerName: t('ui.actions'),
      field: "actions",
      type: "actions",
      getActions: (params) => {
        const approved = params.row.ApproverId !== 0;
        let actions = [
          <GridActionsCellItem
            color="error"
            icon={<DeleteIcon />}
            disabled={!updatePermission}
            onClick={() => {
              confirmDeleteDeviation(params.row.Id)
            }}
            label={t('buttons.delete')}
          />,
          <GridActionsCellItem
            color={approved ? "error" : "success"}
            disabled={!updatePermission}
            icon={approved ? <CloseIcon /> : <DoneIcon />}
            onClick={() =>
              approved
                ? dispatch(denyDeviation(params.row.Id))
                : dispatch(approveDeviation(params.row.Id))
            }
            label={t(approved ? 'buttons.reject' : 'buttons.confirm')}
          />,
        ];
        return actions;
      },
      sortable: false,
      hide: props.hideActions
    },
  ];

  async function confirmDeleteDeviation(id) {
    const confirmed = await showConfirmDialog(
      dispatch,
      `${id} numaralı sapma isteğini silmek istediğinize emin misiniz?`
    );
    if (confirmed) {
      dispatch(deleteDeviation(id)).then(() =>
        dispatch(fetchDeviationRequests())
      );
    }
  }

  return props.data.length ? (
    <div>
      <DataGrid
        autoHeight
        rows={props.data}
        columns={columns}
        getRowId={(row) => row.Id}
        hideFooter={true}
      />
    </div>
  ) : (
    <h4>Gösterilecek sapma isteği yok.</h4>
  );
};

export default DeviationRequests;
