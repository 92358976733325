import { useEffect, useRef } from "react";
import axios from "axios";

function ResponseInterceptor() {
  const interceptorId = useRef(null);
  const interceptorId2 = useRef(null);
  useEffect(() => {
    console.log("init axios interceptors");
    interceptorId.current = axios.interceptors.response.use(
      (data) => {
        if (data.status == 200) {
          return data.data;
        } else if (data.status == 401) {
          console.log(
            "Response not return 200. This text comes from response interceptor",
          );
          window.location.href = "/login";
        } else {
          console.log(
            "Response not return 200. This text comes from response interceptor",
          );
          window.location.href = "/login";
          return data;
        }
      },
      (error) => {
        console.log(error);
      },
    );

    interceptorId2.current = axios.interceptors.request.use((request) => {
      const userSession = sessionStorage.getItem("user");
      if (userSession && userSession != null) {
        // add user info and sequence to query
        const user = JSON.parse(userSession);
        if (user) {
          const UserId = user.UserId;
          const SessionGUID = user.SessionGUID;
          var sequence = sessionStorage.getItem("sequence", "0");
          sequence = Number(sequence) + 1;
          sessionStorage.setItem("sequence", sequence);
          request.data = { UserId, SessionGUID, sequence, ...request.data };
        }
      }

      request.headers["Content-Type"] = "text/plain";
      return request;
    });

    return () => {
      axios.interceptors.response.eject(interceptorId.current);
      axios.interceptors.response.eject(interceptorId2.current);
    };
  }, []);

  return null;
}

export default ResponseInterceptor;
