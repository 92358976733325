import Button from "@mui/material/Button";
import "tui-image-editor/dist/tui-image-editor.css";
import "tui-color-picker/dist/tui-color-picker.css";
import ImageEditor from "@toast-ui/react-image-editor";
import React, { useRef } from "react";
import "./ImageEdit.css";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { dataURLtoBlob } from "./commonFunctions";
const myTheme = {
  // Theme object to extends default dark theme.
  "header.display": "none",
};

export default function ImageEdit(props) {
  const editorRef = useRef();

  const handleSave = () => {
    const editorInstance = editorRef.current.getInstance();
    const dataURL = editorInstance.toDataURL();
    const blob = dataURLtoBlob(dataURL);
    const url = URL.createObjectURL(blob);
    props.onSetEditedImage(props.imageId, url);
    props.onHide();
  };

  return (
    <Dialog
      open={props.show}
      onHide={props.onHide}
      // enforceFocus={false}
      id="image-edit"
      fullScreen
    >
      <DialogContent className="image-editor">
        <ImageEditor
          ref={editorRef}
          includeUI={{
            loadImage: {
              path: props.imagePath,
              name: "SampleImage",
            },
            theme: myTheme,
            menu: ["crop", "rotate", "shape"],
            initMenu: "shape",
            uiSize: {
              width: "1000px",
              height: "700px",
            },
            menuBarPosition: "bottom",
          }}
          cssMaxHeight={500}
          cssMaxWidth={700}
          selectionStyle={{
            cornerSize: 20,
            rotatingPointOffset: 70,
          }}
          usageStatistics={false}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="secondary" onClick={props.onHide}>
          Cancel
        </Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}
