import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useSelector } from "react-redux";
import { selectConfirmDialogWithInput } from "../../features/ui/confirmDialogWithInputSlice";
import { TextField, Typography } from "@mui/material";

const Transition = React.forwardRef(function Transition(
  props,
  ref,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmModalWithInput = () => {
  const [text, setText] = useState("");

  const handleTextChange = (e) => {
    console.log(e.target.value);
    setText(e.target.value);
  };
  const { prompt, approve, isOpen, proceed, cancel } = useSelector(
    selectConfirmDialogWithInput,
  );
  return (
    <Dialog open={isOpen} TransitionComponent={Transition}>
      <DialogTitle>{prompt}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography>
            Please type <b>"{approve}"</b> to confirm:
          </Typography>
          <TextField fullWidth variant="outlined" onChange={handleTextChange} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus variant="outlined" color="secondary" onClick={cancel}>
          Cancel
        </Button>
        <Button
          disabled={text !== approve}
          className="ml-4"
          variant="outlined"
          onClick={proceed}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModalWithInput;
