import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Stack, TextField } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import FindingsService from "../../services/FindingsService";
import {
  MagicDropZoneComponent,
  uploadImage,
} from "../../utils/commonFunctions";
import { dialogActions, selectDialog } from "../../features/ui/dialogsSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchFindings } from "../../features/findings/findingsSlice";
import { useTranslation } from "react-i18next";

export default function CompleteFindingDialog() {
  const currentFinding = useSelector(selectDialog).currentFinding;
  const open = useSelector(selectDialog).completeFinding;
  const dispatch = useDispatch();
  const defaultValues = {
    description: "",
    image: "",
  };
  const [formValues, setFormValues] = useState(defaultValues);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(false);
  const { t } = useTranslation()

  function hideDialog() {
    dispatch(dialogActions.toggleCompleteFinding());
    setFormValues(defaultValues);
    setError(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formValues.image) {
      return;
    }
    const imageId = await uploadImage(formValues.image, setProgress);
    FindingsService.completeFinding({
      ...formValues,
      findingId: currentFinding.Id,
      image: imageId,
    }).then(() => {
      dispatch(fetchFindings());
      hideDialog();
      setProgress(0);
    });
    console.log(formValues);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  return (
    <Dialog open={open} fullWidth>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={Boolean(progress)}
      >
        <CircularProgress
          variant="determinate"
          value={progress}
          color="primary"
        />
      </Backdrop>
      <DialogTitle>{t('findings.completeFinding')}</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Stack gap={2}>
            <Typography>Fotoğraflar</Typography>
            <MagicDropZoneComponent
              setImage={(image) =>
                setFormValues({ ...formValues, ["image"]: image })
              }
            />
            {error && !formValues.image && (
              <Typography color="error">Bir fotoğraf yükleyin</Typography>
            )}
            <TextField
              label="Açıklama"
              name="description"
              onChange={handleInputChange}
              required
              error={error && !formValues.description}
              multiline
              rows={4}
              value={formValues.description}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={hideDialog}>
            Cancel
          </Button>
          <Button
            variant="outlined"
            type="submit"
            onClick={() => setError(true)}
          >
            Ok
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
