import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FindingsFilter from "./FindingsFilter";
import { selectDialog, dialogActions } from "../../features/ui/dialogsSlice";
import { useDispatch, useSelector } from "react-redux";

export default function FilterFindingsDialog() {
  const dialogVisibility = useSelector(selectDialog);
  const dispatch = useDispatch();
  function hideDialog() {
    dispatch(dialogActions.toggleFilterFindings());
  }
  return (
    <div>
      <Dialog open={dialogVisibility.filterFindings} fullWidth>
        <DialogTitle>Bulguları Filtrele</DialogTitle>
        <DialogContent>
          <FindingsFilter />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={hideDialog}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
