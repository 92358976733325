import { LoginService } from "../services";
import { getDescendants } from "./commonFunctions";

const restrictedUsersRestrictions = [
  "delete",
  "update",
  "add",
  "viewOthers",
  "viewModeratorPages",
  "viewChilds",
];

export function currentUserIsAdmin() {
  const user = LoginService.getUser();
  return user.isAdmin;
}

export function currentUserIsAuditor() {
  const user = LoginService.getUser();
  for (let index = 0; index < user.roles.length; index++) {
    const role = user.roles[index];
    if (role.RoleCode == "DE") {
      return true
    }
  }
  return false;
}

export function getRoleCodeDepartments(roleCode) {
  const user = LoginService.getUser();
  const departments = [];
  for (let index = 0; index < user.roles.length; index++) {
    const role = user.roles[index];
    if (role.RoleCode == roleCode) {
      departments.push(role.DepartmentId);
    }
  }
  return departments;
}

export function getChildDepartmentsOfRoleCode(departments, roleCode) {
  const rcDepartments = getRoleCodeDepartments(roleCode);
  const childDepartments = [];
  for (let index = 0; index < rcDepartments.length; index++) {
    const departmentId = rcDepartments[index];
    const descendants = getDescendants(departments, departmentId);
    for (const department of descendants) {
      childDepartments.push(department.Id);
    }
    childDepartments.push(departmentId);
  }

  return childDepartments;
}

export function userHasPermission(permission) {
  if (permission == "update" || permission == "delete" || permission == "viewModeratorPages") {
    const isAdmin = currentUserIsAdmin();
    if (isAdmin) {
      return true;
    } else {
      return !restrictedUsersRestrictions.includes(permission);
    }
  } else return true;
}
