import React, { useState } from "react";
import { useEffect } from "react";
import { AuditService } from "../services";
import { Stack, useTheme, useMediaQuery } from "@mui/material";
import AuditReport from "../components/Reports/AuditReport";
import { useParams } from "react-router-dom";

function ReportViewer() {
  const [auditInfo, setAuditInfo] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [findings, setFindings] = useState([]);
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const params = useParams();

  useEffect(() => {
    AuditService.getAuditById(params.id).then((response) => {
      setAuditInfo(response.Table[0]);
      setQuestions(response.Table1);
      setFindings(response.Table2);
    });

    return () => {};
  }, []);

  return (
    <>
      {auditInfo != null && (
        <AuditReport
          auditInfo={auditInfo}
          questions={questions}
          findings={findings}
        />
      )}
    </>
  );
}

export default ReportViewer;
