import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  approveQuestion,
  denyQuestion,
  fetchRestrictedQuestions,
  selectRestrictedQuestions,
} from "../../features/questions/questionsSlice";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { showConfirmDialog } from "../../features/ui/confirmDialogSlice";
import { Chip } from "@mui/material";
import { userHasPermission } from "../../utils/authFunctions";
import { useTranslation } from "react-i18next";

export default function RestrictedQuestions(props) {
  const updatePermission = userHasPermission("update")
  const data = props.data
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const columns = [
    { headerName: "Id", field: "Id", flex: 0.1 },
    { headerName: t('ui.question'), field: "question", flex: 1 },
    { headerName: t('ui.fiveSType'), field: "FiveSType", flex: 0.3 },
    { headerName: t('ui.department'), field: "Adi", flex: 0.3 },
    {
      headerName: t('ui.status'),
      renderCell: (params) => {
        const approved = params.row.ApproverId !== 0;
        return (
          <Chip
            label={t(approved ? "ui.approved" : "ui.waiting")}
            color={approved ? "success" : "warning"}
          />
        );
      },
      minWidth: 170,
    },
    {
      headerName: t('ui.actions'),
      field: "actions",
      type: "actions",
      getActions: (params) => {
        const approved = params.row.ApproverId !== 0;
        let actions = [
          <GridActionsCellItem
            color="error"
            icon={<CloseIcon />}
            disabled={!updatePermission}
            onClick={
              approved
                ? () => {
                    dispatch(denyQuestion(params.row.ActivityQuestionId)).then(
                      () => dispatch(fetchRestrictedQuestions()),
                    );
                  }
                : () =>
                    confirmDenyQuestion(
                      params.row.Id,
                      params.row.ActivityQuestionId,
                    )
            }
            label={t('buttons.reject')}
          />,
          <GridActionsCellItem
            color="success"
            disabled={approved || !updatePermission}
            icon={<DoneIcon />}
            onClick={() =>
              confirmApproveQuestion(
                params.row.Id,
                params.row.ActivityQuestionId,
              )
            }
            label={t('buttons.approve')}
          />,
        ];
        return actions;
      },
      sortable: false,
      hide: props.hideActions
    },
  ];

  async function confirmApproveQuestion(id, activityQuestionId) {
    const confirmed = await showConfirmDialog(
      dispatch,
      `${id} numaralı soruyu onaylamak istediğinize emin misiniz?`,
    );
    if (confirmed) {
      dispatch(approveQuestion(activityQuestionId)).then(() =>
        dispatch(fetchRestrictedQuestions()),
      );
    }
  }

  async function confirmDenyQuestion(id, activityQuestionId) {
    const confirmed = await showConfirmDialog(
      dispatch,
      `${id} numaralı soruyu reddetmek istediğinize emin misiniz?`,
    );
    if (confirmed) {
      dispatch(denyQuestion(activityQuestionId)).then(() =>
        dispatch(fetchRestrictedQuestions()),
      );
    }
  }

  useEffect(() => {
    dispatch(fetchRestrictedQuestions());
  }, []);

  return data.length ? (
    <DataGrid
      autoHeight
      rows={data}
      columns={columns}
      getRowId={(row) => row.Id}
      hideFooter={true}
    />
  ) : (
    <h4>Gösterilecek soru yok.</h4>
  );
}
