import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  auditDetail: false,
  currentAudit: null,
  insertFinding: false,
  filterFindings: false,
  transferFinding: false,
  transferAuditorOrResponsibleUser: false,
  completeFinding: false,
  findingDetail: false,
  createFieldTour: false,
  createWorkshop: false,
  createMeeting: false,
  currentFinding: null,
  questionId: 0,
  detail: null,
  createDeviationRequest: false
};

const dialogSlice = createSlice({
  name: "dialog",
  initialState,
  reducers: {
    toggleInsertFinding: (state, action) => {
      state.questionId = action.payload;
      state.insertFinding = !state.insertFinding;
    },
    toggleFilterFindings: (state, action) => {
      state.filterFindings = !state.filterFindings;
    },

    toggleTransferFinding: (state, action) => {
      state.transferFinding = !state.transferFinding;
      if (action.payload) {
        state.currentFinding = action.payload;
      }
    },

    toggleCompleteFinding: (state, action) => {
      state.completeFinding = !state.completeFinding;
      if (action.payload) {
        state.currentFinding = action.payload;
      }
    },
    toggleAuditDetail: (state, action) => {
      state.auditDetail = !state.auditDetail;
      state.currentAudit = action.payload;
    },

    toggleCreateDeviationRequest: (state, action) => {
      state.createDeviationRequest = !state.createDeviationRequest ;
      state.detail = action.payload;
    },

    toggleFindingDetail: (state, action) => {
      state.findingDetail = !state.findingDetail;
      state.currentFinding = action.payload;
    },
    toggleCreateFieldTour: (state, action) => {
      state.createFieldTour = !state.createFieldTour;
    },

    toggleTransferAuditorOrResponsibleUser: (state, action) => {
      state.transferAuditorOrResponsibleUser =
        !state.transferAuditorOrResponsibleUser;
    },

    toggleCreateWorkshop: (state, action) => {
      state.createWorkshop = !state.createWorkshop;
    },

    toggleCreateMeeting: (state, action) => {
      state.createMeeting = !state.createMeeting;
    },

    updateCurrentFinding: (state, action) => {
      state.currentFinding = action.payload;
      state.findingDetail = Boolean(action.payload && state.findingDetail);
    },

    updateCurrentAudit: (state, action) => {
      state.currentAudit = action.payload;
      state.auditDetail = Boolean(action.payload && state.auditDetail);
    },
  },
});

export const dialogActions = dialogSlice.actions;
export const selectDialog = (state) => state.ui.dialog;
export default dialogSlice.reducer;
