import axios from "axios";

class CommonService {
  constructor(url, name) {
    this.url = url;
    this.name = name;
  }

  async getAll() {
    return axios.post(this.url, {
      dataobject: {
        Action: "S",
      },
    });
  }

  async get(id) {
    return axios.post(this.url, {
      dataobject: {
        Action: "S",
        Id: id,
      },
    });
  }

  async insert(data) {
    return axios.post(this.url, {
      dataobject: {
        Action: "I",
        ...data,
      },
    });
  }

  async update(data) {
    return axios.post(this.url, {
      dataobject: {
        Action: "U",
        ...data,
      },
    });
  }

  async delete_(id) {
    return axios.post(this.url, {
      dataobject: {
        Action: "D",
        Id: id,
      },
    });
  }
}

export default CommonService;
