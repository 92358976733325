import { MAIN_URL } from "./ServiceConstants";
import axios from "axios";

const url = MAIN_URL + "/Quality/SetUsers";
const periodicScoresUrl = MAIN_URL + "/Quality/GetUserAuditScore";
const auditScoresUrl = MAIN_URL + "/Quality/GetAuditorAuditScore";
const performanceUrl = MAIN_URL + "/Quality/GetPerformance"

async function getAllUsers() {
  return axios.post(url, {
    dataobject: {
      Action: "S",
    },
  });
}

async function getUser(userId) {
  return axios.post(url, {
    dataobject: {
      Action: "S",
      Id: userId,
    },
  });
}

async function insertUser({ loginName, longName, email, password }) {
  return axios.post(url, {
    dataobject: {
      Action: "I",
      Id: 0,
      LoginName: loginName,
      LongName: longName,
      email: email,
      Pass: password,
    },
  });
}

async function updateUser({ userId, loginName, longName, email, imageId, password }) {
  return axios.post(url, {
    dataobject: {
      Action: "U",
      Id: userId,
      LoginName: loginName,
      LongName: longName,
      email: email,
      Photo: imageId,
      Pass: password,
    },
  });
}

async function setRole({ userId, roleId, departmentId }) {
  return axios.post(MAIN_URL + "/Quality/SetRoleUsers", {
    dataobject: {
      Action: "I",
      RoleUserId: userId,
      RoleId: roleId,
      DepartmentId: departmentId,
    },
  });
}

async function deleteRole(roleId) {
  return axios.post(MAIN_URL + "/Quality/SetRoleUsers", {
    dataobject: {
      Action: "D",
      Id: roleId,
    },
  });
}

async function deleteUser(id) {
  return axios.post(url, {
    dataobject: {
      Action: "D",
      Id: id,
    },
  });
}

async function getSubDepUsers(departmentId) {
  return axios.post(MAIN_URL + "/Quality/GetSubDepUsers", {
    dataobject: {
      Id: departmentId,
    },
  });
}

async function getPeriodicScoresForUsers(year) {
  let dataObject = {};
  if (year !== undefined)  {
    dataObject = {
      Year: year,
    };
  }
  return axios.post(periodicScoresUrl, {
    dataobject: dataObject,
  });
}

async function getAuditScoresForAuditors(year) {
  return axios.post(auditScoresUrl, {
    dataobject: {
      Year: year,
    },
  });
}

async function getUserPerformance(year, user) {
  const dataObject = {
    Year: year,
    Action: user

  };
  return axios.post(performanceUrl, {
    dataobject: dataObject
  });
}

const UsersService = {
  getAllUsers,
  getUser,
  insertUser,
  updateUser,
  deleteUser,
  setRole,
  deleteRole,
  getSubDepUsers,
  getPeriodicScoresForUsers,
  getAuditScoresForAuditors,
  getUserPerformance
};

export default UsersService;
