import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Stack } from "@mui/material";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { useDispatch, useSelector } from "react-redux";
import { addRole } from "../../features/users/usersSlice";
import { selectDepartments } from "../../features/departments/departmentsSlice";
import { selectRoles } from "../../features/roles/rolesSlice";

export default function InsertRoleDialog(props) {
  const [formValues, setFormValues] = useState({departmentId: '', roleId: ''});
  const departments = useSelector(selectDepartments);
  const roles = useSelector(selectRoles);
  const dispatch = useDispatch();

  function hideDialog() {
    console.log("refreshing data");
    props.onClose();
    setFormValues({});
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(addRole({ ...formValues, userId: props.data.id }));
    hideDialog();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  return (
    <Dialog open={props.show} fullWidth>
      <DialogTitle>Add role</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Stack gap={2}>
            <FormControl fullWidth>
              <InputLabel>Department</InputLabel>
              <Select
                size="small"
                value={formValues.departmentId}
                label="Department"
                name="departmentId"
                onChange={handleInputChange}
              >
                {departments.map((department) => {
                  return (
                    <MenuItem key={department.Id} value={department.Id}>
                      {department.Adi}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel>Role</InputLabel>
              <Select
                size="small"
                value={formValues.roleId}
                label="Role"
                name="roleId"
                onChange={handleInputChange}
              >
                {roles.map((role) => {
                  return (
                    <MenuItem key={role.Id} value={role.Id}>
                      {role.Adi}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={hideDialog}>
            Cancel
          </Button>
          <Button variant="outlined" type="submit">
            Ok
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
