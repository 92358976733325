import AuditService from "../../services/AuditService";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const fetchCurrentAudit = createAsyncThunk(
  "currentAudit/fetchCurrentAudit",
  async (id) => {
    console.log("fetch audit by id " + id);
    const response = await AuditService.getAuditById(id);
    return response;
  },
);

export const generateAuditQuestions = createAsyncThunk(
  "currentAudit/generateQuestions",
  async (id) => {
    console.log("generate questions by id " + id);
    const response = await AuditService.createAuditQuestions(id);
    return response;
  },
);

export const setAuditQuestionNA = createAsyncThunk(
  "currentAudit/setQuestionNA",
  async ({ id, value }) => {
    console.log("set question na " + id + " " + value);
    let v = -1;

    if (value == false) v = 0;

    const response = await AuditService.setAuditResponseValue(id, v);
    console.log(response);
    return response;
  },
);

export const updateParticipants = createAsyncThunk(
  "currentAudit/updateParticipants",
  async ({ activityId, participants }) => {
    console.log("update participants " + activityId + " " + participants);
    const response = await AuditService.updateParticipants(
      activityId,
      participants,
    );
    console.log(response);
    return response;
  },
);

const initialState = {
  currentQuestionIndex: 0,
  auditInfo: {},
  data: [],
  subQuestions: [],
  auditFindings: [],
  participants: [],
  previousMeetingFindings: [],
  previousAuditFindingsToBeApproved: [],
  loading: false,
  error: "",
};

const currentAuditSlice = createSlice({
  name: "currentAudit",
  initialState,
  reducers: {
    setCurrentQuestionId: (state, action) => {
      state.currentQuestionIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCurrentAudit.fulfilled, (state, action) => {
      state.auditInfo = action.payload.Table[0];
      state.data = action.payload.Table1;
      state.auditFindings = action.payload.Table2;
      state.participants = action.payload.Table3;
      state.previousMeetingFindings = action.payload.Table4;
      state.previousAuditFindingsToBeApproved = action.payload.Table5;
    });
    builder.addCase(generateAuditQuestions.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(updateParticipants.fulfilled, (state, action) => {
      state.participants = action.payload.Table;
    });
    builder.addCase(setAuditQuestionNA.fulfilled, (state, action) => {
      console.log(action);
      var foundIndex = state.data.findIndex(
        (x) => x.Id == action.payload[0].Id,
      );

      console.log(`current index ${foundIndex}`);

      if (foundIndex != -1)
        state.data[foundIndex] = {
          ...state.data[foundIndex],
          ...action.payload[0],
        };
    });
  },
});

const { setCurrentQuestionId } = currentAuditSlice.actions;
export const currentAuditActions = {
  setCurrentQuestionId: setCurrentQuestionId,
};
export const selectCurrentAudit = (state) => state.currentAudit;
export default currentAuditSlice.reducer;
