import RoleUserService from "../../services/RoleUserService";

const initialState = [];

export default function roleUsersReducer(state = initialState, action) {
  switch (action.type) {
    case "roleUsers/loaded": {
      return action.payload;
    }
    default:
      return state;
  }
}

export async function fetchRoleUsers(dispatch, getState) {
  const response = await RoleUserService.getAll();
  dispatch({ type: "roleUsers/loaded", payload: response });
}

export const selectRoleUsers = (state) => state.roleUsers;
