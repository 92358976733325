import { useSelector } from "react-redux";
import { selectDepartmentsWithPeriodicScoresYear } from "../../features/departments/departmentsSlice";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  BarElement
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { FormControlLabel, Paper, Switch } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import MyTitle from "../common/Title"

ChartJS.register(
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  BarElement,
);

const options = (/* title,  */stacked) => {
  return {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      // title: {
      //   display: false,
      //   text: title,
      // },
    },

    scales: {
        x: {
          stacked: stacked,
        },
        y: {
          stacked: stacked,
        },
      },
    }
};

const YearlyAuditGraph = ({ department, currentYear }) => {
  const departmentsWithPeriodicScores = useSelector(
    selectDepartmentsWithPeriodicScoresYear,
  );
  const {t} = useTranslation()
  const data = departmentsWithPeriodicScores.map((year) =>
    year.find((d) => d.Id == department.Id),
  );
  const [stacked, setStacked] = useState(true)

  function prepareData() {
    const months = [
      t('months.0'),
      t('months.1'),
      t('months.2'),
      t('months.3'),
      t('months.4'),
      t('months.5'),
      t('months.6'),
      t('months.7'),
      t('months.8'),
      t('months.9'),
      t('months.10'),
      t('months.11'),
    ];
    return data.map((d, i) => {
      return { label: months[i], notCompleted: d.AuditCnt - d.CompletedAuditCnt, completed: d.CompletedAuditCnt };
    });
  }

  function getChartData() {
    const chartData = prepareData();
    return {
      labels: chartData.map((d) => d.label),
      datasets: [
        {
          label: t('ui.shortHeaders.wasCompleted'),
          data: chartData.map((d) => d.completed),
          borderColor: "rgb(185, 250, 70)",
          backgroundColor: "rgba(185,235,73, 0.5)",
        },
        {
          label: t('ui.shortHeaders.wasNotCompleted'),
          data: chartData.map((d) => d.notCompleted),
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(241,67,63, 0.5)",
        },
      ],
    };
  }
  const title = t('titles.yearlyAuditGraph', {currentDepartment: department.Adi})

  return data.length ? (
    <Paper elevation={12}>
      <MyTitle title={title} year={currentYear} yearly={true} />
      <FormControlLabel sx={{float: "right"}} control={<Switch defaultChecked value={stacked} onChange={(e)=>setStacked(e.target.checked)}/>} label={t('ui.stackedGraph')} />
      <Bar options={options(stacked)} data={getChartData()} />
    </Paper>
  ) : (
    <div> </div>
  );
};

export default YearlyAuditGraph;
