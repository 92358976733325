import Button from "@mui/material/Button";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Stack, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch, useSelector } from "react-redux";
import DeleditableTextView from "../../common/DeleditableTextView";
import EditableSelect from "../../common/EditableSelect";
import { selectUsers } from "../../../features/users/usersSlice";
import { dialogActions, selectDialog } from "../../../features/ui/dialogsSlice";
import { showConfirmDialog } from "../../../features/ui/confirmDialogSlice";
import { updateAudit } from "../../../features/audits/auditsSlice";
import { useTranslation } from "react-i18next";

export function AuditDetails(props) {
  const userList = useSelector(selectUsers).users;
  const dispatch = useDispatch();
  const {t} = useTranslation()

  async function handleUpdateDueDate(StartDate) {
    const confirmed = await showConfirmDialog(
      dispatch,
      t('confirm.changeAuditDate'),
    );
    if (confirmed) {
      console.log(props);
      dispatch(updateAudit({ ...props, StartDate: StartDate }));
    }
  }

  function renderDetails(data) {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack gap={2} mt={2}>
          <EditableSelect
            value={data.userId}
            label={t('ui.responsibleUser')}
            list={userList}
            listKey="Id"
            listValue="LongName"
            onUpdate={(newValue) =>
              dispatch(
                updateAudit({
                  ...props,
                  InchargeUserId: newValue,
                }),
              )
            }
          ></EditableSelect>

          <EditableSelect
            value={data.auditorId}
            label={t('ui.auditor')}
            list={userList.filter((user) => user.UserRolesCode.includes("DE"))}
            listKey="Id"
            listValue="LongName"
            onUpdate={(newValue) =>
              dispatch(
                updateAudit({
                  ...props,
                  AuditorId: newValue,
                }),
              )
            }
          ></EditableSelect>

          <DesktopDatePicker
            inputFormat="DD/MM/YYYY"
            label={t('ui.auditDate')}
            onChange={(newDate) => handleUpdateDueDate(newDate)}
            value={new Date(data.date)}
            renderInput={(params) => <TextField {...params} />}
          />
        </Stack>
      </LocalizationProvider>
    );
  }

  return renderDetails({
    user: props.InchargeUser,
    userId: props.InchargeUserId,
    auditor: props.Auditor,
    auditorId: props.AuditorId,
    date: props.StartDate,
  });
}

export default function UpdateAuditDialog(props) {
  const currentAudit = useSelector(selectDialog).currentAudit;
  const open = useSelector(selectDialog).auditDetail;
  const dispatch = useDispatch();
  const {t} = useTranslation()

  function hideDialog() {
    dispatch(dialogActions.toggleAuditDetail());
  }

  return (
    <Dialog open={open} maxWidth="lg" fullWidth>
      <DialogTitle>{t('audits.editAudit')}</DialogTitle>
      <DialogContent>
        <AuditDetails {...currentAudit}></AuditDetails>
      </DialogContent>
      <DialogActions data-html2canvas-ignore="true">
        <Button variant="outlined" color="secondary" onClick={hideDialog}>
          {t('buttons.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
