import "./App.css";
import "react-datepicker/dist/react-datepicker.css";
import { Route, Routes } from "react-router-dom";
import * as Pages from "./pages/";
import { LanguageProvider } from "./utils/Language";
import Users from "./components/Users/Users";
import Tree from "./components/Tree/Tree";
import Departments from "./components/Tree/Departments";
import RoleService from "./services/RolesServices";
import UserDetail from "./components/Users/UserDetail";
import { Container } from "@mui/material";
import Settings from "./components/Settings/Settings";
import DepartmentDetail from "./components/Tree/DepartmentDetail";
import FindingsComponent from "./components/Findings/FindingsComponent";
import FindingReport from "./components/Reports/FindingReport";
import Reports from "./components/Reports/Reports";
import { useSelector } from "react-redux";
import {
  selectApprovedFindings,
  selectUnapprovedFindings,
} from "./features/findings/findingsSlice";
import AuditCalendarComponent from "./components/MainActivity/Audit/AuditCalendarComponent";
import UnapprovedJobs from "./pages/UnapprovedJobs";
import DepartmentProfile from "./components/Tree/DepartmentProfile";

function App() {
  const approvedFindings = useSelector(selectApprovedFindings);
  return (
    <LanguageProvider>
      <div className="container-fluid App">
        <Routes>
          <Route
            path="/"
            element={
              <Pages.ProtectedPage showNavbar={true}>
                <Container maxWidth="md">
                  <Pages.Home />
                </Container>
              </Pages.ProtectedPage>
            }
          />
          <Route path="/login" element={<Pages.LoginPage />} />
          <Route
            path="/forgotpassword"
            element={<Pages.ForgotPasswordPage />}
          />
          <Route path="/logout" element={<Pages.LogoutPage />} />
          <Route
            path="/calendar"
            element={
              <Pages.ProtectedPage showNavbar={true}>
                <AuditCalendarComponent />
              </Pages.ProtectedPage>
            }
          />
          <Route
            path="/users"
            element={
              <Pages.ProtectedPage showNavbar={true}>
                <Users />
              </Pages.ProtectedPage>
            }
          />
          <Route
            path="/users/:id"
            element={
              <Pages.ProtectedPage showNavbar={true}>
                <Container maxWidth="md">
                  <UserDetail />
                </Container>
              </Pages.ProtectedPage>
            }
          />
          <Route
            path="/roles"
            element={
              <Pages.ProtectedPage showNavbar={true}>
                <Tree key="role" service={RoleService} />
              </Pages.ProtectedPage>
            }
          />
          <Route
            path="/reports"
            element={
              <Pages.ProtectedPage showNavbar={true}>
                <Container maxWidth="lg">
                  <Reports />
                </Container>
              </Pages.ProtectedPage>
            }
          />
          <Route
            path="/departments"
            element={
              <Pages.ProtectedPage showNavbar={true}>
                <Container maxWidth="md">
                  <Departments />
                </Container>
              </Pages.ProtectedPage>
            }
          />
          <Route
            path="/departments/:id"
            element={
              <Pages.ProtectedPage showNavbar={true}>
                <Container maxWidth="lg">
                  <DepartmentProfile />
                </Container>
              </Pages.ProtectedPage>
            }
          />
          <Route
            path="/profile"
            element={
              <Pages.ProtectedPage showNavbar={true}>
                <Pages.ProfilePage />
              </Pages.ProtectedPage>
            }
          />
          <Route
            path="/settings"
            element={
              <Pages.ProtectedPage showNavbar={true}>
                <Settings />
              </Pages.ProtectedPage>
            }
          />
          <Route
            path="/questionpool"
            element={
              <Pages.ProtectedPage showNavbar={true}>
                <Pages.FiveSQuestionPoolPage />
              </Pages.ProtectedPage>
            }
          />
          <Route
            path="/moderatoractions"
            element={
              <Pages.ProtectedPage showNavbar={true}>
                <Pages.ModeratorActions />
              </Pages.ProtectedPage>
            }
          />

          <Route
            path="/5SActivities"
            element={
              <Pages.ProtectedPage showNavbar={true}>
                <Pages.FiveSActivitiesPage />
              </Pages.ProtectedPage>
            }
          />

          <Route
            path="/findings"
            element={
              <Pages.ProtectedPage showNavbar={true}>
                <Pages.FindingsPage />
              </Pages.ProtectedPage>
            }
          />
          <Route
            path="/unapprovedjobs"
            element={
              <Pages.ProtectedPage showNavbar={true}>
                <UnapprovedJobs />
              </Pages.ProtectedPage>
            }
          />

          <Route
            path="/approvedfindings"
            element={
              <Pages.ProtectedPage showNavbar={true}>
                <FindingsComponent data={approvedFindings} approval={true} />
              </Pages.ProtectedPage>
            }
          />

          <Route
            path="/periodicaudits"
            element={
              <Pages.ProtectedPage showNavbar={true}>
                <Pages.PeriodicAuditsPage />
              </Pages.ProtectedPage>
            }
          />

          <Route
            path="/audits"
            element={
              <Pages.ProtectedPage showNavbar={true}>
                <Pages.AuditsPage />
              </Pages.ProtectedPage>
            }
          />

          <Route
            path="/audits/:id"
            element={
              <Pages.ProtectedPage showNavbar={true}>
                <Pages.AuditsDetailPage />
              </Pages.ProtectedPage>
            }
          />

          <Route
            path="/auditreport/:id"
            element={
              <Pages.ProtectedPage showNavbar={false}>
                <Pages.ReportViewerPage />
              </Pages.ProtectedPage>
            }
          />

          <Route
            path="/findingreport"
            element={
              <Pages.ProtectedPage showNavbar={false}>
                <FindingReport />
              </Pages.ProtectedPage>
            }
          />

          <Route path="*" element={<Pages.NotFound />} />
        </Routes>
      </div>
    </LanguageProvider>
  );
}

export default App;
