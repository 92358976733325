import { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { selectDialog, dialogActions } from "../../features/ui/dialogsSlice";
import { useDispatch, useSelector } from "react-redux";
import MultiUserSelect from "../common/MultiUserSelect";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { AuditService } from "../../services";
import { selectDepartments } from "../../features/departments/departmentsSlice";
import { fetchAudits } from "../../features/audits/auditsSlice";
import { LoginService } from "../../services";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import DeviationService from "../../services/DeviationService";
import DeviationTypes from "./DeviationTypes"
import { createDeviation } from "../../features/deviations/deviationsSlice";
import { useTranslation } from "react-i18next";


const five_s_types = ["DUZEN", "TERTIP", "TEMIZLIK"];

export default function CreateDeviationRequestDialog() {
  const { t } = useTranslation()
  const currentUserID = LoginService.getUser()?.UserId;
  const dialogVisibility = useSelector(selectDialog);
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date(Date.now() + 6.048e8));
  const departments = useSelector(selectDepartments);
  const { detail } = useSelector(selectDialog);
  const [fiveSType, set5SType] = useState([]);
  const [deviationType, setDeviationType] = useState([]);
  const [desc, setDesc] = useState("");
  const [checked, setChecked] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0])
  const error = checked.filter(i=>i).length == 0

  function hideDialog() {
    dispatch(dialogActions.toggleCreateDeviationRequest());
  }

  useEffect(() => {
    setStartDate(new Date());
    setEndDate(new Date(Date.now() + 6.048e8));
    set5SType([]);
    setChecked([0, 0, 0, 0, 0, 0, 0, 0, 0]);
    setDesc('')
  }, [dialogVisibility.createDeviationRequest]);

  function handleSubmit(e) {
    e.preventDefault();
    const checkedTypes = checked.reduce(
      (out, bool, index) => bool ? out.concat(index) : out,
      []
    )
    dispatch(createDeviation(
      [
      startDate,
      endDate,
      detail,
      fiveSType.join(","),
      checkedTypes.join(","),
      desc
      ]
    )).then(hideDialog); /* .then(dispatch(fetchAudits)); */
  }

  const handle5STypeChange = (event) => {
    const {
      target: { value },
    } = event;
    set5SType(typeof value === "string" ? value.split(",") : value);
  };

  const handleCheckboxChange = (event, index) => {
      setChecked((prevDeviationTypes) => {
        const updatedDeviationTypes = [...prevDeviationTypes];
        updatedDeviationTypes[index] = event.target.checked
        return updatedDeviationTypes;
      });
    };

  return (
    <div>
      <Dialog open={dialogVisibility.createDeviationRequest} fullWidth>
        <DialogTitle>Sapma Talep Et</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Stack gap={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Başlama Zamanı"
                  required
                  inputFormat="L"
                  value={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                  renderInput={(params) => <TextField required {...params} />}
                />
                <DatePicker
                  label="Bitiş Zamanı"
                  required
                  inputFormat="L"
                  value={endDate}
                  onChange={(date) => {
                    setEndDate(date);
                  }}
                  renderInput={(params) => <TextField required {...params} />}
                />
              </LocalizationProvider>
              <FormControl required>
                <InputLabel>5S Tipi</InputLabel>
                <Select
                  multiple
                  value={fiveSType}
                  onChange={handle5STypeChange}
                  input={<OutlinedInput label="5S Tipi" />}
                >
                  {five_s_types.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl required error={error}>
                <FormLabel component="legend">Sapma tipi (En az birini seçiniz):</FormLabel>
                {DeviationTypes.map((dt, i) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Boolean(checked[i])}
                        onChange={(e) => {handleCheckboxChange(e, i)}}
                      />
                    }
                    label={
                      <Stack gap={2} direction="row">
                        <img height={25} src={dt.image} />
                        <Typography>{dt.name}</Typography>
                      </Stack>
                    }
                  />
                ))}
              </FormControl>
              <TextField
                label="Açıklama"
                name="description"
                onChange={(e) => setDesc(e.target.value)}
                required
                // error={error && !formValues.description}
                multiline
                rows={4}
                value={desc}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="secondary" onClick={hideDialog}>
              {t('buttons.cancel')}
            </Button>
            <Button variant="outlined" type="submit" disabled={error}>
              {t('buttons.createDeviationRequest')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
