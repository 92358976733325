import { useEffect } from "react";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  Title,
} from "chart.js";

import { Radar } from "react-chartjs-2";
import { getRadarChartData } from "../../utils/commonFunctions";

const options = {
  scale: {
    ticks: {
      beginAtZero: true,
      stepSize: 1,
    },
    r: {
      angleLines: {
        display: false,
      },
      suggestedMin: 0,
      suggestedMax: 5,
    },
  },
  plugins: {
    legend: {
      display: true,
    },
  },
};

ChartJS.register(
  Title,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
);

const DepartmentRadarChart = ({ department }) => {
  return <Radar data={getRadarChartData(department)} options={options} />;
};

export default DepartmentRadarChart;
