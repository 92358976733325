import {
  Autocomplete,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { selectUsers } from "../../features/users/usersSlice";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const MultiUserSelect = (props) => {
  const { users } = useSelector(selectUsers);
  return (
    <FormControl fullWidth error={props.error && !props.value}>
      <Autocomplete
        multiple
        disableCloseOnSelect
        options={[...users].sort((a, b) => {
          if (a.LongName > b.LongName) return 1;
          else if (a.LongName < b.LongName) return -1;
          else return 0;
        })}
        size="small"
        getOptionLabel={(d) => d.LongName}
        value={props.value}
        // onChange={(e) => props.onChangeUser(e.target.value)}
        onChange={(e, val) => props.onChangeUserList(val)}
        renderOption={(props, option, { selected }) => (
          <li value={option.Id} {...props}>
            <Checkbox
              icon={icon}
              value={option.Id}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.LongName}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            required={props.required ? true : false}
            error={props.error && !props.valueDepartment}
            label="Katılımcılar"
            placeholder="Katılımcı seçin"
            variant="standard"
          />
        )}
      >
        <MenuItem key={null} value="">
          None
        </MenuItem>
        {users.map((user) => {
          return (
            <MenuItem key={user.Id} value={user.Id}>
              {user.LongName}
            </MenuItem>
          );
        })}
      </Autocomplete>
    </FormControl>
  );
};

export default MultiUserSelect;
