import { useState } from "react";
import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import {
  Stack,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DetailsDialog from "../Findings/DetailsDialog";
import FindingsService from "../../services/FindingsService";
import { dialogActions } from "../../features/ui/dialogsSlice";
import Chip from "@mui/material/Chip";
import { TabPanel } from "../../utils/commonFunctions";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

function TaskList(props) {
  const { Tasks, onCallback, ...other } = props;

  return (
    <List
      sx={{
        width: "100%",
        bgcolor: "background.paper",
        overflow: "auto",
        maxHeight: 500,
      }}
      aria-label="contacts"
    >
      {Tasks.map((task) => {
        return (
          <ListItem index={task.Id} disablePadding>
            <ListItemButton>
              <ListItemText
                secondary={
                  <Stack>
                    <Typography>{"Açıklama: " + task.Dsc}</Typography>
                    <Link href={"/audits/" + task.MainActivityId}>
                      {"Kaynak: " + task.ActivityType}
                    </Link>
                    {task.question && (
                      <Typography variant="paragraph">
                        {"Soru: " + task.question}
                      </Typography>
                    )}
                  </Stack>
                }
                primary={"Termin:" + new Date(task.DueDate).toLocaleString()}
              />
              <ListItemIcon>
                <VisibilityIcon onClick={() => onCallback(task.Id)} />
              </ListItemIcon>
              <Chip label={task.FiveSType} color="primary" />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
}

export default function UserTasksList(props) {
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const { t } = useTranslation()

  const openDetails = (id) => {
    FindingsService.getFinding(id).then((response) => {
      dispatch(dialogActions.toggleFindingDetail(response[0]));
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ border: 1, borderColor: "divider", width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label={t('tasks.upcoming')} value={0} />
            <Tab label={t('tasks.expired')} value={1} />
          </Tabs>
        </Box>
        <TabPanel currentTab={value} value={0}>
          <TaskList
            Tasks={props.incoming.filter(
              (item) => new Date(item.DueDate) >= Date.now(),
            )}
            onCallback={openDetails}
          />
        </TabPanel>
        <TabPanel currentTab={value} value={1}>
          <TaskList
            Tasks={props.incoming.filter(
              (item) => new Date(item.DueDate) < Date.now(),
            )}
            onCallback={openDetails}
          />
        </TabPanel>

        <Link p={3} href="/findings">
          {t('tasks.more')}
        </Link>
      </Box>

      <DetailsDialog />
    </>
  );
}
