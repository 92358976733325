import { Avatar, IconButton } from "@mui/material";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { getImageUrl, uploadImage } from "../../utils/commonFunctions";

const EditableAvatar = forwardRef((props, ref) => {
  const inputFileRef = useRef(null);
  const [imageURL, _setImage] = useState(getImageUrl(props.imageId));

  useEffect(() => {
    setImage(getImageUrl(props.imageId));
  }, [props.editable, props.imageId]);

  useImperativeHandle(ref, () => ({
    upload: async () => {
      let imageId = props.imageId;
      if (imageURL !== getImageUrl(props.imageId)) {
        imageId = await uploadImage([imageURL], props.setProgress);
      }
      return imageId;
    },
  }));

  const setImage = (newImage) => {
    if (imageURL) {
      URL.revokeObjectURL(imageURL);
    }
    _setImage(newImage);
  };

  const handleOnChange = (event) => {
    const newImage = event.target?.files?.[0];

    if (newImage) {
      setImage(URL.createObjectURL(newImage));
    }
  };

  function renderAvatar() {
    return (
      <Avatar src={imageURL} alt={props.alt} sx={{ height: 150, width: 150 }} />
    );
  }
  return props.editable ? (
    <>
      <input
        ref={inputFileRef}
        accept="image/*"
        hidden
        id="avatar-image-upload"
        type="file"
        onChange={handleOnChange}
      />
      <label htmlFor="avatar-image-upload">
        <IconButton variant="contained" color="primary" component="span" mb={2}>
          {renderAvatar()}
        </IconButton>
      </label>
    </>
  ) : (
    renderAvatar()
  );
});

export default EditableAvatar;
