import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  initialized: false,
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    initialize: (state) => {
      state.initialized = true;
    },
    deinitialize: (state) => {
      state.initialized = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { initialize, deinitialize } = commonSlice.actions;

export default commonSlice.reducer;
