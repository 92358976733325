import React, { useState } from "react";
import Calendar from "@toast-ui/react-calendar";
import "@toast-ui/calendar/dist/toastui-calendar.min.css";
import { useEffect } from "react";
import UserTasksList from "../components/Dashboard/UserTasksList";
import { Container } from "@mui/material";
import { DashboardService } from "../services";
import UserDetail from "../components/Users/UserDetail";
import { LoginService } from "../services";
import { Stack, useTheme, useMediaQuery } from "@mui/material";

function Home() {
  const [userTasks, setUserTasks] = useState([]);
  const [userAudits, setUserAudits] = useState([]);
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const calendars = [{ id: "cal1", name: "Personal" }];

  useEffect(() => {
    DashboardService.getDashboard().then((response) => {
      setUserTasks(response.Table1);
      setUserAudits(
        response.Table.map((audit) => {
          return {
            id: audit.Id,
            calendarId: "cal1",
            title: audit.Department,
            category: "time",
            start: audit.StartDate,
            end: new Date(new Date(audit.StartDate) + 3600 * 1000),
          };
        }),
      );
    });
  }, []);

  return (
    <Container>
      <Stack direction="column" gap={2}>
        <UserDetail id={LoginService.getUser().UserId} />

        <Stack
          gap={2}
          sx={{ mx: { sm: 0, md: 0 } }}
          direction={smallScreen ? "column" : "row"}
        >
          <UserTasksList incoming={userTasks}></UserTasksList>

          <Container sx={{ border: 1, borderColor: "divider" }}>
            <Calendar
              usageStatistics={false}
              height="600px"
              view="day"
              week={{
                taskView: "time",
                eventView: true,
              }}
              allDaySlot="false"
              isReadOnly="true"
              calendars={calendars}
              events={userAudits}
            />
          </Container>
        </Stack>
      </Stack>
    </Container>
  );
}

export default Home;
