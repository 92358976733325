import Login from "../components/Login/Login";
import background from "../resources/Login/background.jpg";

export default function LoginPage(props) {
  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "streched",
      }}
    >
      <Login {...props} />;
    </div>
  );
}
