import { Tab, Tabs } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import FindingsComponent from "../components/Findings/FindingsComponent";
import { useDispatch, useSelector } from "react-redux";
import { selectUnapprovedFindings } from "../features/findings/findingsSlice";
import { TabPanel } from "../utils/commonFunctions";
import {
  fetchRestrictedQuestions,
  selectRestrictedQuestions,
} from "../features/questions/questionsSlice";
import RestrictedQuestions from "../components/QuestionPool/RestrictedQuestions";
import DeviationRequests from "../components/DeviationRequests/DeviationRequests";
import { selectDeviations } from "../features/deviations/deviationsSlice";
import { useTranslation } from "react-i18next";

export default function UnapprovedJobs() {
  const [value, setValue] = useState("findings");
  const { t } = useTranslation()
  const unapprovedFindings = useSelector(selectUnapprovedFindings);
  const deviationRequests = useSelector(selectDeviations)
  const restrictedQuestions = useSelector(selectRestrictedQuestions);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <Tabs value={value} onChange={handleChange}>
        <Tab label={t('tasks.findings')} value="findings" />
        <Tab
          label={t('tasks.deviationRequests')}
          value="deviationRequests"
        />
        <Tab
          label={t('tasks.unadaptableQuestions')}
          value="unadaptableQuestions"
        />
      </Tabs>
      <TabPanel currentTab={value} value="findings">
        <FindingsComponent data={unapprovedFindings} approval={true} />
      </TabPanel>
      <TabPanel currentTab={value} value="deviationRequests">
        <h3>{t('tasks.deviationRequests')}</h3>
        <DeviationRequests data={deviationRequests}/>
      </TabPanel>
      <TabPanel currentTab={value} value="unadaptableQuestions">
        <RestrictedQuestions data={restrictedQuestions}/>
      </TabPanel>
    </div>
  );
}
