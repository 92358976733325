import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filterText: "",
  filterUser: null,
};

const listFiltersSlice = createSlice({
  name: "listFilters",
  initialState,
  reducers: {
    setFilterText: (state, action) => {
      state.filterText = action.payload;
    },
    setFilterUser: (state, action) => {
      state.filterUser = action.payload;
    },
  },
});

export const { setFilterText, setFilterUser } = listFiltersSlice.actions;

export const selectFilterText = (state) => state.ui.listFilters.filterText;
export const selectFilterUser = (state) => state.ui.listFilters.filterUser;
export default listFiltersSlice.reducer;
