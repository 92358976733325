import React, { forwardRef } from "react";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useState } from "react";
import Department from "./Department";
import Role from "./Role";

const Transition = forwardRef(function Transition(
  props,
  ref,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const InsertModal = (props) => {
  const [newNodeData, setNewNodeData] = useState({});

  function cancel() {
    props.onClose();
  }

  function proceed() {
    props.service
      .insert({ ...newNodeData, master_id: props.node.Id })
      .then(() => props.onClose("", true));
  }

  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {props.isRole ? "Add new role" : "Add new department"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.isRole ? (
            <Role
              update={false}
              editing={true}
              node={{ title: "", DepartmentId: "" }}
              onSetData={setNewNodeData}
            />
          ) : (
            <Department
              update={false}
              editing={true}
              node={{ title: "", DepartmentId: "" }}
              onSetData={setNewNodeData}
            />
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus variant="outlined" color="secondary" onClick={cancel}>
          Cancel
        </Button>
        <Button className="ml-4" variant="outlined" onClick={proceed}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InsertModal;
