import { CalendarPicker } from "@mui/lab";
import dayjs from "dayjs";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { IconButton, Button, Stack, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function YearMonthSelector(props) {
  const [value, setValue] = useState(dayjs().endOf("month"));
  const minDate = props.minDate || dayjs().subtract(10, "years").endOf("month");
  const maxDate = props.maxDate || dayjs().add(5, "years").endOf("month");
  const { t } = useTranslation()

  function setDate(newDate) {
    props.onChange(new Date(newDate.endOf("month")));
    setValue(newDate.endOf("month"));
  }

  useEffect(() => {
    setDate(value);
  }, []);

  return (
    <Stack direction="row" justifyContent="space-between" sx={{my: 1}} >
      <Button
        disabled={
          minDate.diff(dayjs(value).subtract(1, "month").endOf("month")) > 0
        }
        startIcon={<ArrowBackIcon />}
        onClick={() => setDate(dayjs(value).subtract(1, "month"))}
      >
        {dayjs(value).subtract(1, "month").format("MMMM")}
      </Button>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          views={["year", "month"]}
          label={t('ui.yearMonth')}
          openTo="month"
          minDate={minDate}
          maxDate={maxDate}
          value={value}
          onChange={(newVal) => setDate(newVal)}
          // inputFormat="LL"
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      <Button
        disabled={maxDate.diff(dayjs(value).add(1, "month").endOf("month")) < 0}
        endIcon={<ArrowForwardIcon />}
        onClick={() => setDate(dayjs(value).add(1, "month"))}
      >
        {dayjs(value).add(1, "month").format("MMMM")}
      </Button>
    </Stack>
  );
}
