import { useState } from "react";
import { FiveSService } from "../../services";
import { useDispatch } from "react-redux";
import { showNotification } from "../../features/ui/notificationSlice";
import {
  Fab,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Stack,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useMediaQuery, useTheme } from "@mui/material";

function AddMasterQuestionModal(props) {
  const [newQuestionText, setNewQuestionText] = useState("");
  const [fiveSType, setFiveSType] = useState("");
  const [show, setShow] = useState(false);
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  function addQuestion() {
    FiveSService.insertQuestion(newQuestionText, 0, fiveSType)
      .then(() => {
        showNotification(
          dispatch,
          "New Question Added Successfully",
          "primary",
        );
        handleClose();
        props.onAddNewQuestionCallback();
      })
      .catch(() => {
        showNotification(dispatch, "Please try again.", "secondary");
      });
  }

  return (
    <>
      <Fab
        sx={{
          position: "fixed",
          bottom: (theme) => theme.spacing(2),
          right: (theme) => theme.spacing(2),
        }}
        variant={smallScreen ? "contained" : "extended"}
        color="primary"
        onClick={handleShow}
      >
        <AddIcon /> {smallScreen ? "" : "Soru Ekle"}
      </Fab>

      <Dialog fullWidth open={show}>
        <DialogTitle>Add New Question</DialogTitle>
        <DialogContent>
          <Stack gap={2} mt={1}>
            <FormControl fullWidth>
              <TextField
                label="Question title"
                value={newQuestionText}
                onChange={(e) => setNewQuestionText(e.target.value)}
                placeholder="New question"
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>5S Type</InputLabel>
              <Select
                size="small"
                value={fiveSType}
                label="5S Type"
                onChange={(e) => setFiveSType(e.target.value)}
              >
                <MenuItem key={null} value="">
                  None
                </MenuItem>
                <MenuItem key="TERTIP" value="TERTIP">
                  TERTIP
                </MenuItem>
                <MenuItem key="DUZEN" value="DUZEN">
                  DUZEN
                </MenuItem>
                <MenuItem key="TEMIZLIK" value="TEMIZLIK">
                  TEMIZLIK
                </MenuItem>
                <MenuItem key="STANDARTLASTIRMA" value="STANDARTLASTIRMA">
                  STANDARTLAŞTIRMA
                </MenuItem>
                <MenuItem key="DISIPLIN" value="DISIPLIN">
                  DİSİPLİN
                </MenuItem>
                <MenuItem key="ISG" value="ISG">
                  İSG
                </MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button color="primary" onClick={addQuestion}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AddMasterQuestionModal;
