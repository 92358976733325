import { useContext, useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { TabPanel } from "../../utils/commonFunctions";

import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Stack,
  Typography,
} from "@mui/material";
import Departments from "../Tree/Departments";
import Users from "../Users/Users";
import UserDetail from "../Users/UserDetail";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsersVisibleToCurrentUser, selectUsers, selectUsersVisibleToCurrentUser } from "../../features/users/usersSlice";
import { LoginService } from "../../services";
import {
  selectDepartments,
  selectComparableDepartments,
  selectDepartmentsVisibleToCurrentUser,
} from "../../features/departments/departmentsSlice";
import DepartmentDetail from "../Tree/DepartmentDetail";
import MultiDepartmentSelect from "../common/MultiDepartmentSelect";
import DepartmentRadarChart from "../common/DepartmentRadarChart";
import { Container, Grid } from "@mui/material";
import { Button } from "@mui/material";
import UserMonthlyDetails from "../Users/UserMonthlyDetails";
import { useTranslation } from "react-i18next";

const Reports = () => {
  const [currentTab, setcurrentTab] = useState("departments");
  const { t } = useTranslation()
  const { users } = useSelector(selectUsers);

  const departments = useSelector(selectDepartments);
  const viewableDepartments = useSelector(selectDepartmentsVisibleToCurrentUser)
  const [master, setMaster] = useState();

  const [user, setUser] = useState(LoginService.getUser().UserId);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const dispatch = useDispatch()

  const handleChange = (event, newValue) => {
    setcurrentTab(newValue);
  };

  useEffect(() => {
    try {
      // setMaster(departments.find((d) => d.master_id == 0).Id);
      setMaster(viewableDepartments[0].Id);
      dispatch(fetchUsersVisibleToCurrentUser(viewableDepartments))
      // setSelectedDepartments([departments[0]]);
    } catch (err) {
      console.error(err);
    }
  }, [departments]);

  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <Tabs
        value={currentTab}
        onChange={handleChange}
        sx={{
          backgroundColor: "white",
          position: "sticky",
          top: 60,
          zIndex: 5,
        }}
      >
        <Tab label={t('navbar.departments')} value="departments" />
        <Tab label={t('navbar.monthlyUserStats')} value="monthlyUserStats" />
      </Tabs>
      <TabPanel currentTab={currentTab} value="departments">
        {master && <DepartmentDetail id={master} />}
      </TabPanel>
      <TabPanel currentTab={currentTab} value="monthlyUserStats">
        <UserMonthlyDetails />
      </TabPanel>
    </Box>
  );
};

export default Reports;
