import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FiveSService } from "../../services";

export const fetchRestrictedQuestions = createAsyncThunk(
  "questions/fetchRestrictedQuestions",
  async () => {
    const response = await FiveSService.getAllRestrictedQuestions();
    return response;
  },
);

export const approveQuestion = createAsyncThunk(
  "questions/approveQuestion",
  async (questionId) => {
    const response = await FiveSService.approveRestrictedQuestions(questionId);
    return response;
  },
);

export const denyQuestion = createAsyncThunk(
  "questions/denyQuestion",
  async (questionId) => {
    const response = await FiveSService.denyRestrictedQuestions(questionId);
    return response;
  },
);

const initialState = {
  restrictedQuestions: [],
};

const questionsSlice = createSlice({
  name: "questions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRestrictedQuestions.fulfilled, (state, action) => {
      state.restrictedQuestions = action.payload.Table.sort((a, b) => {
        const d1 = Date.parse(a.SystemDate);
        const d2 = Date.parse(b.SystemDate);
        if (d1 < d2) return 1;
        else if (d1 > d2) return -1;
        else return 0;
      });
    });
  },
});

export const selectRestrictedQuestions = (state) =>
  state.questions.restrictedQuestions;

export default questionsSlice.reducer;
