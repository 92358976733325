import { Stack, Button, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { selectSettings } from "../../features/settings/settingsSlice";
import { useTranslation } from "react-i18next";
import { getImageUrl } from "../../utils/commonFunctions";

export default function ({title, year, month, yearly }) {
  const settings = useSelector(selectSettings);
  const { t } = useTranslation()
  return (
    <Stack
      justifyContent="space-between"
      direction="row"
    >
      <img
        height={50}
        src={getImageUrl(settings.Logo)}
        alt={settings.FactoryName}
      />
      <h4>{title}</h4>
      <Stack>
        <Button>{t('buttons.export2Pdf')}</Button>
        <Typography sx={{fontWeight: "bold", textDecoration: "underline"}}>{yearly ? "" : t(`months.${month}`)} {year}</Typography>
      </Stack>
    </Stack>
  );
}
