import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  selectDepartments,
  selectComparableDepartments,
  selectDepartmentsVisibleToCurrentUser,
} from "../../features/departments/departmentsSlice";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { getChildren, parentDepartmentIds } from "../../utils/commonFunctions";
import { Breadcrumbs, Button, Menu, MenuItem } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const EasyDepartmentSelect = forwardRef((props, ref) => {
  const params = useParams();
  const [id, setId] = useState(params.id || props.id);
  const navigatePages = props.navigatePages;
  const viewableDepartments = useSelector(
    selectDepartmentsVisibleToCurrentUser
  );
  const departments = useSelector(selectDepartments);
  const department = departments.find((d) => d.Id === Number(id));
  const [anchorEl, setAnchorEl] = useState(false);
  const navigate = useNavigate();

  useImperativeHandle(ref, () => ({
    setDepartment: (id) => {
      setId(id)
    },
  }));

  const handleMenuClick = (event) => {
    if (children.length === 1) {
      setId(children[0].Id);
      if (navigatePages) {
        navigate(`/departments/${children[0].Id}`);
      }
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  let children = getChildren(departments, Number(id));
  let parents = [
    Number(id),
    ...parentDepartmentIds(departments, Number(id)),
  ].reverse();

  useEffect(() => {
    parents = [
      Number(id),
      ...parentDepartmentIds(departments, Number(id)),
    ].reverse();
    children = getChildren(departments, Number(id));
  }, [id, params.id]);

  useEffect(() => {
    props.onChange(department);
  }, [department]);

  return department ? (
    <div>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
        {department.FullName.split(" - ").map((item, i) => {
          if (i + 1 === parents.length) {
            return (
              <div>
                <Button disabled={!children.length} onClick={handleMenuClick}>
                  {item}
                </Button>
                {children.length ? (
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    {children.map((child) => (
                      <MenuItem
                        // component={Button}
                        onClick={() => {
                          setAnchorEl(null);
                          if (navigatePages) {
                            navigate(`/departments/${child.Id}`);
                          }
                          setId(child.Id);
                        }}
                        // onClick={handleMenuClose}
                      >
                        {child.Adi}
                      </MenuItem>
                    ))}
                  </Menu>
                ) : (
                  ""
                )}
              </div>
            );
          }
          return (
            <Button
              underline="hover"
              color="inherit"
              onClick={() => {
                if (navigatePages) navigate(`/departments/${parents[i]}`);
                setId(parents[i]);
              }}
            >
              {item}
            </Button>
          );
        })}
      </Breadcrumbs>
    </div>
  ) : (
    <div></div>
  );
});

export default EasyDepartmentSelect
