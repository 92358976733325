const initialState = {
  showNotification: false,
  notificationMessage: "",
};

export default function uiReducer(state = initialState, action) {
  switch (action.type) {
    case "ui/notificationShow": {
      return action.payload;
    }
    default:
      return state;
  }
}

export function showNotification(dispatch, message) {
  dispatch({
    type: "ui/notificationShow",
    payload: { showNotification: true, notificationMessage: message },
  });
}

export const selectNotification = (state) => state.ui.notification;
