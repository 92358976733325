import {
  TextField,
  Stack,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState, useEffect } from "react";

const EditableSelect = (props) => {
  const [editMode, setEditMode] = useState(false);
  const [value, setValue] = useState(props.value);
  const [error, setError] = useState(false);

  useEffect(() => {
    setValue(props.value);
    setError(false);
  }, [editMode]);

  function handleUpdate() {
    props.onUpdate(value).then(() => setEditMode(!editMode));
  }

  function handleChange(e) {
    if (!e.target.value) setError(true);
    else setError(false);
    setValue(e.target.value);
  }

  function renderSelect(readOnly) {
    return (
      <FormControl fullWidth error={error}>
        <InputLabel>{props.label}</InputLabel>
        <Select
          size="small"
          value={value}
          label={props.label}
          onChange={handleChange}
          readOnly={readOnly}
        >
          <MenuItem key={null} value="">
            None
          </MenuItem>
          {props.list.map((item) => {
            return (
              <MenuItem key={item[props.listKey]} value={item[props.listKey]}>
                {item[props.listValue]}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }

  return editMode ? (
    <Stack direction="row">
      {renderSelect(false)}
      {/* <TextField */}
      {/*   fullWidth */}
      {/*   error={error} */}
      {/*   helperText={error && "Only integer values are allowed"} */}
      {/*   size={props.size ? props.size : "medium"} */}
      {/*   type={props.integerOnly ? "number" : "text"} */}
      {/*   label={props.label} */}
      {/*   multiline={props.multiline} */}
      {/*   rows={4} */}
      {/*   value={value} */}
      {/*   onChange={handleChange} */}
      {/* /> */}
      <IconButton color="error" onClick={() => setEditMode(!editMode)}>
        <CloseIcon />
      </IconButton>
      <IconButton color="success" disabled={error} onClick={handleUpdate}>
        <CheckIcon />
      </IconButton>
    </Stack>
  ) : (
    <Stack direction="row">
      {props.children ? props.children : renderSelect(true)}
      {props.onUpdate && (
        <IconButton color="primary" onClick={() => setEditMode(!editMode)}>
          <EditIcon />
        </IconButton>
      )}
      {props.onDelete && (
        <IconButton color="error" onClick={props.onDelete}>
          <DeleteIcon />
        </IconButton>
      )}
    </Stack>
  );
};

export default EditableSelect;
