import { useContext, useState } from "react";
import * as Pages from "../pages/";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { TabPanel, isAuditCompleted } from "../utils/commonFunctions";
import CreateFieldTourDialog from "../components/FieldTour/CreateFieldTourDialog";
import CreateWorkshopDialog from "../components/Workshop/CreateWorkshopDialog";
import Workshop from "../components/Workshop/Workshop";
import FieldTour from "../components/FieldTour/FieldTour";
import { Container, Fab, Grid, Typography, SpeedDial } from "@mui/material";
import { DateFormat } from "../utils/DateFormat";
import AuditFilter from "../components/MainActivity/Audit/AuditFilterBar";
import { useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  changeAuditViewTab,
  selectAuditView,
} from "../features/ui/auditViewSlice";

import FilterListIcon from "@mui/icons-material/FilterList";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import AddIcon from "@mui/icons-material/Add";
import { dialogActions } from "../features/ui/dialogsSlice";
import InsertAuditDialog from "../components/MainActivity/Audit/InsertAuditDialog";
import { FilterAuditsDialog } from "../components/MainActivity/Audit/FilterAuditsDialog";
import {
  selectAudits,
  selectFieldTours,
  selectMeetings,
  selectNonCompleteAudits,
  selectNonCompleteFieldTours,
  selectNonCompleteMeetings,
  selectNonCompleteWorkshops,
  selectWorkshops,
} from "../features/audits/auditsSlice";
import AuditListComponent from "../components/MainActivity/Audit/AuditListComponent";
import AuditCardsComponent from "../components/MainActivity/Audit/AuditCardsComponent";
import AuditCalendarComponent from "../components/MainActivity/Audit/AuditCalendarComponent";
import { currentUserIsAdmin, getChildDepartmentsOfRoleCode, userHasPermission } from "../utils/authFunctions";
import Meetings from "../components/Meetings/Meetings";
import CreateMeetingDialog from "../components/Meetings/CreateMeetingDialog";
import dayjs from "dayjs";
import { LoginService } from "../services";
import { selectDepartments } from "../features/departments/departmentsSlice";
import { useTranslation } from "react-i18next";

function PageWithFilters(props) {
  const { start, end, filter } = useSelector(selectAuditView);
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const toggleFilterDialog = () => setShowFilterDialog(!showFilterDialog);
  const addPermission = userHasPermission("add");
  const isAdmin = currentUserIsAdmin();
  const {t} = useTranslation()

  function getFilterText() {
    const activityType = t(`fiveSActivities.${props.type}`)
    if (filter == "5") {
      return t('fiveSActivities.allTimes', { activityType: activityType })
    } else
      return t('fiveSActivities.currentMonth', {currentMonth: dayjs(end).format("MMMM"), activityType: activityType})
  }

  return (
    <Grid container alignItems="flex-end" justifyContent="center">
      {!smallScreen && addPermission && isAdmin && (
        <Fab
          sx={{
            position: "fixed",
            bottom: (theme) => theme.spacing(2),
            right: (theme) => theme.spacing(2),
          }}
          variant={smallScreen ? "contained" : "extended"}
          color="primary"
          onClick={props.addItemCallback}
        >
          <AddIcon /> {smallScreen ? "" : props.label}
        </Fab>
      )}

      {showFilterDialog && (
        <FilterAuditsDialog
          show={showFilterDialog}
          onClose={toggleFilterDialog}
        />
      )}
      {smallScreen && (
        <SpeedDial
          ariaLabel="SpeedDial basic example"
          sx={{ position: "fixed", bottom: 16, right: 16 }}
          icon={<SpeedDialIcon />}
        >
          <SpeedDialAction
            key="add"
            icon={<AddIcon />}
            tooltipTitle={props.label}
            onClick={props.addItemCallback}
            disabled={!addPermission && !isAdmin}
          />
          <SpeedDialAction
            key="filter"
            icon={<FilterListIcon />}
            onClick={() => toggleFilterDialog()}
            tooltipTitle="Filtrele"
          />
        </SpeedDial>
      )}
      <Typography variant="h6" align="center">
        {getFilterText()}
      </Typography>
      {smallScreen ? null : (
        <Grid item xs={12} md={11} m={{ md: 2, xs: 0 }} mt={{ xs: 1 }}>
          <AuditFilter></AuditFilter>
        </Grid>
      )}
      <Container>{props.children}</Container>
    </Grid>
  );
}

const FiveSActivities = () => {
  const dispatch = useDispatch();
  const [showInsertAudit, setShowInsertAudit] = useState(false);
  const toggleInsertAuditDialog = () => setShowInsertAudit(!showInsertAudit);
  const { auditViewType, activityTab, filter } = useSelector(selectAuditView);
  const audits = useSelector(selectAudits);
  const departments = useSelector(selectDepartments);
  const fieldTours = useSelector(selectFieldTours);
  const workshops = useSelector(selectWorkshops);
  const meetings = useSelector(selectMeetings)
  const nonCompleteAudits = useSelector(selectNonCompleteAudits);
  const nonCompleteFieldTours = useSelector(selectNonCompleteFieldTours);
  const nonCompleteWorkshops = useSelector(selectNonCompleteWorkshops);
  const nonCompleteMeetings = useSelector(selectNonCompleteMeetings);
  const { t, i18n: {changeLanguage, language} } = useTranslation();

  const handleChange = (event, newValue) => {
    changeAuditViewTab(dispatch, newValue);
  };

  function getData(type, nonComplete) {
    if (nonComplete) {
      if (type == "audits") {
        return nonCompleteAudits;
      } else if (type == "fieldTours") {
        return nonCompleteFieldTours;
      } else if (type == "workshops") {
        return nonCompleteWorkshops
      } else return nonCompleteMeetings ;

    } else {
      if (type == "audits") {
        return audits;
      } else if (type == "fieldTours") {
        return fieldTours;
      } else if (type == "workshops") {
        return workshops
      } else return meetings;
    }
  }

  function renderData(type, nonComplete) {
    const data = getData(type, nonComplete);
    const isAdmin = currentUserIsAdmin();
    const currentUserId = LoginService.getUser().UserId;
    const viewAbleChildDepartments = getChildDepartmentsOfRoleCode(departments, "YO");
    const filtered = data.filter(audit=>{
      const isAuditor = audit.AuditorId == currentUserId;
      const isResponsibleUser = audit.InchargeUserId == currentUserId;
      const completed = isAuditCompleted(audit);
      return isAdmin ||
      isAuditor ||
      isResponsibleUser ||
      viewAbleChildDepartments.includes(audit.DepartmentId);

    })
    if (auditViewType === "list") return <AuditListComponent audits={data} />;
    else if (auditViewType === "card")
      return <AuditCardsComponent audits={filtered} />;
    else return <AuditCalendarComponent audits={data} />;
  }

  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <InsertAuditDialog
        show={showInsertAudit}
        onClose={toggleInsertAuditDialog}
      />

      <CreateFieldTourDialog />
      <CreateWorkshopDialog />
      <CreateMeetingDialog />
      <Tabs value={activityTab} onChange={handleChange}>
        <Tab label={t('navbar.audits')} value="audits" />
        <Tab label={t('navbar.fieldTour')} value="fieldTours" />
        <Tab label={t('navbar.workshops')} value="workshops" />
        <Tab label={t('navbar.meetings')} value="meetings" />
      </Tabs>
      <TabPanel currentTab={activityTab} value="audits">
        <PageWithFilters
          type="audits"
          label={t('buttons.createAudit')}
          addItemCallback={() => toggleInsertAuditDialog(true)}
        >
          {renderData("audits", filter == "5")}
        </PageWithFilters>
      </TabPanel>
      <TabPanel currentTab={activityTab} value="fieldTours">
        <PageWithFilters
          type="fieldTours"
          label={t('buttons.createFieldTour')}
          addItemCallback={() =>
            dispatch(dialogActions.toggleCreateFieldTour())
          }
        >
          {renderData("fieldTours", filter == "5")}
        </PageWithFilters>
      </TabPanel>
      <TabPanel currentTab={activityTab} value="workshops">
        <PageWithFilters
          type="workshops"
          label={t('buttons.createWorkshop')}
          addItemCallback={() => dispatch(dialogActions.toggleCreateWorkshop())}
        >
          {renderData("workshops", filter == "5")}
        </PageWithFilters>
      </TabPanel>
      <TabPanel currentTab={activityTab} value="meetings">
        <PageWithFilters
          type="meetings"
          label={t('buttons.createMeeting')}
          addItemCallback={() => dispatch(dialogActions.toggleCreateMeeting())}
        >
          {renderData("meetings", filter == "5")}
        </PageWithFilters>
      </TabPanel>
    </Box>
  );
};

export default FiveSActivities;
