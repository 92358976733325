import { useContext, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { FormHelperText, IconButton, InputAdornment, Stack } from "@mui/material";

import { TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { addUser } from "../../features/users/usersSlice";
import { useTranslation } from "react-i18next";

export default function InsertUserDialog(props) {
  const dispatch = useDispatch();
  const [repassword, setRepassword] = useState('')
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const { t } = useTranslation()
  const defaultValues = {
    longName: "",
    email: "",
    password: "",
    // roles: [],
  };
  const [formValues, setFormValues] = useState(defaultValues);
  const error = formValues.password != repassword

  function hideDialog() {
    props.onClose();
    setFormValues(defaultValues);
    setRepassword('')
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(addUser({...formValues, loginName: formValues.email}));
    hideDialog();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  return (
    <Dialog open={props.show} fullWidth>
      <DialogTitle>Yeni Kullanıcı Ekle</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Stack gap={2}>
            <TextField
              label={t('ui.nameSurname')}
              name="longName"
              required
              onChange={handleInputChange}
              value={formValues.longName}
            />
            <TextField
              label={t('ui.email')}
              name="email"
              type="email"
              required
              onChange={handleInputChange}
              value={formValues.email}
            />
            <TextField
              label={t("ui.password")}
              name="password"
              required
              type={showPassword ? "text" : "password"}
              onChange={handleInputChange}
              value={formValues.password}
              InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
            />
            <TextField
              label={t("ui.passwordAgain")}
              name="repassword"
              required
              type={showPassword ? "text" : "password"}
              error={error}
              onChange={(e)=>setRepassword(e.target.value)}
              value={repassword}
              helperText={error ? "Girdiğiniz parolalar eşleşmiyor!" : ""}
              InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={hideDialog}>
            {t('buttons.cancel')}
          </Button>
          <Button disabled={error} variant="outlined" type="submit">
            {t('buttons.ok')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
