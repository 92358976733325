import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { makeStyles } from "@material-ui/core/styles";
import { useState, useEffect, useRef, useContext } from "react";
import {
  Avatar,
  Card,
  CardContent,
  Chip,
  Typography,
  Stack,
  Button,
  CardHeader,
  CardActions,
  Paper,
  IconButton,
  useTheme,
  useMediaQuery,
  TextField,
  LinearProgress,
} from "@mui/material";

import { useParams } from "react-router-dom";
import {
  deleteRole,
  deleteUser,
  fetchUsers,
  selectUsers,
  selectUsersWithPeriodicScores,
  updateUser,
} from "../../features/users/usersSlice";
import { selectDepartments } from "../../features/departments/departmentsSlice";
import { selectRoleUsers } from "../../features/roleUsers/roleUsersSlice";
import InsertRoleDialog from "./InsertRoleDialog";
import { showConfirmDialog } from "../../features/ui/confirmDialogSlice";
import fuzzysort from "fuzzysort";
import EditableAvatar from "../common/EditableAvatar";
import { getImageUrl } from "../../utils/commonFunctions";
import FindingStats from "../common/FindingStats";
import FindingsList from "../Findings/FindingsList";
import { selectFilteredFindings } from "../../features/findings/findingsSlice";
import { setFilter } from "../../features/ui/findingFiltersSlice";
import {
  fetchUserStatistics,
  setFindingStats,
} from "../../features/ui/findingStatsSlice";
import { currentUserIsAdmin, userHasPermission } from "../../utils/authFunctions";
import { LoginService } from "../../services";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(20),
    height: theme.spacing(20),
    margin: "auto",
  },
  main: {
    fontSize: 24,
    fontWeight: "bold",
    textAlign: "center",
  },
  detail: {
    fontSize: 18,
    textAlign: "center",
  },
}));

function UserDetail(props) {
  const classes = useStyles();
  const departments = useSelector(selectDepartments);
  const roleUsers = useSelector(selectRoleUsers);
  const params = useParams();
  const [dialogData, setDialogData] = useState();
  const [showInsertRoleDialog, setShowInsertRoleDialog] = useState(false);
  const users = useSelector(selectUsers);

  const user =
    props.user ||
    users.users.find((u) => u.Id === Number(params.id || props.id));

  const [roles, setRoles] = useState([]);
  const dispatch = useDispatch();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [editMode, setEditMode] = useState(false);
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [longName, setLongName] = useState(user?.LongName);
  const [repassword, setRepassword] = useState('');
  const avatarRef = useRef();
  const [progress, setProgress] = useState(0);
  const { t } = useTranslation()
  const updatePermission = currentUserIsAdmin();
  const deletePermission = !props.noDelete && currentUserIsAdmin()
  const error = password !== repassword
  const isCurrentUser = LoginService.getUser()?.UserId == user?.Id

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLongNameChange = (e) => {
    setLongName(e.target.value);
  };

  const handleRepasswordChange = (e) => {
    setRepassword(e.target.value);
  };

  useEffect(() => {
    dispatch(setFilter.department(null));
  }, []);


  useEffect(() => {
    if (user) {
      const userRoles = user.UserRolesId.split(",").map((val) =>
        roleUsers.find((role) => role.Id === Number(val))
      );
      setRoles(userRoles);
    }
    // TODO: get rid of the following comment
    // eslint-disable-next-line
  }, [user]);

  function departmentName(id) {
    const dep = departments.find((d) => d.Id === id)?.Adi;
    if (!dep) return "";
    else return dep + " - ";
  }

  function toggleInsertRoleDialog(data) {
    if (data) {
      const dialogData = {
        id: data.Id,
        // roles: data.Roles.split(",")
        //   .filter((i) => i)
        //   .map((e) => Number(e)),
      };
      setDialogData(dialogData);
      console.log("dialog data", dialogData);
    }
    setShowInsertRoleDialog(!showInsertRoleDialog);
  }

  function handleInsertRole(e, user) {
    e.stopPropagation();
    toggleInsertRoleDialog(user);
  }

  function handleDeleteRole(roleUserId) {
    dispatch(deleteRole(roleUserId));
  }

  async function handleDelete(e, user) {
    e.stopPropagation();
    const confirmed = await showConfirmDialog(
      dispatch,
      `Are you sure you want to delete user "${user.LoginName}"?`
    );
    if (confirmed) {
      dispatch(deleteUser(user.Id));
    } else {
      console.log("not confirmed");
    }
  }

  function toggleEditMode() {
    setPassword('');
    setLongName(user.LongName);
    setRepassword('');
    setEditMode(!editMode);
    setProgress(0);
  }

  async function handleUpdateUser() {
    const imageId = await avatarRef.current.upload();
    const data = { userId: user.Id, loginName: user.LoginName, longName, email: user.email, imageId, password };

    dispatch(updateUser(data));
    dispatch(fetchUsers());
    toggleEditMode();
  }

  function highlightName(match) {
    const result = fuzzysort.highlight(match[0], (m, i) => <b>{m}</b>);
    if (!result || !result.length) return user.LongName;
    else return result;
  }

  function highlightEmail(match) {
    const result = fuzzysort.highlight(match[1], (m, i) => <b>{m}</b>);
    if (!result || !result.length) return user.email;
    else return result;
  }

  function singleUserDetail() {
    return (
      <Stack gap={2}>
        <Card>
          <LinearProgress variant="determinate" value={progress} />
          <CardContent>
            <Stack gap={2} alignItems="center">
              <>
                <EditableAvatar
                  ref={avatarRef}
                  imageId={user.Photo}
                  alt={user.LongName}
                  setProgress={setProgress}
                  editable={editMode}
                />
                {editMode ? (
                  <TextField
                    className={classes.detail}
                    value={longName}
                    variant="outlined"
                    label={t('profile.fullname')}
                    onChange={handleLongNameChange}
                  />
                ) : (
                  <Typography className={classes.detail} variant="h5">
                    {user.LongName}
                  </Typography>
                )}
              </>
              {editMode ? (
                <TextField
                  value={password}
                  variant="outlined"
                  label={t("ui.password")}
                  onChange={handlePasswordChange}
                />
              ) : (
                <Typography className={classes.main} variant="body1">
                  {user.LoginName}
                </Typography>
              )}
              {editMode ? (
                <TextField
                  className={classes.detail}
                  value={repassword}
                  variant="outlined"
                  label={t("ui.passwordAgain")}
                  helperText={error ? t('ui.passwordError') : ""}
                  onChange={handleRepasswordChange}
                />
              ) : (
                <Typography className={classes.detail} variant="body1">
                  {user.email}
                </Typography>
              )}
              <Stack
                sx={{
                  maxWidth: "calc(100% - 0px)",
                  marginX: "auto",
                  overflowX: "auto",
                }}
                direction="row"
                gap={1}
              >
                {roles
                  .filter((i) => i)
                  .map((role) => (
                    <Chip
                      key={role.Id}
                      label={departmentName(role.DepartmentId) + role.Adi}
                      // size={smallScreen ? "small" : "medium"}
                      onDelete={
                        deletePermission
                          ? () => handleDeleteRole(role.Id)
                          : undefined
                      }
                    />
                  ))}
                {!props.id && updatePermission && (
                  <Chip
                    key="add"
                    label={t('buttons.addRole')}
                    variant="outlined"
                    color="success"
                    icon={<AddIcon />}
                    onClick={(e) => handleInsertRole(e, user)}
                  />
                )}
              </Stack>
              <Stack
                gap={2}
                justifyContent="flex-end"
                width="100%"
                direction="row"
              >
                {editMode ? (
                  <>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={toggleEditMode}
                      startIcon={<CloseIcon />}
                    >
                      {t('buttons.cancel')}
                    </Button>
                    <Button
                      variant="outlined"
                      color="success"
                      onClick={handleUpdateUser}
                      disabled={error}
                      startIcon={<CheckIcon />}
                    >
                      {t('buttons.save')}
                    </Button>
                  </>
                ) : (
                  (isCurrentUser || updatePermission) && (
                    <Button
                      variant="outlined"
                      onClick={toggleEditMode}
                      startIcon={<EditIcon />}
                    >
                      {t('buttons.editUser')}
                    </Button>
                  )
                )}
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    );
  }

  function oneLineUserDetail() {
    return (
      <Paper onClick={() => navigate(`/users/${user.Id}`)}>
        <Stack direction="row" gap={1} p={1} justifyContent="flex-start">
          <Avatar alt={user.LongName} src={getImageUrl(user.Photo)} />

          <Stack direction="row" gap={1} width="93%">
            <Stack>
              <Typography>{highlightName(props.match)}</Typography>
              <Typography variant="subtitle2" color="text.secondary">
                {highlightEmail(props.match)}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              gap={1}
              sx={{
                overflowX: "auto",
                marginX: "auto",
              }}
            >
              {!smallScreen &&
                roles
                  .filter((i) => i)
                  .map((role) => (
                    <Chip
                      key={role.Id}
                      label={departmentName(role.DepartmentId) + role.Adi}
                      // size={smallScreen ? "small" : "medium"}
                      onDelete={
                        deletePermission
                          ? () => handleDeleteRole(role.Id)
                          : undefined
                      }
                    />
                  ))}
            </Stack>
            {deletePermission && (
              <IconButton
                onClick={(e) => handleDelete(e, user)}
                color="error"
                variant="outlined"
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Stack>
        </Stack>
      </Paper>
    );
  }
  return (
    <>
      {user && (props.user ? oneLineUserDetail() : singleUserDetail())}

      {showInsertRoleDialog && (
        <InsertRoleDialog
          show={showInsertRoleDialog}
          data={dialogData}
          onClose={toggleInsertRoleDialog}
        />
      )}
    </>
  );
}

export default UserDetail;
