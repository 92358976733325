import {
  Autocomplete,
  Checkbox,
  FormControl,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { selectDepartments } from "../../features/departments/departmentsSlice";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const MultiDepartmentSelect = (props) => {
  const departments = useSelector(selectDepartments);
  return (
    <FormControl fullWidth error={props.error && !props.value}>
      <Autocomplete
        multiple
        disableCloseOnSelect
        options={[...departments].sort((a, b) => {
          if (a.Adi > b.Adi) return 1;
          else if (a.Adi < b.Adi) return -1;
          else return 0;
        })}
        size="small"
        getOptionLabel={(d) => d.Adi}
        value={props.value}
        // onChange={(e) => props.onChangeUser(e.target.value)}
        onChange={(e, val) => props.onChangeUserList(val)}
        renderOption={(props, option, { selected }) => (
          <li value={option.Id} {...props}>
            <Checkbox
              icon={icon}
              value={option.Id}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.Adi}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            required={props.required ? true : false}
            error={props.error && !props.valueDepartment}
            label="Departmanlar"
            placeholder="Departman Seçin"
            variant="standard"
          />
        )}
      >
        <MenuItem key={null} value="">
          None
        </MenuItem>
        {departments.map((departments) => {
          return (
            <MenuItem key={departments.Id} value={departments.Id}>
              {departments.Adi}
            </MenuItem>
          );
        })}
      </Autocomplete>
    </FormControl>
  );
};

export default MultiDepartmentSelect;
