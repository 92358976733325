import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import {
  selectDepartmentsWithPeriodicScores,
  selectDepartmentsWithPeriodicScoresYear,
} from "../../features/departments/departmentsSlice";
import { getChildren, months, sumSubArrayValues } from "../../utils/commonFunctions";
import { Box } from "@mui/material";

import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import Title from "../common/Title"

const SubDepartmentsReport = ({ parent, currentMonth, yearly, currentYear }) => {
  const { t } = useTranslation()
  function format(value, bold) {
    if (bold) {
      return <strong>{value}</strong>;
    } else return value;
  }
  const columns = [
    {
      headerName: t('ui.subDepartment'),
      field: "Adi",
      renderCell: (params) => format(params.value, params.row.Id == parent.Id),
      flex: 2,
    },
    {
      headerName: t('ui.shortHeaders.completed'),
      type: "number",
      field: "CompCnt",
      renderCell: (params) => format(params.value, params.row.Id == parent.Id),
      flex: 0.9,
    },
    {
      headerName: t('ui.shortHeaders.expired'),
      type: "number",
      field: "DueExpCnt",

      renderCell: (params) => format(params.value, params.row.Id == parent.Id),
      flex: 0.6,
    },
    {
      headerName: t('ui.shortHeaders.upcoming'),
      field: "hasTime",
      type: "number",
      flex: 0.9,
      valueGetter: (params) =>
        (params.row.Cnt || 0) -
        (params.row.DueExpCnt || 0) -
        (params.row.CompCnt || 0),
      renderCell: (params) => format(params.value, params.row.Id == parent.Id),
    },
    {
      headerName: t('ui.shortHeaders.total'),
      field: "Cnt",
      type: "number",
      flex: 0.6,
      renderCell: (params) => format(params.value, params.row.Id == parent.Id),
    },
    {
      headerName: t('ui.shortHeaders.closeRate'),
      field: "closeRate",
      type: "number",
      flex: 1,
      valueGetter: (params) =>
        ((params.row.CompCnt / params.row.Cnt || 0) * 100).toFixed(2),
      renderCell: (params) =>
        format(`%${params.value}`, params.row.Id == parent.Id),
    },
    {
      headerName: t('ui.shortHeaders.conformRate'),
      field: "compatibleRate",
      type: "number",
      flex: 1,
      valueGetter: (params) =>
        ((params.row.TermCompCnt / params.row.CompCnt || 0) * 100).toFixed(2),
      renderCell: (params) =>
        format(`%${params.value}`, params.row.Id == parent.Id),
    },
  ];

  const departmentsWithPeriodicScores = useSelector(
    selectDepartmentsWithPeriodicScoresYear
  );
  const sumValues = sumSubArrayValues(departmentsWithPeriodicScores, "Id")
  const correctData = yearly ? sumValues : departmentsWithPeriodicScores[currentMonth]
  const data = getChildren(
    correctData,
    parent.Id
  );
  const self = correctData.find(
    (d) => d.Id == parent.Id
  );
  data.unshift(self);
  return data.length ? (
    <Paper elevation={12}>
      <DataGrid
        autoHeight
        rows={data}
        components={{ Toolbar: Title }}
        componentsProps={{ toolbar: { title: t(yearly ? "titles.subDepReportYearly" : "titles.subDepReportMonthly", {currentMonth: t(`months.${currentMonth}`)}), month: currentMonth, year: currentYear, yearly: yearly } }}
        columns={columns}
        getRowId={(row) => row.Id}
        hideFooter={true}
      />
    </Paper>
  ) : (
    <div></div>
  );
};

export default SubDepartmentsReport;
