import React, { useState } from "react";
import { Stack, useTheme, useMediaQuery, Container } from "@mui/material";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { QuestionDetails, BeforeAfterDetails } from "../Findings/DetailsDialog";
import { createPDF } from "./ExportToPdf";
import { Fab } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { selectSettings } from "../../features/settings/settingsSlice";
import { getImageUrl } from "../../utils/commonFunctions";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";

function FindingReport() {
  const settings = useSelector(selectSettings);
  const { state } = useLocation();
  const { finding } = state; // Read values passed on state

  return (
    <>
      <Fab
        sx={{
          position: "fixed",
          bottom: 10,
          right: 10,
        }}
        variant="extended"
        color="primary"
        onClick={() =>
          createPDF("#findingContent", "p", "Uygunsuzluk Raporu " + finding.Id)
        }
      >
        <PictureAsPdfIcon /> PDF olarak kaydet
      </Fab>
      <Container>
        <Stack id="findingContent" p={10}>
          <Stack gap={1} alignItems="center" direction="row">
            <img
              height={50}
              src={getImageUrl(settings.Logo)}
              alt={settings.FactoryName}
            />
            <Typography variant="h6">
              Bulgu Detayları (Bulgu No: {finding.Id})
            </Typography>
          </Stack>
          {finding.ActivityQuestionId ? (
            <QuestionDetails questionId={finding.ActivityQuestionId} />
          ) : (
            ""
          )}
          <BeforeAfterDetails {...finding}></BeforeAfterDetails>
        </Stack>
      </Container>
    </>
  );
}

export default FindingReport;
