import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectUsersAuditPerformance, selectUsersVisibleToCurrentUser } from "../../features/users/usersSlice";
import { useTranslation } from "react-i18next";
import { DataGrid } from "@mui/x-data-grid";
import { Avatar, Button, Container, Paper, Stack, Tooltip, Typography } from "@mui/material";
import {
  getImageUrl,
  sumArrayValues,
  sumSubArrayValues,
} from "../../utils/commonFunctions";
import { renderProgress } from "../common/ProgressBar";
import Legend from "./Legend";
import { selectSettings } from "../../features/settings/settingsSlice";
import { selectFilterUser } from "../../features/ui/listFiltersSlice";

function Title({title, year, month, yearly }) {
  const settings = useSelector(selectSettings);
  const { t } = useTranslation()
  return (
    <Stack
      justifyContent="space-between"
      direction="row"
    >
      <img
        height={50}
        src={getImageUrl(settings.Logo)}
        alt={settings.FactoryName}
      />
      <h4>{title}</h4>
      <Stack>
        <Button>{t('buttons.export2Pdf')}</Button>
        <Typography sx={{fontWeight: "bold", textDecoration: "underline"}}>{yearly ? "" : t(`months.${month}`)} {year}</Typography>
      </Stack>
    </Stack>
  );
}

export default function FindingsPerformanceReport(props) {
  const performance = useSelector(selectUsersAuditPerformance);
  const selectedUser = useSelector(selectFilterUser)
  const usersVisibleToCurrentUser = useSelector(
    selectUsersVisibleToCurrentUser
  );
  const filteredUsers = selectedUser == null ? usersVisibleToCurrentUser : [selectedUser]
  const monthlyData = performance["findings"].filter(
    (u) => u.M == props.currentMonth + 1 && filteredUsers.find(m=>m.Id == u.userID)
  );
  const yearlyData = sumArrayValues(performance["findings"].filter(u=>filteredUsers.find(m=>m.Id == u.userID)), "userID");
  const [sortModel, setSortModel] = useState([
    {
      field: "closeRate",
      sort: "desc",
    },
  ]);
  const yearly = props.yearly;
  const data = yearly ? yearlyData : monthlyData;
  const { t } = useTranslation();
  const columns = [
    {
      headerName: t("ui.nameSurname"),
      field: "LongName",
      flex: 1,
      renderCell: (params) => (
        <Stack
          direction="row"
          gap={1}
          className="pointer"
          onClick={async () => {
            props.onSelectUser(params.row);
          }}
        >
          <Avatar
            sx={{ width: 28, height: 28 }}
            alt={params.value}
            src={getImageUrl(params.row.photo)}
          />
          <Typography variant="p">{params.value}</Typography>
        </Stack>
      ),
    },
    {
      headerName: t("performances.completedVsTotal.findings"),
      type: "number",
      field: "Score",
      flex: 1,
      renderCell: (params) => <Stack gap={0.3} direction="row">
        <Typography color={params.value == params.row.Cnt ? "" : "error"}>{params.value}</Typography><Typography>/</Typography><Typography>{params.row.Cnt}</Typography>
      </Stack>
    },

    {
      headerName: t('ui.shortHeaders.closeRate'),
      field: "closeRate",
      type: "number",
      flex: 0.5,
      valueGetter: (params) =>
        Math.round(100 * (params.row.Score / params.row.Cnt || 0)),
      renderCell: renderProgress
    },
    {
      headerName: t('ui.shortHeaders.conformRate'),
      field: "compatibleRate",
      type: "number",
      flex: 0.5,
      valueGetter: (params) =>
        Math.round((params.row.Termin / params.row.Score || 0) * 100),
      renderCell: renderProgress
    },
  ];
  return (
    <Stack component={Paper} gap={1} elevation={12}>
      <DataGrid
        autoHeight
        rows={data}
        components={{ Toolbar: Title }}
        componentsProps={{ toolbar: { title: t(`performances.findings`), month: props.currentMonth, year: props.currentYear, yearly: yearly } }}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        columns={columns}
        getRowId={(row) => row.id}
        hideFooter={true}
      />
      <Legend />
    </Stack>
  );
}
