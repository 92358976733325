import { useSelector } from "react-redux";
import { selectDepartmentsWithPeriodicScoresYear } from "../../features/departments/departmentsSlice";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  BarElement
} from "chart.js";
import { Line } from "react-chartjs-2";
import { FormControlLabel, Paper, Switch } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { selectUsersAuditPerformance } from "../../features/users/usersSlice";

ChartJS.register(
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  BarElement,
);

const options = (title) => {

  return {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: title,
      },
    },
    scales: {
      y: {
        max: 100,
      },
    },
  }
};

const YearlyAuditGraphUser = ({selectedUser}) => {
  const performance = useSelector(selectUsersAuditPerformance)
  const performanceConstants = {
    managers: 1 / 6,
    inchargeUsers: 1 / 6,
    auditors: 1,
    findings: 100,
    participants: 100,
  };
  const {t} = useTranslation()

  const calculateScore = (type, month) => {
    const p = performance[type].filter(u=>u.userID == selectedUser?.userID).find(u => u.M == month + 1 )
    if (p) {
      const score = p.Score
      const count = p.Cnt
      return score / count * performanceConstants[type]
    }
    return type == "participants" ? 100 : 0
  }

  function prepareData() {
    const months = [
      t('months.0'),
      t('months.1'),
      t('months.2'),
      t('months.3'),
      t('months.4'),
      t('months.5'),
      t('months.6'),
      t('months.7'),
      t('months.8'),
      t('months.9'),
      t('months.10'),
      t('months.11'),
    ];
    return months.map((m, i) => {
      return { label: m, managementPerformance: calculateScore("managers", i), auditorPerformance: calculateScore("auditors", i), inchargeUserPerformance: calculateScore("inchargeUsers", i), findingsPerformance: calculateScore("findings", i ), participationPerformance: calculateScore("participants", i)};
    });
  }

  function getChartData() {
    const chartData = prepareData();
    return {
      labels: chartData.map((d) => d.label),
      datasets: [
        {
          tension: 0.4,
          pointRadius: 0,
          pointHitRadius: 10,
          label: "Denetci Performansi",
          data: chartData.map((d) => d.auditorPerformance),
        },
        {
          tension: 0.4,
          pointRadius: 0,
          pointHitRadius: 10,
          label: "Sorumlu Performansı",
          data: chartData.map((d) => d.inchargeUserPerformance),
        },
        {
          tension: 0.4,
          pointRadius: 0,
          pointHitRadius: 10,
          label: "Yonetim Performansı",
          data: chartData.map((d) => d.managementPerformance),
        },
        {
          tension: 0.4,
          pointRadius: 0,
          pointHitRadius: 10,
          label: "Bulgu Performansı",
          data: chartData.map((d) => d.findingsPerformance),
        },
        {
          tension: 0.4,
          pointRadius: 0,
          pointHitRadius: 10,
          label: "Katılım Oranı",
          data: chartData.map((d) => d.participationPerformance),
        },
      ],
    };
  }
  const title = t('titles.monthlyUserPerformance', {currentUser: selectedUser?.LongName })

  return (
    <Paper elevation={12}>
      <Line options={options(title)} data={getChartData()} />
    </Paper>
  )
};

export default YearlyAuditGraphUser;
