import { useState } from "react";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import logo from "../../resources/Navbar/logo_2023.jpg";

import {
  Button,
  TextField,
  FormControl,
  InputAdornment,
  IconButton,
  Typography,
  Container,
  Box,
  Paper,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { LoginService } from "../../services";

export default function Login() {
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState("");
  const redirect = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = await LoginService.login(username, password);
    if (user.LoginName !== null) {
      setMessage("Welcome!");
      redirect("/");
    } else {
      setMessage("Username or password is wrong.");
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <Container maxWidth="sm">
        <Paper
          elevation={3}
          sx={{ padding: "2rem", display: "flex", flexDirection: "column" }}
        >
          <Box sx={{ alignSelf: "center", marginBottom: "1rem" }}>
            <img src={logo} width="100%" alt="Orthomagic Logo" />
            <Typography variant="h4" align="center">
              Portal Login
            </Typography>
          </Box>
          <form onSubmit={handleSubmit}>
            <FormControl fullWidth margin="normal">
              <TextField
                placeholder="User name"
                label="User name"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <TextField
                type={showPassword ? "text" : "password"}
                label="Password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>

            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              sx={{ marginTop: "1rem" }}
            >
              Login
            </Button>

            <Typography
              variant="body1"
              align="center"
              sx={{ marginTop: "1rem" }}
            >
              Forgot your password? Reset your password{" "}
              <Link to="/forgotpassword">here</Link>.
            </Typography>

            {message && (
              <Box
                sx={{
                  marginTop: "1rem",
                  borderRadius: "0.5rem",
                  backgroundColor: "error.main",
                  color: "white",
                  padding: "1rem",
                  textAlign: "center",
                }}
              >
                {message}
              </Box>
            )}
          </form>
        </Paper>
      </Container>
    </Box>
  );
}
