import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Switch from "@mui/material/Switch";
import { useDispatch } from "react-redux";
import { selectCurrentAudit } from "../../features/audits/currentAuditSlice";
import { useSelector } from "react-redux";
import { updateParticipants } from "../../features/audits/currentAuditSlice";
import { useTranslation } from "react-i18next";

export default function ActivityParticipants(props) {
  const { participants } = useSelector(selectCurrentAudit);
  const { t } = useTranslation()
  const dispatch = useDispatch();

  const handleToggle = (participant) => () => {
    if (props.disabled == true) return;

    var all = participants
      .filter((item) => {
        if (item.UserID == participant.UserID && item.Present == 1)
          return false;
        else if (item.UserID == participant.UserID && item.Present != 1)
          return true;
        else return item.Present == 1;
      })
      .map((item) => item.UserID)
      .join(",");

    dispatch(
      updateParticipants({
        activityId: participant.MainActivityId,
        participants: all,
      }),
    );
  };

  return (
    <List
      sx={{ bgcolor: "background.paper" }}
      subheader={<ListSubheader>{t('ui.participants')}</ListSubheader>}
    >
      {participants.map((participant) => (
        <ListItem>
          <ListItemText
            id="switch-list-label-wifi"
            primary={participant.LongName}
          />
          <Switch
            edge="end"
            onChange={handleToggle(participant)}
            checked={participant.Present == 1}
            inputProps={{
              "aria-labelledby": "switch-list-label-wifi",
            }}
          />
        </ListItem>
      ))}
    </List>
  );
}
