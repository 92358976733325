import { useState } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  ListSubheader,
  Stack,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  FormLabel,
} from "@mui/material";
import { dialogActions, selectDialog } from "../features/ui/dialogsSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectUsers } from "../features/users/usersSlice";
import { AuditService } from "../services";
import { showNotification } from "../features/ui/notificationSlice";
import FindingsService from "../services/FindingsService";

function TransferAuditorOrResponsibleUserDialog() {
  const open = useSelector(selectDialog).transferAuditorOrResponsibleUser;
  const users = useSelector(selectUsers).users;
  const [oldUser, setOldUser] = useState('');
  const [newUser, setNewUser] = useState('');
  const [auditResponsibility, setAuditResponsibility] = useState(false);
  const [findingResponsibility, setFindingResponsibility] = useState(false);
  const [auditor, setAuditor] = useState(false);
  const error = !auditResponsibility && !auditor && !findingResponsibility;
  const dispatch = useDispatch();

  function hideDialog() {
    dispatch(dialogActions.toggleTransferAuditorOrResponsibleUser());
  }

  function handleSubmit(e) {
    e.preventDefault();
    const oldUserName = users.find((u) => u.Id == oldUser).LongName;
    const newUserName = users.find((u) => u.Id == newUser).LongName;
    if (auditResponsibility) {
      AuditService.transferAuditInchargeUserOrAuditor(
        oldUser,
        newUser,
        "INCHARGEAUDIT",
      );
    }
    if (auditor) {
      AuditService.transferAuditInchargeUserOrAuditor(
        oldUser,
        newUser,
        "AUDITORAUDIT",
      );
    }
    if (findingResponsibility) {
      FindingsService.transferFindingInchargeUser(oldUser, newUser)
    }
    showNotification(
      dispatch,
      `Transfer edildi: ${oldUserName} -> ${newUserName}`,
    );
    hideDialog();
  }
  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Denetçiliği veya Sorumluluğu Transfer Et</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Stack gap={2}>
            <FormControl fullWidth required>
              <InputLabel>Eski Kullanıcı</InputLabel>
              <Select
                size="small"
                value={oldUser}
                label="Eski Kullanıcı"
                onChange={(e) => setOldUser(e.target.value)}
              >
                {users.map((user) => {
                  return (
                    <MenuItem key={user.Id} value={user.Id}>
                      {user.LongName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl fullWidth required>
              <InputLabel>Yeni Kullanıcı</InputLabel>
              <Select
                size="small"
                value={newUser}
                label="Yeni Kullanıcı"
                onChange={(e) => setNewUser(e.target.value)}
              >
                {users.map((user) => {
                  return (
                    <MenuItem key={user.Id} value={user.Id}>
                      {user.LongName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl error={error} required>
              <FormLabel component="legend">En az birini seçiniz:</FormLabel>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={findingResponsibility}
                    onChange={(e) => setFindingResponsibility(e.target.checked)}
                  />
                }
                label="Bulgu Sorumlulukları"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={auditResponsibility}
                    onChange={(e) => setAuditResponsibility(e.target.checked)}
                  />
                }
                label="Denetim Sorumlulukları"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={auditor}
                    onChange={(e) => setAuditor(e.target.checked)}
                  />
                }
                label="Denetçilikler"
              />
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={hideDialog}>
            Cancel
          </Button>
          <Button variant="outlined" type="submit" disabled={error}>
            Ok
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default function ModeratorActionsPage() {
  const dispatch = useDispatch();
  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <TransferAuditorOrResponsibleUserDialog />
      <List subheader={<ListSubheader>Moderator İşlemleri</ListSubheader>}>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() =>
              dispatch(dialogActions.toggleTransferAuditorOrResponsibleUser())
            }
          >
            <ListItemIcon>
              <ArrowForwardIcon />
            </ListItemIcon>
            <ListItemText primary="Denetçiliği veya Sorumluluğu Transfer Et" />
          </ListItemButton>
        </ListItem>
        <Divider />
      </List>
    </Box>
  );
}
