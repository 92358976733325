const initialState = {
  prompt: "",
  isOpen: false,
  proceed: null,
  cancel: null,
};

export default function modalDialogReducer(state = initialState, action) {
  switch (action.type) {
    case "modalDialog/show": {
      return action.payload;
    }
    default:
      return state;
  }
}

export function showConfirmDialog(dispatch, message) {
  const promise = new Promise((resolve, reject) => {
    dispatch({
      type: "modalDialog/show",
      payload: {
        isOpen: true,
        prompt: message,
        proceed: resolve,
        cancel: reject,
      },
    });
  });
  return promise.then(
    () => {
      dispatch({
        type: "modalDialog/show",
        payload: { isOpen: false, prompt: "", proceed: null, cancel: null },
      });
      return true;
    },
    () => {
      dispatch({
        type: "modalDialog/show",
        payload: { isOpen: false, prompt: "", proceed: null, cancel: null },
      });
      return false;
    },
  );
}

export const selectConfirmDialog = (state) => state.ui.confirmModal;
