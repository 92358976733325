import { useSelector } from "react-redux";
import {
  selectDepartmentsWithPeriodicScores,
  selectDepartmentsWithPeriodicScoresYear,
} from "../../features/departments/departmentsSlice";
import { getChildren, months, sumSubArrayValues } from "../../utils/commonFunctions";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { FormControlLabel, Paper, Switch } from "@mui/material";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import MyTitle from "../common/Title"

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const options = (clickHandler, stacked) => {
  return {
    onClick: clickHandler,
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
    },

    scales: {
        x: {
          stacked: stacked,
        },
        y: {
          stacked: stacked,
        },
      },
  }
};

const SubDepartments5SPoints = ({ parent, currentMonth, yearly, onDepartmentChange, currentYear }) => {
  const departmentsWithPeriodicScores = useSelector(
    selectDepartmentsWithPeriodicScoresYear,
  );
  const { t } = useTranslation()
  const ref = useRef(null)
  const [stacked, setStacked] = useState(true)
  const sumValues = sumSubArrayValues(departmentsWithPeriodicScores, "Id")
  const correctData = yearly ? sumValues : departmentsWithPeriodicScores[currentMonth]
  const data = getChildren(
    correctData,
    parent.Id
  );
  const self = correctData.find(
    (d) => d.Id == parent.Id
  );
  data.push(self);

  function calculateAverage(d) {
    const S1 = d.S1 || 0;
    const S2 = d.S2 || 0;
    const S3 = d.S3 || 0;
    const S4 = d.S4 || 0;
    const S5 = d.S5 || 0;
    const S6 = d.S6 || 0;

    return (S1 + S2 + S3 + S4 + S5 + S6) / (100 * 6) || 0;
  }

  function clickHandler(evt) {
    const points = ref.current.getElementsAtEventForMode(evt, 'nearest', { intersect: true }, true);

    if (points.length) {
      const firstPoint = points[0];
      const value = ref.current.data.datasets[firstPoint.datasetIndex].ids[firstPoint.index]
      onDepartmentChange(value, value==self.Id)
    }
  }

  function prepareData() {
    return data.map((d) => {
      return { id: d.Id, label: d.Adi, value: calculateAverage(d), ...d };
    });
  }

  function getChartData() {
    const chartData = prepareData().sort((a, b) => {
      if (a.Id == parent.Id || b.Id == parent.Id){
        if (a.Id == parent.Id ) return -1
        else return 1
      }
      if (a.value > b.value) return -1;
      else if (a.value < b.value) return 1;
      else return 0;
    });
    return {
      labels: chartData.map((d) => d.label),
      datasets: [
        {

          label: t('fiveSActivities.typesLower.tdys'),
          ids: chartData.map((d) => d.id),
          data: chartData.map((d) => d.CompletedAuditCnt? d.S1 / 600 / d.CompletedAuditCnt * (stacked?1:6): 0),
          maxBarThickness: 100,
        },
        {
          label: t('fiveSActivities.typesLower.orgn'),
          ids: chartData.map((d) => d.id),
          data: chartData.map((d) => d.CompletedAuditCnt? d.S2 / 600 / d.CompletedAuditCnt  * (stacked?1:6): 0),
          maxBarThickness: 100,
        },
        {
          label: t('fiveSActivities.typesLower.clns'),
          ids: chartData.map((d) => d.id),
          data: chartData.map((d) => d.CompletedAuditCnt? d.S3 / 600 / d.CompletedAuditCnt  * (stacked?1:6): 0),
          maxBarThickness: 100,
        },
        {
          label: t('fiveSActivities.typesLower.stdn'),
          ids: chartData.map((d) => d.id),
          data: chartData.map((d) => d.CompletedAuditCnt? d.S4 / 600 / d.CompletedAuditCnt * (stacked?1:6) : 0),
          maxBarThickness: 100,
        },
        {
          label: t('fiveSActivities.typesLower.dcln'),
          ids: chartData.map((d) => d.id),
          data: chartData.map((d) => d.CompletedAuditCnt? d.S5 / 600 / d.CompletedAuditCnt * (stacked?1:6) : 0),
          maxBarThickness: 100,
        },
        {
          label: t('fiveSActivities.typesLower.isg'),
          ids: chartData.map((d) => d.id),
          data: chartData.map((d) => d.CompletedAuditCnt? d.S6 / 600 / d.CompletedAuditCnt * (stacked?1:6) : 0),
          maxBarThickness: 100,
        },

      ],
    };
  }
  const title = t(yearly ? "titles.subDep5SPointsYearly" : "titles.subDep5SPointsMonthly", {currentMonth: t(`months.${currentMonth}`)})

  return data.length ? (
    <Paper elevation={12}>
      <MyTitle title={title} month={currentMonth} year={currentYear} yearly={yearly}/>
      <FormControlLabel sx={{float: "right"}} control={<Switch defaultChecked value={stacked} onChange={(e)=>setStacked(e.target.checked)}/>} label={t('ui.stackedGraph')} />
      <Bar ref={ref} options={options(clickHandler, stacked)} data={getChartData()} />
    </Paper>
  ) : (
    <div> </div>
  );
};

export default SubDepartments5SPoints;
