import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useState } from "react";
import {
  selectParameters,
  updateParameter,
} from "../../features/settings/settingsSlice";
import { useDispatch, useSelector } from "react-redux";
import { FormControlLabel, Stack, Switch, TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DeleditableTextView from "../common/DeleditableTextView";
import { TabPanel } from "../../utils/commonFunctions";

const groupBy = (x, f) =>
  x.reduce((a, b, i) => ((a[f(b, i, x)] ||= []).push(b), a), {});

export default function ParametersTab() {
  const [value, setValue] = useState(0);
  const parameters = useSelector(selectParameters);
  const groupedParameters = groupBy(parameters, (p) => p.Grup);
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function renderParam(param) {
    const type = param.Tip;
    if (type === 0 || type === 1)
      return (
        <DeleditableTextView
          integerOnly={type === 1}
          label={param.Adi}
          value={param.Deger}
          onUpdate={(newValue) =>
            dispatch(updateParameter({ ...param, Deger: newValue }))
          }
        />
      );
    else if (type === 2)
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            value={param.Deger}
            label={param.Adi}
            inputFormat="DD/MM/YYYY HH:mm"
            onChange={(date) =>
              dispatch(updateParameter({ ...param, Deger: date }))
            }
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      );
    else
      return (
        <FormControlLabel
          control={
            <Switch
              checked={Boolean(Number(param.Deger))}
              onChange={(e) =>
                dispatch(
                  updateParameter({
                    ...param,
                    Deger: Number(e.target.checked).toString(),
                  }),
                )
              }
            />
          }
          label={param.Adi}
        />
      );
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange}>
          {Object.keys(groupedParameters).map((groupName, i) => (
            <Tab label={groupName} value={i} />
          ))}
        </Tabs>
      </Box>

      {Object.values(groupedParameters).map((group, i) => (
        <TabPanel currentTab={value} value={i}>
          <Stack gap={2}>{group.map((param) => renderParam(param))}</Stack>
        </TabPanel>
      ))}
    </Box>
  );
}
