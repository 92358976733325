import { MenuItem } from "@mui/material";
import { config } from "./config";
import * as React from "react";
import {
  gridFilteredSortedRowIdsSelector,
  gridVisibleColumnFieldsSelector,
  useGridApiContext,
} from "@mui/x-data-grid";
import { DateFormat } from "../../../utils/DateFormat";
import { jsPDF } from "jspdf";
import { font } from "./times-normal";
import { getImageUrl } from "../../../utils/commonFunctions";
import { useTranslation } from "react-i18next";

function getData(apiRef) {
  // Select rows and columns
  const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
  const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);

  // Format the data. Here we only keep the value
  const data = filteredSortedRowIds.map((id) => {
    const row = {};
    visibleColumnsField.forEach((field) => {
      row[field] = apiRef.current.getCellParams(id, field).value;
    });
    return row;
  });

  return data;
}

const getBase64FromUrl = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

export async function handleExportFindingsToPdf(
  filteredData,
  realData,
  appendToDoc,
  fileName,
) {
  const rows = filteredData.map((row) => {
    const readData = realData.find((d) => d.Id == row.Id);
    const mRow = {};
    for (const header of config.pdfHeaders) {
      if (header.dataKey == "SystemDate" && readData[header.dataKey] != null)
        mRow[header.dataKey] = DateFormat.dayMonthYear(
          readData[header.dataKey],
        );
      else if (
        header.dataKey == "DueDate" &&
        readData[header.dataKey] != null
      ) {
        var due = DateFormat.dayMonthYear(readData[header.dataKey]);
        var comp = readData["CompletedDate"]
          ? "\n" + DateFormat.dayMonthYear(readData[header.dataKey])
          : "";
        mRow[header.dataKey] = due + comp;
      } else if (header.dataKey == "InchargeUser" && readData["AuditorUser"])
        mRow[header.dataKey] =
          readData[header.dataKey] + "\n" + readData["AuditorUser"];
      else mRow[header.dataKey] = readData[header.dataKey];
    }
    return mRow;
  });

  let base64ValuesDictionary = {};
  await Promise.all(
    ["imagebefore", "imageafter"].map(async (imageColumnName) => {
      await Promise.all(
        rows.map(async (row) => {
          if (
            row[imageColumnName] != "" &&
            row[imageColumnName] != null &&
            row[imageColumnName] != undefined
          ) {
            const imageUrl = getImageUrl(row[imageColumnName]);
            var b64 = await getBase64FromUrl(imageUrl);
            b64 = b64.replace("text/html", "image/png");
            base64ValuesDictionary[row[imageColumnName]] = b64;
          }
        }),
      );
    }),
  );

  var doc = {};
  if (appendToDoc == null) doc = new jsPDF("l", "pt", "a4");
  else doc = appendToDoc;

  console.log(doc.getFontList());
  // generate auto table with body
  var y = 10;
  doc.setLineWidth(2);
  doc.setFont("times");
  doc.text(50, (y = y + 30), "BULGU LİSTESİ");
  doc.autoTable({
    columns: config.pdfHeaders,
    body: rows,
    startY: 70,
    theme: "grid",
    styles: {
      overflow: "linebreak",
      cellWidth: "auto",
      font: "times",
      fontSize: 8,
      cellPadding: 8,
      overflowColumns: "linebreak",
    },
    headStyles: {
      font: "times",
      fontStyle: "normal",
      lineWidth: 1,
      textColor: [0, 0, 0],
    },
    columnStyles: {
      1: { cellWidth: 80 },
      2: { cellWidth: 80 },
      3: { cellWidth: 100 },
      4: { cellWidth: 100 },
      5: { cellWidth: 100 },
      8: { cellWidth: 54 },
      9: { cellWidth: 54 },
    },
    rowStyles: {
      rowHeight: 54,
    },
    didDrawCell: async (data) => {
      if (
        data.section === "body" &&
        (data.column.dataKey === "imagebefore" ||
          data.column.dataKey === "imageafter")
      ) {
        var b64 = base64ValuesDictionary[data.cell.raw];
        if (b64)
          doc.addImage(b64, "PNG", data.cell.x + 2, data.cell.y + 2, 50, 50);
      }
    },
  });
  // save the data to this file
  console.log("start save");

  if (fileName) doc.save(fileName);
}

export function ExportPdf(props) {
  const apiRef = useGridApiContext();
  const { data } = props;
  const { hideMenu } = props;
  const { t } = useTranslation()

  return (
    <MenuItem
      onClick={() => {
        const filtered = getData(apiRef);
        handleExportFindingsToPdf(filtered, data, null, "Bulgu Listesi");
        // Hide the export menu after the export
        hideMenu?.();
      }}
    >
      {t('buttons.export2Pdf')}
    </MenuItem>
  );
}
