import { MAIN_URL } from "./ServiceConstants";
import axios from "axios";

async function hash(p) {
  console.log("hash password");
  return p;
}

// async function getHash(buffer, algo = "SHA-256") {
//   return crypto.subtle.digest(algo, buffer)
//     .then(hash => {
//       // here hash is an arrayBuffer, so we'll convert it to its hex version
//       let result = '';
//       const view = new DataView(hash);
//       for (let i = 0; i < hash.byteLength; i += 4) {
//         result += ('00000000' + view.getUint32(i).toString(16)).slice(-8);
//       }
//       return result;
//     });
// }

async function login(username, password) {
  sessionStorage.setItem("sequence", 0);
  const hashPassword = await hash(password);
  const credentials = { loginname: username, passwordhash: hashPassword };
  const adminRoles = ["YO", "FM", "MO"];
  return axios
    .post(MAIN_URL + "/BS/UserAuthenticate", credentials)
    .then((response) => {
      console.log(response);
      if (response) {
        if (response && response.LoginName != null) {
          response["roles"] = [];
          response["isAdmin"] = false;
          sessionStorage.setItem("user", JSON.stringify(response));
          axios
            .post(MAIN_URL + "/Quality/SetUsers", {
              dataobject: {
                Action: "A",
              },
            })
            .then((roles) => {
              let isAdmin = false;
              for (let index = 0; index < roles.length; index++) {
                const role = roles[index];
                if (adminRoles.includes(role.RoleCode)) {
                  isAdmin = true;
                }
              }
              response["roles"] = roles;
              response["isAdmin"] = isAdmin;
              sessionStorage.setItem("user", JSON.stringify(response));
              sessionStorage.setItem("sequence", "1");
            });
        }
      }
      return response;
    });
}

async function register(email, password, FullName, Phone, Title) {
  const NewPass = await hash(password);
  return axios.post(MAIN_URL + "/Quality/SetPortalAccountRequest", {
    dataobject: {
      email,
      NewPass,
      FullName,
      Phone,
      Title,
    },
  });
}

async function resetPassword(MailAddress, password, Type) {
  const Pass = await hash(password);
  return axios.post(MAIN_URL + "/QualityEX/SetUserRequest", {
    MailAddress,
    Pass,
    Type,
  });
}

function getUser() {
  return JSON.parse(sessionStorage.getItem("user"));
}

function logout() {
  sessionStorage.setItem("user", null);
}

function generateImageUrl(url) {
  const user = getUser();
  return `${MAIN_URL}/OrthoMagic/GetImage?ImageId=${url}&GUID=${user.SessionGUID}&UserId=${user.UserId}`;
}

const LoginService = {
  login,
  register,
  resetPassword,
  generateImageUrl,
  getUser,
  logout,
};

export default LoginService;
