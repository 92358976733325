import { Grid } from "@mui/material";
import AuditCardsComponent from "../components/MainActivity/Audit/AuditCardsComponent";
import AuditListComponent from "../components/MainActivity/Audit/AuditListComponent";
import AuditCalendarComponent from "../components/MainActivity/Audit/AuditCalendarComponent";
import { selectAuditView } from "../features/ui/auditViewSlice";
import { useSelector } from "react-redux";
import { selectAudits } from "../features/audits/auditsSlice";

function AuditsPage() {
  const { auditViewType } = useSelector(selectAuditView);
  const audits = useSelector(selectAudits);


  function getAudits() {
    if (auditViewType === "list") return <AuditListComponent audits={audits} />;
    else if (auditViewType === "card")
      return <AuditCardsComponent audits={audits} />;
    else return <AuditCalendarComponent audits={audits} />;
  }

  return (
    <Grid container alignItems="flex-end" justifyContent="center">
      {getAudits()}
    </Grid>
  );
}

export default AuditsPage;
