import { ConstructionSharp } from "@mui/icons-material";
import {
  Container,
  Button,
  Box,
  Typography,
  Divider,
  Fab,
} from "@mui/material";
import { Stack } from "@mui/system";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import "./report.css";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  Title,
} from "chart.js";
import { Radar } from "react-chartjs-2";
import { getRadarChartData } from "../../utils/commonFunctions";
import { useSelector } from "react-redux";
import { selectDepartments } from "../../features/departments/departmentsSlice";
import { selectSettings } from "../../features/settings/settingsSlice";
import { getImageUrl } from "../../utils/commonFunctions";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { createPDF } from "./ExportToPdf";
import FindingsTable from "../Findings/FindingsTable";

const options = {
  scale: {
    ticks: {
      beginAtZero: true,
      stepSize: 1,
    },
    r: {
      angleLines: {
        display: false,
      },
      suggestedMin: 0,
      suggestedMax: 5,
    },
  },
  plugins: {
    legend: {
      display: true,
    },
  },
};

ChartJS.register(
  Title,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
);

// Create Document Component
const AuditReport = ({ auditInfo, questions, findings }) => {
  const FiveSValues = getRadarChartData(auditInfo);
  const departments = useSelector(selectDepartments);
  const settings = useSelector(selectSettings);

  const CreateHeader = ({ auditInfo }) => {
    return (
      <Stack
        display="flex"
        justifyContent="space-between"
        direction="row"
        alignItems="center"
        alignContent="space-between"
        marginY={2}
      >
        <div>
          <img
            height={50}
            src={getImageUrl(settings.Logo)}
            alt={settings.FactoryName}
          />
        </div>
        <div>
          <Typography variant="h6" color="text.secondary">
            <b>Birim</b> <br /> {auditInfo.Department}
          </Typography>
        </div>
        <div>
          <Typography variant="h3"> 5S Denetim Raporu </Typography>
        </div>
        <div>
          <Typography variant="body2" color="text.secondary">
            <b>Denetim No:</b> {auditInfo.ActivityNo}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <b>İlk Yayın Tarihi:</b>{" "}
            {new Date(auditInfo.CompleteDate).toLocaleDateString()}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <b>Denetçi:</b> {auditInfo.Auditor}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <b>Sorumlu:</b> {auditInfo.InchargeUser}
          </Typography>
        </div>
      </Stack>
    );
  };

  const CreateScoreTable = ({ score }) => {
    let SType = "S1";
    let color = "red";
    if (score > 4) {
      color = "green";
      SType = "S5";
    } else if (score > 3) {
      color = "lightgreen";
      SType = "S4";
    } else if (score > 2) {
      color = "yellow";
      SType = "S3";
    } else if (score > 1) {
      color = "orange";
      SType = "S2";
    }

    return (
      <div>
        <Typography variant="h6">S PUANI</Typography>

        <div
          style={{
            borderStyle: "solid",
            borderWidth: "1px",
            position: "relative",
            width: 500,
            height: 300,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              width: "10%",
              height: "100%",
              backgroundColor: color,
            }}
          >
            <Typography>{SType}</Typography>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              left: "10%",
              top: "0",
              width: "90%",
              height: "16%",
              backgroundColor: "red",
            }}
          >
            <Typography>S1: DAHA YAPACAK ÇOK İŞ VAR</Typography>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              left: "10%",
              top: "16%",
              width: "90%",
              height: "16%",
              backgroundColor: "orange",
            }}
          >
            <Typography>S2: "S" ORADAN GEÇMİŞ</Typography>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              left: "10%",
              top: "32%",
              width: "90%",
              height: "16%",
              backgroundColor: "yellow",
            }}
          >
            <Typography>S3: BİR ŞEYLER KIMILDIYOR GİBİ</Typography>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              left: "10%",
              top: "48%",
              width: "90%",
              height: "16%",
              backgroundColor: "lightgreen",
            }}
          >
            <Typography>S4: BİRAZ DAHA GAYRET</Typography>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              left: "10%",
              top: "64%",
              width: "90%",
              height: "16%",
              backgroundColor: "green",
            }}
          >
            <Typography>S5: ARTIK ZİRVEDEYİZ</Typography>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              top: "80%",
              width: "100%",
              height: "20%",
              backgroundColor: color,
            }}
          >
            <Typography>S Puanı: {score}</Typography>
          </div>

          <div
            style={{
              borderStyle: "solid",
              borderWidth: "1px",
              position: "absolute",
              left: "10%",
              width: "90%",
              height: "80%",
            }}
          ></div>
        </div>
      </div>
    );
  };

  const radarData = () => {
    const departmentScores = getRadarChartData(
      departments.find((item) => item.Id == auditInfo.DepartmentId),
    );
    const ds = {
      label: "Departman: " + auditInfo.Department,
      data: departmentScores.datasets[0].data,
      backgroundColor: "rgba(0, 255, 132, 0.2)",
      borderColor: "rgba(0, 255, 132, 1)",
      borderWidth: 1,
    };
    FiveSValues.datasets.push(ds);
    return FiveSValues;
  };

  return (
    <div>
      <Fab
        sx={{
          position: "fixed",
          bottom: 10,
          right: 10,
        }}
        variant="extended"
        color="primary"
        onClick={() =>
          createPDF(
            "#pdf",
            "l",
            auditInfo.Department + " Denetim Raporu No " + auditInfo.Id,
            findings,
          )
        }
      >
        <PictureAsPdfIcon /> PDF olarak kaydet
      </Fab>

      <div>
        <Stack paddingY={5} paddingX={10} id="pdf">
          <CreateHeader auditInfo={auditInfo} />
          <Divider color="black" sx={{ margin: 3 }}></Divider>
          <Stack direction="row" display="flex" justifyContent="space-evenly">
            <div>
              <table>
                <tr>
                  <th>Grup</th>
                  <th>Soru</th>
                  <th>Puan</th>
                </tr>
                {[
                  "TERTIP",
                  "DUZEN",
                  "TEMIZLIK",
                  "STANDARTLASTIRMA",
                  "DISIPLIN",
                  "ISG",
                ].map((val, key) => {
                  return (
                    <>
                      <tr key={key} style={{ backgroundColor: "LightSalmon" }}>
                        <td>
                          <Typography>{val}</Typography>
                        </td>
                        <td></td>
                        <td>{FiveSValues.datasets[0].data[key]}</td>
                      </tr>

                      {questions
                        .filter((item) => item.FiveSType == val)
                        .map((val, key) => {
                          let c =
                            val.Response == -1
                              ? "GainsBoro"
                              : val.Response < 300
                              ? "pink"
                              : "white";
                          return (
                            <tr key={key} style={{ backgroundColor: c }}>
                              <td>{key + 1}</td>
                              <td>{val.question}</td>
                              <td>
                                {val.Response == -1
                                  ? "N/A"
                                  : val.Response / 100}
                              </td>
                            </tr>
                          );
                        })}
                    </>
                  );
                })}
              </table>
            </div>
            <Stack>
              <div style={{ paddingLeft: "125px", marginBottom: "20px" }}>
                <CreateScoreTable score={auditInfo.Score / 100} />
              </div>

              {departments.length > 0 && (
                <div style={{ paddingLeft: "125px" }}>
                  <Divider color="black" sx={{ margin: 1 }}></Divider>
                  <Typography variant="h6">RADAR GRAFİĞİ</Typography>

                  <Radar data={radarData()} options={options} />
                </div>
              )}
            </Stack>
          </Stack>
        </Stack>

        <FindingsTable data={findings} />
      </div>
    </div>
  );
};

export default AuditReport;
