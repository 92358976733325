import {
  Autocomplete,
  FormHelperText,
  Stack,
  Switch,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectDepartments } from "../../features/departments/departmentsSlice";
import { fetchSubDepUsers, selectUsers } from "../../features/users/usersSlice";
import PrettyDepartment from "./PrettyDepartment";
import { useTranslation } from "react-i18next";
import { Trans } from 'react-i18next'

export default function DepartmentUserSelect(props) {
  const departments = useSelector(selectDepartments);
  const [selectedDep, setSelectedDep] = useState(null);
  const [auditable, setAuditable] = useState(true);
  const [showSubDepUsers, setShowSubDepUsers] = useState(false);
  const departmentOptions = auditable
    ? departments.filter((d) => d.Auditable)
    : departments;
  const readOnlyDepartment = props.departmentId !== undefined;
  const readOnlyUser = props.readOnlyUser;
  const disabled = !selectedDep;
  const { t } = useTranslation()

  const { users, subDepUsers } = useSelector(selectUsers);
  const userListGiven = Boolean(props.inChargeUserList);

  function getUsers(userList) {
    const userIds = userList.map(Number);
    return users.filter((u) => userIds.includes(u.Id));
  }
  const data = userListGiven
    ? getUsers(props.inChargeUserList)
    : showSubDepUsers
    ? subDepUsers
    : users;
  const dispatch = useDispatch();

  useEffect(() => {
    if (showSubDepUsers && !subDepUsers.find((u) => u.Id === props.valueUser)) {
      props.onChangeUser("");
    }
  }, [subDepUsers, showSubDepUsers]);

  useEffect(() => {
    if (selectedDep) dispatch(fetchSubDepUsers(selectedDep.Id));
    else setShowSubDepUsers(false);
  }, [selectedDep]);

  return (
    <Stack
      sx={{ width: "100%" }}
      direction={props.horizontal ? "row" : "column"}
      gap={2}
    >
      <Stack sx={{ width: "100%" }}>
        <Stack sx={{ alignItems: "flex-end" }} direction="row">
          <FormControl fullWidth>
            <Autocomplete
              fullWidth
              options={[...departmentOptions].sort((a, b) => {
                if (a.Adi > b.Adi) return 1;
                else if (a.Adi < b.Adi) return -1;
                else return 0;
              })}
              getOptionLabel={(d) => d.Adi}
              value={props.valueDepartment}
              readOnly={readOnlyDepartment}
              onChange={(event, newValue) => {
                props.onChangeDepartment(event, newValue);
                setSelectedDep(newValue);
              }}
              renderOption={(props, option, { selected }) => {
                return (
                  <li {...props}>
                    <PrettyDepartment department={option} />
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required={props.required ? true : false}
                  error={props.error && !props.valueDepartment}
                  label={t('ui.department')}
                  variant="standard"
                />
              )}
            />
          </FormControl>
          {!readOnlyDepartment && (
            <Switch
              checked={auditable}
              onChange={(event) => setAuditable(event.target.checked)}
            />
          )}
        </Stack>
        {!readOnlyDepartment && (
          <FormHelperText>
            <Trans i18nKey={auditable ? 'ui.listingAuditableDepartments' : 'ui.listingAllDepartments'}/>
          </FormHelperText>
        )}
      </Stack>
      <Stack sx={{ width: "100%" }}>
        <Stack sx={{ alignItems: "flex-end" }} direction="row">
          <FormControl fullWidth error={props.error && !props.valueUser}>
            <InputLabel>{t('ui.responsibleUser')}</InputLabel>
            <Select
              size="small"
              value={props.valueUser}
              label={t('ui.responsibleUser')}
              readOnly={readOnlyUser}
              required={props.required ? true : false}
              onChange={(e) => props.onChangeUser(e.target.value)}
            >
              <MenuItem key={null} value="">
                None
              </MenuItem>
              {[...data]
                .sort((a, b) => {
                  if (a.LongName > b.LongName) return 1;
                  else if (a.LongName < b.LongName) return -1;
                  else return 0;
                })
                .map((user) => {
                  return (
                    <MenuItem key={user.Id} value={user.Id}>
                      {user.LongName}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          {!readOnlyUser && !userListGiven && (
            <Switch
              checked={showSubDepUsers}
              disabled={disabled}
              onChange={(event) => setShowSubDepUsers(event.target.checked)}
            />
          )}
        </Stack>
        {!readOnlyUser && (
          <FormHelperText>
            <Trans i18nKey={userListGiven
                ? 'ui.listingUserList'
                : showSubDepUsers
                ? 'ui.listingSubDepUsers'
                : "ui.listingAllUsers"}
            />
          </FormHelperText>
        )}
      </Stack>
    </Stack>
  );
}
