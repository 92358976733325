import { useSelector } from "react-redux";
import { selectDepartmentsWithPeriodicScoresYear } from "../../features/departments/departmentsSlice";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Paper } from "@mui/material";
import { Colors } from 'chart.js';
import { months } from "../../utils/commonFunctions";
import { useTranslation } from "react-i18next";
import MyTitle from "../common/Title"

ChartJS.register(
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Colors
);

const options = (/* title */) => {
  return {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      // title: {
      //   display: true,
      //   text: title,
      // },
    },
    scales: {
      y: {
        max: 5,
      },
    },
  }
};

const YearlyGraph = ({ department, currentYear }) => {
  const { t } = useTranslation()
  const departmentsWithPeriodicScores = useSelector(
    selectDepartmentsWithPeriodicScoresYear,
  );

  const data = departmentsWithPeriodicScores.map((year) =>
    year.find((d) => d.Id == department.Id),
  );

  function calculateAverage(d) {
    const S1 = d.S1 || 0;
    const S2 = d.S2 || 0;
    const S3 = d.S3 || 0;
    const S4 = d.S4 || 0;
    const S5 = d.S5 || 0;
    const S6 = d.S6 || 0;

    return (S1 + S2 + S3 + S4 + S5 + S6) / (100 * 6) || 0;
  }

  function prepareData() {
    return data.map((d, i) => {
      return { label: t(`months.${i}`), value: calculateAverage(d), ...d };
    });
  }

  function getChartData() {
    const chartData = prepareData();
    const options = {tension: 0.4, pointRadius: 0, pointHitRadius: 10}
    return {
      labels: chartData.map((d) => d.label),
      datasets: [
        {

          label: t('fiveSActivities.typesLower.tdys'),
          data: chartData.map((d) => d.CompletedAuditCnt? d.S1 / 100 / d.CompletedAuditCnt : 0),
          ...options
        },
        {
          label: t('fiveSActivities.typesLower.orgn'),
          data: chartData.map((d) => d.CompletedAuditCnt? d.S2 / 100 / d.CompletedAuditCnt : 0),
          ...options
        },
        {
          label: t('fiveSActivities.typesLower.clns'),
          data: chartData.map((d) => d.CompletedAuditCnt? d.S3 / 100 / d.CompletedAuditCnt : 0),
          ...options
        },
        {
          label: t('fiveSActivities.typesLower.stdn'),
          data: chartData.map((d) => d.CompletedAuditCnt? d.S4 / 100 / d.CompletedAuditCnt : 0),
          ...options
        },
        {
          label: t('fiveSActivities.typesLower.dcln'),
          data: chartData.map((d) => d.CompletedAuditCnt? d.S5 / 100 / d.CompletedAuditCnt : 0),
          ...options
        },
        {
          label: t('fiveSActivities.typesLower.isg'),
          data: chartData.map((d) => d.CompletedAuditCnt? d.S6 / 100 / d.CompletedAuditCnt : 0),
          ...options
        },
        {
          label: t('ui.average'),
          data: chartData.map((d) => d.CompletedAuditCnt? d.value / d.CompletedAuditCnt : 0),
          ...options
        },
      ],
    };
  }
  const title = t('titles.yearlyGraph', {currentDepartment: department.Adi})

  return data.length ? (
    <Paper elevation={12}>
      <MyTitle title={title} year={currentYear} yearly={true} />
      <Line options={options()} data={getChartData()} />
    </Paper>
  ) : (
    <div> </div>
  );
};

export default YearlyGraph;
