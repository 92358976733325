import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LoginService } from "../services";
import store from "../store";
import { deinitialize } from "../features/common";

export default function LogoutPage() {
  const navigate = useNavigate();
  useEffect(() => {
    LoginService.logout();

    store.dispatch(deinitialize());
    setTimeout(() => {
      navigate("/");
    }, 1000);
  });
  return <div>Good bye!</div>;
}
