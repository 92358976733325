import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Stack,
  TextField,
  useTheme,
  useMediaQuery,
  Typography,
  Rating,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { getImageUrl } from "../../utils/commonFunctions";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import FindingActionsButton from "./FindingActions";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch, useSelector } from "react-redux";
import { dialogActions, selectDialog } from "../../features/ui/dialogsSlice";
import { selectSettings } from "../../features/settings/settingsSlice";
import { useEffect, useState } from "react";
import DeleditableTextView from "../common/DeleditableTextView";
import { updateFinding } from "../../features/findings/findingsSlice";
import { showConfirmDialog } from "../../features/ui/confirmDialogSlice";
import AuditService from "../../services/AuditService";
import EditableSelect from "../common/EditableSelect";
import { selectUsers } from "../../features/users/usersSlice";
import { userHasPermission } from "../../utils/authFunctions";
import { LoginService } from "../../services";
import { isFindingApproved } from "../../utils/commonFunctions";

export function QuestionDetails({ questionId }) {
  const [mainQuestion, setMainQuestion] = useState([]);
  const [subQuestions, setSubQuestions] = useState([]);

  useEffect(() => {
    AuditService.getAuditQuestionById(questionId).then((response) => {
      setMainQuestion(response.Table);
      setSubQuestions(response.Table1);
    });
  }, []);
  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between">
        <h2>{mainQuestion[0]?.question}</h2>
        <h2>Skor: {mainQuestion[0]?.Response / 100}</h2>
      </Stack>
      <ul>
        {subQuestions.map((q) => (
          <li>
            <Stack direction="row" justifyContent="space-between">
              <FormLabel>{q.question}</FormLabel>
              <FormControl key={q.Id}>
                <RadioGroup
                  row
                  value={q.Response ? q.Response.toString() : "0"}
                  readOnly
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Evet"
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Hayır"
                  />
                  <FormControlLabel value="3" control={<Radio />} label="N/A" />
                </RadioGroup>
              </FormControl>
            </Stack>
          </li>
        ))}
      </ul>
    </Stack>
  );
}
export function BeforeAfterDetails(props) {
  const completed = props.CompletedDate;
  const theme = useTheme();
  const userList = useSelector(selectUsers).users;
  const dispatch = useDispatch();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const updatePermission = userHasPermission("update");

  async function handleUpdateDueDate(dueDate) {
    const confirmed = await showConfirmDialog(
      dispatch,
      "Termin tarihini değiştirmek istediğinize emin misiniz?",
    );
    if (confirmed) {
      dispatch(updateFinding({ ...props, DueDate: dueDate }));
    }
  }

  function renderTextField(
    data,
    label,
    multiline,
    updatedFieldName,
    canThisUserUpdate,
    approved,
  ) {
    return multiline ? (
      <DeleditableTextView
        label={label}
        multiline={multiline}
        onUpdate={
          (updatePermission || canThisUserUpdate) && !approved
            ? (newValue) =>
                dispatch(
                  updateFinding({ ...props, [updatedFieldName]: newValue }),
                )
            : undefined
        }
        value={data}
      />
    ) : (
      <TextField
        fullWidth
        label={label}
        multiline={multiline}
        rows={4}
        value={data}
      />
    );
  }

  function renderDetails(
    data,
    isAuditor,
    isInchargeUser,
    approved,
    halfWidth = true,
  ) {
    const imageUrl = getImageUrl(data.image, false);
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack
          gap={2}
          sx={{
            boxShadow: 1,
            borderRadius: 2,
            border: 2,
            borderColor:
              data.isBefore || data.isBefore === undefined
                ? "error.main"
                : "success.main",
            p: smallScreen ? 1 : 2,
            width: !smallScreen && halfWidth ? "50%" : "97%",
          }}
        >
          <h2>
            {data.isBefore
              ? "Öncesi"
              : data.isBefore === false
              ? "Sonrası"
              : ""}
          </h2>
          <DesktopDatePicker
            readOnly
            inputFormat="DD/MM/YYYY"
            label={"Oluşturulma Tarihi"}
            value={new Date(data.createdAt)}
            renderInput={(params) => <TextField {...params} />}
          />
          <DesktopDatePicker
            readOnly={!data.isBefore || !(updatePermission || isAuditor)}
            inputFormat="DD/MM/YYYY"
            label={data.isBefore ? "Termin Tarihi" : "Tamamlanma Tarihi"}
            onChange={(newDate) => handleUpdateDueDate(newDate)}
            value={new Date(data.date)}
            renderInput={(params) => <TextField {...params} />}
          />

          {data.fiveSType && (
            <TextField fullWidth label="5S Türü" value={data.fiveSType} />
          )}
          {data.department && (
            <TextField fullWidth label="Departman" value={data.department} />
          )}
          <EditableSelect
            value={data.userId}
            label={data.isBefore ? "Sorumlu" : "Bitiren Kişi"}
            list={userList}
            listKey="Id"
            listValue="LongName"
            onUpdate={
              (updatePermission || isAuditor)&& data.isBefore && !approved
                ? (newValue) =>
                    dispatch(
                      updateFinding({
                        ...props,
                        [data.isBefore ? "InchargeUserId" : "CompletedUserId"]:
                          newValue,
                      }),
                    )
                : undefined
            }
          >
            {renderTextField(
              data.user,
              data.isBefore ? "Sorumlu" : "Bitiren Kişi",
              false,
            )}
          </EditableSelect>
          {data.note ? (
            <Stack direction="row" gap={1}>
              {renderTextField(
                data.text,
                data.isBefore ? "Bulgu" : "Aksiyon",
                true,
                data.isBefore ? "Dsc" : "DscOk",
                data.isBefore ? isAuditor : isInchargeUser,
                approved,
              )}
              {<ArrowForwardIcon />}
              {renderTextField(
                data.note,
                "Transfer Notu",
                data.isBefore,
                "Note",
                data.isBefore ? isAuditor : isInchargeUser,
                approved,
              )}
            </Stack>
          ) : (
            renderTextField(
              data.text,
              data.isBefore ? "Bulgu" : "Aksiyon",
              true,
              data.isBefore ? "Dsc" : "DscOk",
              data.isBefore ? isAuditor : isInchargeUser,
              approved,
            )
          )}
          <img
            src={imageUrl}
            alt="Finding detail"
            style={{
              width: "100%",
              maxHeight: "400px",
              "object-fit": "contain",
            }}
          />
        </Stack>
      </LocalizationProvider>
    );
  }
  const currentUserID = LoginService.getUser()?.UserId;
  const isAuditor = currentUserID == props.AuditorId;
  const isInchargeUser = currentUserID == props.InchargeUserId;
  const approved = isFindingApproved(props);
  if (completed) {
    return (
      <Stack
        direction={smallScreen ? "column" : "row"}
        gap={2}
        justifyContent="space-between"
      >
        {renderDetails(
          {
            text: props.Dsc,
            isBefore: true,
            note: props.Note,
            fiveSType: props.FiveSType,
            department: props.Department,
            user: props.InchargeUser,
            userId: props.InchargeUserId,
            createdAt: props.SystemDate,
            image: props.imagebefore,
            date: props.DueDate,
          },
          isAuditor,
          isInchargeUser,
          approved,
        )}
        {renderDetails(
          {
            text: props.DscOk,
            isBefore: false,
            user: props.CompletedUser,
            fiveSType: props.FiveSType,
            department: props.Department,
            userId: props.CompletedUserId,
            createdAt: props.SystemDate,
            image: props.imageafter,
            date: props.CompletedDate,
          },
          isAuditor,
          isInchargeUser,
          approved,
        )}
      </Stack>
    );
  }
  return renderDetails(
    {
      isBefore: true,
      text: props.Dsc,
      note: props.Note,
      fiveSType: props.FiveSType,
      department: props.Department,
      user: props.InchargeUser,
      userId: props.InchargeUserId,
      createdAt: props.SystemDate,
      image: props.imagebefore,
      date: props.DueDate,
    },
    isAuditor,
    isInchargeUser,
    approved,
    false,
  );
}

export default function DetailsDialog() {
  const currentFinding = useSelector(selectDialog).currentFinding;
  const open = useSelector(selectDialog).findingDetail;
  const settings = useSelector(selectSettings);
  const dispatch = useDispatch();

  function hideDialog() {
    dispatch(dialogActions.toggleFindingDetail());
  }

  return (
    <Dialog open={open} maxWidth="lg" fullWidth>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Stack gap={1} alignItems="center" direction="row">
            <img
              height={50}
              src={getImageUrl(settings.Logo)}
              alt={settings.FactoryName}
            />
            <Typography variant="h6">
              Bulgu Detayları (Bulgu No: {currentFinding?.Id})
            </Typography>
          </Stack>
          {currentFinding && (
            <FindingActionsButton
              finding={currentFinding}
              inDetailsDialog={true}
            />
          )}
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack>
          {currentFinding?.ActivityQuestionId ? (
            <QuestionDetails questionId={currentFinding.ActivityQuestionId} />
          ) : (
            ""
          )}
          <BeforeAfterDetails {...currentFinding}></BeforeAfterDetails>
        </Stack>
      </DialogContent>

      <DialogActions data-html2canvas-ignore="true">
        <Button variant="outlined" color="secondary" onClick={hideDialog}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
