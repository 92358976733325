import { MAIN_URL } from "./ServiceConstants";
import axios from "axios";

const deviationURL = MAIN_URL + "/Quality/SetSapma"

async function getDeviations() {
  return axios.post(deviationURL, {
    dataobject: {
      Action: "S",
    },
  });
}


async function createDeviation(startDate, endDate, departmentId, fiveSType, deviationType, note) {
  return axios.post(deviationURL, {
    dataobject: {
      Action: "I",
      StartDate : startDate,
      EndDate : endDate,
      DepartmentId: departmentId,
      FiveSType : fiveSType,
      SapmaType : deviationType,
      Note : note
    },
  });
}


async function updateDeviation(id, data) {
  return axios.post(deviationURL, {
    dataobject: {
      Action: "U",
      Id: id,
      ...data
    },
  });
}

async function approveDeviation(id) {
  return axios.post(deviationURL, {
    dataobject: {
      Action: "A",
      Id: id,
    },
  });
}


async function denyDeviation(id) {
  return axios.post(deviationURL, {
    dataobject: {
      Action: "N",
      Id: id,
    },
  });
}

async function deleteDeviation(id) {
  return axios.post(deviationURL, {
    dataobject: {
      Action: "D",
      Id: id,
    },
  });
}
const DeviationService = {
  getDeviations,
  updateDeviation,
  createDeviation,
  approveDeviation,
  denyDeviation,
  deleteDeviation
};

export default DeviationService;
