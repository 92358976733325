import { useState } from "react";
import { Link } from "react-router-dom";
import MaterialLink from "@mui/material/Link";

import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import LoginService from "../../services/LoginServices";
import logo from "../../resources/Navbar/logo_navbar_2.jpeg";
import footer from "../../resources/Navbar/ydm_powered_by.png"

import { getImageUrl } from "../../utils/commonFunctions";
import { selectUsers } from "../../features/users/usersSlice";
import { useSelector } from "react-redux";
import { Stack } from "@mui/material";
import { useContext } from "react";
import { userHasPermission } from "../../utils/authFunctions";
import { selectRoleUsers } from "../../features/roleUsers/roleUsersSlice";
import { selectDepartments } from "../../features/departments/departmentsSlice";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../utils/Language";

const languageOptions = { tr: "Türkçe", en: "English" };

export function LanguageSelector() {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t, i18n: {changeLanguage, language, options} } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(language)
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (newLang) => {
    setCurrentLanguage(newLang);
    changeLanguage(newLang);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <List>
        <ListItem onClick={handleClickListItem}>
          <ListItemText
            primary={`${t('navbar.language')}: ${t('navbar.currentLanguage')}`}
          />
        </ListItem>
      </List>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {Object.keys(options.resources).map((key, index) => (
          <MenuItem
            key={key}
            selected={currentLanguage === key}
            onClick={() => handleMenuItemClick(key)}
          >
            {key}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

const drawerWidth = 240;

export default function ResponsiveDrawer(props) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const users = useSelector(selectUsers).users;
  const roleUsers = useSelector(selectRoleUsers)
  const departments = useSelector(selectDepartments)
  const currentUser = users.find((u) => u.Id === LoginService.getUser().UserId);
  const canViewModeratorPages = userHasPermission("viewModeratorPages");

  const { t } = useTranslation()
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  function getUserRoles() {
    const roles = roleUsers.filter(r=>r.LoginName == currentUser?.LoginName)
    const userRoles = []
    for (const role of roles) {
      const department = departments.find(d=>d.Id == role.DepartmentId)
      if (department) {
        userRoles.push(`${department.Adi} - ${role.Adi}`)
      }else userRoles.push(role.Adi)
    }
    return <div>
      {userRoles.map((v, i)=> <div key={i}>{v}<br/></div>)}
    </div>
  }

  const drawer = (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Toolbar />
      <Divider />
      <List
        sx={{
          flexGrow: 1,
          flexDirection: "column",
          display: { xs: "flex", md: "flex" },
        }}
      >
        <ListItem key="home" disablePadding>
          <ListItemButton component={Link} to="/" onClick={handleDrawerToggle}>
            <ListItemText primary={t('navbar.home')} />
          </ListItemButton>
        </ListItem>
        <ListItem key="fiveS" disablePadding>
          <ListItemButton
            component={Link}
            to="/5SActivities"
            onClick={handleDrawerToggle}
          >
            <ListItemText primary={t('navbar.5SActivities')} />
          </ListItemButton>
        </ListItem>
        {canViewModeratorPages && (
          <div key="mydiv1">
            <ListItem key="periodicAudits" disablePadding>
              <ListItemButton
                component={Link}
                to="/periodicaudits"
                onClick={handleDrawerToggle}
              >
                <ListItemText primary={t('navbar.periodicAudits')} />
              </ListItemButton>
            </ListItem>
          </div>
        )}
            <ListItem key="reports" disablePadding>
              <ListItemButton
                component={Link}
                to="/reports"
                onClick={handleDrawerToggle}
              >
                <ListItemText primary={t('navbar.reports')} />
              </ListItemButton>
            </ListItem>
        <Divider />
        <ListItem key="findings" disablePadding>
          <ListItemButton
            component={Link}
            to="/findings"
            onClick={handleDrawerToggle}
          >
            <ListItemText primary={t('navbar.findings')} />
          </ListItemButton>
        </ListItem>
        <ListItem key="approvedfindings" disablePadding>
          <ListItemButton
            component={Link}
            to="/approvedfindings"
            onClick={handleDrawerToggle}
          >
            <ListItemText primary={t('navbar.approvedFindings')} />
          </ListItemButton>
        </ListItem>
        {canViewModeratorPages && (<ListItem key="unapprovedjobs" disablePadding>
          <ListItemButton
            component={Link}
            to="/unapprovedjobs"
            onClick={handleDrawerToggle}
          >
            <ListItemText primary={t('navbar.unapprovedJobs')} />
          </ListItemButton>
        </ListItem>
        )}
        <Divider />
        <ListItem key="calendar" disablePadding>
          <ListItemButton
            component={Link}
            to="/calendar"
            onClick={handleDrawerToggle}
          >
            <ListItemText primary={t('navbar.calendar')} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List
        sx={{
          flexGrow: 0,
          flexDirection: "column",
          display: { xs: "flex", md: "flex" },
        }}
      >
        <LanguageSelector />
        <ListItem key="settings" disablePadding>
          <ListItemButton
            component={Link}
            to="/settings"
            onClick={handleDrawerToggle}
          >
            <ListItemText primary={t('navbar.settings')} />
          </ListItemButton>
        </ListItem>

        <img
              src={footer}
              alt="Powered by YDM"
              height={40}
              width={160}
              style={{  margin: 'auto' }}
            ></img>
      </List>
    </div>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        color="warning"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleDrawerToggle}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <MaterialLink
            variant="h5"
            noWrap
            component={Link}
            to="/"
            color="inherit"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "flex" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              textDecoration: "none",
            }}
          >
            <img
              src={logo}
              alt="YDM"
              height={50}
              sx={{ display: { xs: "flex", md: "flex" }, mr: 1 }}
            ></img>
          </MaterialLink>
          <Box sx={{ flexGrow: 0 }}>
            <Stack gap={1} alignItems="center" direction="row">
              <Typography sx={{ display: { xs: "none", md: "flex" } }}>
                {currentUser?.LongName}
              </Typography>
              <Tooltip title={getUserRoles()}>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    alt={currentUser?.LongName}
                    src={getImageUrl(currentUser?.Photo)}
                  />
                </IconButton>
              </Tooltip>
            </Stack>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem
                key="current"
                color="inherit"
                component={Link}
                to="/profile"
                onClick={handleCloseUserMenu}
                sx={{ display: { xs: "flex", md: "none" } }}
              >
                {currentUser?.LongName}
              </MenuItem>
              <Divider key="divider1" sx={{ display: { xs: "flex", md: "none" } }} />
              <MenuItem
                key="profile"
                color="inherit"
                component={Link}
                to="/profile"
                onClick={handleCloseUserMenu}
              >
                {t('navbar.profile')}
              </MenuItem>
                  <MenuItem
                    key="users"
                    color="inherit"
                    component={Link}
                    to="/users"
                    onClick={handleCloseUserMenu}
                  >
                    {t('navbar.users')}
                  </MenuItem>
                  <MenuItem
                    key="departments"
                    color="inherit"
                    component={Link}
                    to="/departments"
                    onClick={handleCloseUserMenu}
                  >
                    {t('navbar.departments')}
                  </MenuItem>
              {canViewModeratorPages && (
                <div key="mydiv2">
                  <MenuItem
                    key="roles"
                    color="inherit"
                    component={Link}
                    to="/roles"
                    onClick={handleCloseUserMenu}
                  >
                    {t('navbar.roles')}
                  </MenuItem>
                  <MenuItem
                    key="qp"
                    color="inherit"
                    component={Link}
                    to="/questionpool"
                    onClick={handleCloseUserMenu}
                  >
                    {t('navbar.questionpool')}
                  </MenuItem>
                  <MenuItem
                    key="modactions"
                    color="inherit"
                    component={Link}
                    to="/moderatoractions"
                    onClick={handleCloseUserMenu}
                  >
                    {t('navbar.modactions')}
                  </MenuItem>
                </div>
              )}
              <MenuItem
                key="logout"
                color="inherit"
                component={Link}
                to="/logout"
                onClick={handleCloseUserMenu}
              >
                {t('navbar.logout')}
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="persistent"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {props.children}
      </Box>
    </Box>
  );
}
