import UserDetail from "../components/Users/UserDetail";
import { LoginService } from "../services";
import Container from "@mui/material/Container";

function ProfilePage() {
  return (
    <Container maxWidth="md">
      <UserDetail id={LoginService.getUser().UserId} />
    </Container>
  );
}

export default ProfilePage;
