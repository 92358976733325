import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import ResponseInterceptor from "./services/ResponseInterceptor";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./components/common/ScrollToTop";
import NotificationToast from "./components/common/NotificationToast";
import GlobalDndContext from "./utils/dnd-context";
import ConfirmModal from "./components/common/ConfirmModal";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { trTR } from "@mui/material/locale";
import { Provider } from "react-redux";
import store from "./store";
import ConfirmModalWithInput from "./components/common/ConfirmModalWithInput";
import { orange, yellow, blueGrey } from "@mui/material/colors";

import './languages/i18n.js'

const theme = createTheme({
  palette: {
    primary: { main: "#1976d2" },
    completed: { main: "#A9CF54" },
    conforming: { main: "#b9eb49" },
    upcoming: { main: "#F7E967" },
    expired: { main: "#F1433F" },
    transferred: { main: "blue" },

    neutral: {
      main: `${blueGrey[700]}`,
      dark: `${blueGrey[800]}`,
      contrastText: "#fff",
    },
    yellow: {
      main: `${yellow[700]}`,
      dark: `${yellow[800]}`,
      contrastText: "#fff",
    },
  },
  status: {
    danger: orange[500],
  },
  findings: {
    completed: "#A9CF54",
    conforming: "#b9eb49",
    upcoming: "#F7E967",
    expired: "#F1433F",
    transferred: "blue",
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ResponseInterceptor />
      <GlobalDndContext>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <ScrollToTop />
            <NotificationToast />
            <ConfirmModal />
            <ConfirmModalWithInput />
            <App />
          </BrowserRouter>
        </ThemeProvider>
      </GlobalDndContext>
    </Provider>
  </React.StrictMode>,
);
