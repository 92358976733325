import { Stack, useTheme, useMediaQuery } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { useDispatch, useSelector } from "react-redux";
import {
  selectFindingFilters,
  setFilter,
} from "../../features/ui/findingFiltersSlice";
import DepartmentUserSelect from "../common/DepartmentUserSelect";
import { userHasPermission } from "../../utils/authFunctions";

const FindingsFilter = (props) => {
  const filters = useSelector(selectFindingFilters);
  const filterSet = setFilter;
  const approval = Boolean(props.approval);
  const dispatch = useDispatch();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const viewOthersPermission = userHasPermission("viewOthers");

  const handleChange = (event, newValue) => {
    dispatch(filterSet.status(newValue));
  };

  return (
    <Stack gap={2} direction={smallScreen ? "column" : "row"}>
      <DepartmentUserSelect
        style={{ width: "100%" }}
        horizontal={!smallScreen}
        valueDepartment={filters.department}
        onChangeDepartment={(event, newValue) => {
          dispatch(filterSet.department(newValue));
        }}
        valueUser={filters.inChargeUserId}
        readOnlyUser={!viewOthersPermission}
        onChangeUser={(value) => dispatch(filterSet.inChargeUserId(value))}
      />
      {!approval && (
        <ToggleButtonGroup
          color="primary"
          value={filters.status}
          exclusive
          onChange={handleChange}
        >
          <ToggleButton color="error" value="past">
            <PlaylistRemoveIcon />
          </ToggleButton>
          <ToggleButton value="upcoming">
            <FormatListBulletedIcon />
          </ToggleButton>
          <ToggleButton value={"completed"} color="success">
            <DoneIcon />
          </ToggleButton>
          <ToggleButton value={"approved"} color="success">
            <DoneAllIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      )}
    </Stack>
  );
};

export default FindingsFilter;
