import AnimatedNumber from "react-animated-number";
import {
  Button,
  Paper,
  Grid,
  Typography,
  Stack,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import { selectFindingStats } from "../../features/ui/findingStatsSlice";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

const Stat = ({ value, color, text }) => {
  const rgb = hexToRgb(color);
  return (
    <Paper elevation={5} sx={{ backgroundColor: color }}>
      <Stack justifyContent="center" alignItems="center" p={1}>
        <AnimatedNumber
          formatValue={(v) => v?.toFixed(0)}
          style={{
            transition: "0.8s ease-out",
            fontSize: 36,
            transitionProperty: "box-shadow, font-size",
          }}
          duration={1000}
          frameStyle={(perc) =>
            perc === 100 || !perc
              ? {}
              : {
                  boxShadow: `0px 0px 60px 8px rgba(${rgb.r}, ${rgb.g}, ${rgb.b},${perc})`,
                  fontSize: 45,
                }
          }
          value={value}
        />
        <Typography variant="subtitle1" color="#2b2b2b">
          {text}
        </Typography>
      </Stack>
    </Paper>
  );
};
const FindingStats = () => {
  const stats = useSelector(selectFindingStats);
  const { t } = useTranslation()
  const theme = useTheme();

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={6} md={3}>
          <Stat
            value={stats.Completed || 0}
            color={theme.findings.completed}
            text={t('findings.completed')}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Stat
            value={stats.Competable || 0}
            color={theme.findings.conforming}
            text={t('findings.conforming')}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Stat
            value={stats.Total - stats.Completed - stats.NotCompetable}
            color={theme.findings.upcoming}
            text={t('findings.upcoming')}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Stat
            value={stats.NotCompetable || 0}
            color={theme.findings.expired}
            text={t('findings.expired')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Stat
            value={stats.Total || 0}
            color="#ffffff"
            text={t('findings.total')}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default FindingStats;
