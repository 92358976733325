import { useSelector } from "react-redux";
import { selectDepartmentsWithPeriodicScoresYear } from "../../features/departments/departmentsSlice";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  BarElement
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { FormControlLabel, Paper, Switch } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { selectUsersAuditPerformance } from "../../features/users/usersSlice";

ChartJS.register(
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  BarElement,
);

const options = (title) => {

  return {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: title,
      },
    },
    scales: {
      y: {
        max: 100,
      },
    },
  }
};

const AveragePerformance = ({selectedUser}) => {
  const performance = useSelector(selectUsersAuditPerformance)
  const managementPerformance = performance['managers'].filter(u=>u.userID == selectedUser?.userID)
  const auditorPerformance = performance['auditors'].filter(u=>u.userID == selectedUser?.userID)
  const inchargeUserPerformance = performance['inchargeUsers'].filter(u=>u.userID == selectedUser?.userID)
  const {t} = useTranslation()

  const calculateScore = (performance, month, isAuditor=false) => {
    const p = performance.find(p=>p.M == month + 1)
    if (p) {
      const score = p.Score
      const count = p.Cnt
      return score / count / (isAuditor ? 1 : 6)
    }
    return 0
  }

  function prepareData() {
    const months = [
      t('months.0'),
      t('months.1'),
      t('months.2'),
      t('months.3'),
      t('months.4'),
      t('months.5'),
      t('months.6'),
      t('months.7'),
      t('months.8'),
      t('months.9'),
      t('months.10'),
      t('months.11'),
    ];
    return months.map((m, i) => {
      return { label: m, managementPerformance: calculateScore(managementPerformance, i), auditorPerformance: calculateScore(auditorPerformance, i, true), inchargeUserPerformance: calculateScore(inchargeUserPerformance, i)};
    });
  }

  function getChartData() {
    const chartData = prepareData();
    return {
      labels: chartData.map((d) => d.label),
      datasets: [
        {
          tension: 0.4,
          pointRadius: 0,
          pointHitRadius: 10,
          label: "Ortalama",
          data: chartData.map((d) => (d.managementPerformance + d.auditorPerformance + d.inchargeUserPerformance) / 3),
        },
      ],
    };
  }
  const title = t('titles.monthlyUserPerformance', {currentUser: selectedUser?.LongName })

  return (
    <Paper elevation={12}>
      <Bar options={options(title)} data={getChartData()} />
    </Paper>
  )
};

export default AveragePerformance;
