import React from "react";
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  Typography,
  Stack,
  Divider,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { Rating, Box, LinearProgress, Chip, IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { dialogActions } from "../../../features/ui/dialogsSlice";
import { useDispatch } from "react-redux";
import { setAuditQuestionNA } from "../../../features/audits/currentAuditSlice";
import { useState } from "react";
import { useTranslation } from "react-i18next";

function GetRating({ value }) {
  return (
    <Box>
      <Box style={{ display: "flex", gap: "1rem" }}>
        <Typography variant="body2" color="text.secondary">
          Skor:{" "}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {value / 100}
        </Typography>
      </Box>
      <Rating
        name="half-rating-read"
        value={value / 100}
        precision={0.1}
        readOnly
      />
    </Box>
  );
}

function LinearProgressWithLabel({ value, count }) {
  return (
    <Box style={{ minWidth: "50%" }}>
      <LinearProgress
        style={{ minWidth: "100%" }}
        variant="determinate"
        value={(value / count) * 100}
      />
      <Typography variant="body2" color="text.secondary">
        {value}/{count}
      </Typography>
    </Box>
  );
}

export default function AuditMainQuestionCard({
  auditInfo,
  auditQuestion,
  displayActions,
  onShowAuditQuestionModal,
}) {
  let color = "red";
  let isNA = auditQuestion.Response == -1;
  const dispatch = useDispatch();
  const { t } = useTranslation()

  if (auditQuestion.Answ == auditQuestion.Cnt) color = "green";
  if (isNA) color = "black";

  return (
    <Card sx={{ width: "100%", border: 1, borderColor: color }}>
      <CardActionArea>
        <CardContent>
          <Box mb={1}>
            <Stack direction="row" spacing={1} justifyContent="space-between">
              {/* <Typography gutterBottom variant="h6" component="div">
                        Soru No: {auditQuestion.QuestionId}
                    </Typography> */}
              <Chip
                size="small"
                label={t(`fiveSActivities.types.${auditQuestion.FiveSType}`)}
                color="primary"
              />
              {!isNA && <GetRating value={auditQuestion.Response} />}
            </Stack>

            <Typography variant="body1" color="text.secondary">
              {t('ui.question')}: {auditQuestion.question}
            </Typography>
          </Box>

          <Divider variant="middle" />

          <Box
            mt={1}
            style={{
              display: "flex",
              gap: "1rem",
              justifyContent: "space-between",
            }}
          >
            {/*                     <LinearProgressWithLabel value={auditQuestion.Answ} count={auditQuestion.Cnt}/> */}
          </Box>
        </CardContent>
      </CardActionArea>
      <CardActions>
        {displayActions && (
          <Button
            variant="contained"
            className="m-1"
            sx={{ marginRight: 2 }}
            onClick={() => onShowAuditQuestionModal(auditQuestion.Id)}
          >
            {t(auditInfo.CompleteDate ? "buttons.seeAnswers" : "buttons.answerQuestions")}
          </Button>
        )}

        {!isNA && auditQuestion.FindingId ? (
          <IconButton sx={{ marginRight: 2 }}>
            <VisibilityIcon />
          </IconButton>
        ) : null}
      </CardActions>
    </Card>
  );
}
