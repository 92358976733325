import dayjs from "dayjs";

function monthNameDayYear(date) {
  return dayjs(date).format("LL");
}

function dayMonthYear(date) {
  return dayjs(date).format("L");
}

function monthNameDayandTime(date) {
  return dayjs(date).format("LLL");
}

function convert2ddmmyyyy(dateString) {
  let d = new Date(dateString);
  var datestring =
    d.getFullYear() +
    "/" +
    ("0" + (d.getMonth() + 1)).slice(-2) +
    "/" +
    ("0" + d.getDate()).slice(-2);

  return datestring;
}

export const DateFormat = {
  monthNameDayYear,
  dayMonthYear,
  convert2ddmmyyyy,
  dayMonthYearandTime: monthNameDayandTime,
};
