import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  deleteFinding,
  fetchFindings,
} from "../../features/findings/findingsSlice";
import { dialogActions } from "../../features/ui/dialogsSlice";

import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CloseIcon from "@mui/icons-material/Close";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import {
  checkDeadline,
  getImageUrl,
  isFindingApproved,
  isFindingCompleted,
  isFindingExpired,
  isFindingTransferred,
} from "../../utils/commonFunctions";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { showNotification } from "../../features/ui/notificationSlice";
import { showConfirmDialog } from "../../features/ui/confirmDialogSlice";
import { DateFormat } from "../../utils/DateFormat";
import { ExportButton } from "./export-excel/ExportButton";
import { useNavigate } from "react-router-dom";
import { LoginService } from "../../services";
import {
  userHasPermission,
  currentUserIsAdmin,
  currentUserIsAuditor
} from "../../utils/authFunctions";
import { useTranslation } from "react-i18next";

const FindingsTable = (props) => {
  const [columns, setColumns] = useState([]);
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const deletePermission = userHasPermission("delete");
  const navigate = useNavigate();
  const { t } = useTranslation()

  const toggleTransferFindingDialog = (finding) => {
    dispatch(dialogActions.toggleTransferFinding(finding));
    //refresh
  };

  const toggleDetails = (finding) => {
    dispatch(dialogActions.toggleFindingDetail(finding));
  };
  const toggleCompleteFindingDialog = (finding) => {
    dispatch(dialogActions.toggleCompleteFinding(finding));
    //refresh
  };

  const handleDelete = async (findingId) => {
    const confirmed = await showConfirmDialog(
      dispatch,
      t('confirm.deleteFinding'),
    );
    if (confirmed) {
      dispatch(deleteFinding(findingId)).then(() => {
        showNotification(dispatch, t('notification.findingDeleted'));
      });
    }
  };

  useEffect(() => {
    const columns = [
      {
        field: "Id",
        headerName: "ID",
        maxWidth: 60,
        renderCell: (params) => {
          const master = params.row.master_id;
          if (master) {
            const finding = props.data.find((f) => f.Id == master);
            return (
              <Stack alignItems="center">
                <Typography
                  className="pointer"
                  onClick={() => toggleDetails(finding)}
                >
                  {master}
                </Typography>
                <ArrowDownwardIcon />
                <Typography
                  className="pointer"
                  onClick={() => toggleDetails(params.row)}
                >
                  {params.value}
                </Typography>
              </Stack>
            );
          }
          return (
            <Typography
              className="pointer"
              onClick={() => toggleDetails(params.row)}
            >
              {params.value}
            </Typography>
          );
        },
      },
      {
        field: "InchargeUser",
        headerName: `${t('ui.responsibleUser')} / ${t('ui.auditor')}`,
        renderCell: (params) => {
          return (
            <Stack>
              <Tooltip title={t('ui.responsibleUser')} followCursor>
                <Typography>{params.value}</Typography>
              </Tooltip>
              <Tooltip title={t('ui.auditor')} followCursor>
                <Typography>{params.row.AuditorUser}</Typography>
              </Tooltip>
            </Stack>
          );
        },
        minWidth: 170,
      },
      {
        field: "Department",
        headerName: t('ui.department'),
        minWidth: 150,
      },
      {
        field: "MainActivityId",
        headerName: t('ui.activityNo'),
        hide:
          props.data.filter((finding) => finding.MainActivityId !== 0).length ==
          0,
        minWidth: 150,
        renderCell: (params) => {
          return (
            <Typography
              className="pointer"
              onClick={() => navigate(`/audits/${params.value}`)}
            >
              {params.value}
            </Typography>
          );
        },
      },
      {
        field: "DueDate",
        headerName: t('ui.deadlineAndCompletion'),
        minWidth: 250,

        renderCell: (params) => {
          const creationDate = DateFormat.dayMonthYear(params.row.SystemDate);
          const creationDateTime = DateFormat.dayMonthYearandTime(
            params.row.SystemDate,
          );
          const dueDateText = DateFormat.dayMonthYear(params.value);
          const completed = isFindingCompleted(params.row);
          const expired = isFindingExpired(params.row);
          const conforming = checkDeadline(params.row, "conforming");
          const color = conforming
            ? "conforming"
            : completed
            ? "completed"
            : expired
            ? "expired"
            : "upcoming";
          const completedText = completed
            ? DateFormat.dayMonthYear(params.row.CompletedDate)
            : " - ";
          const title = (
            <div>
              {t('ui.creationDate')}: {creationDate}
              <br />
              {t('ui.deadlineDate')}: {dueDateText}
              <br />
              {t('ui.completeDate')}: {completedText}
            </div>
          );
          return (
            <Stack gap={2}>
              <Typography variant="outlined">
                {t('ui.createdText')}: {creationDateTime}
              </Typography>
              <Tooltip title={title} followCursor>
                <Button
                  color={color}
                  variant="contained"
                  sx={{ fontWeight: conforming ? "bold" : "normal" }}
                >
                  {completed ? completedText : dueDateText}
                </Button>
              </Tooltip>
            </Stack>
          );
        },
      },
      {
        field: "FiveSType",
        headerName: t('ui.fiveSType'),
        minWidth: 80,
      },
      {
        field: "Dsc",
        headerName: t('ui.mainQuestionAndFinding'),
        minWidth: 300,
        renderCell: (params) => {
          const noteExists = params.row.Note !== null;
          const content = (
            <Stack>
              <h4>{params.row.question}</h4>
              <div>{params.value}</div>
            </Stack>
          );
          if (noteExists) {
            return <Tooltip title={params.row.Note}>{content}</Tooltip>;
          } else {
            return content;
          }
        },
      },

      {
        field: "imagebefore",
        headerName: t('ui.imageBeforeAfter'),
        sortable: false,
        minWidth: 350,
        hide: props.noImage,
        renderCell: (params) => {
          const beforeImageId = params.value;
          const beforeImageURL = getImageUrl(beforeImageId);
          const afterImageId = params.row.imageafter;
          const afterImageURL = getImageUrl(afterImageId);
          return (
            <Stack direction="row" gap={1}>
              <img
                src={beforeImageURL}
                height={150}
                width={150}
                onClick={() => toggleDetails(params.row)}
                // style={{maxWidth:'160px', maxHeight: '150px', width:'100%'}}
                style={{
                  objectFit: "contain",
                  border: `3px solid ${theme.findings.expired}`,
                }}
                alt={t('ui.imageBefore')}
              />

              {afterImageId && (
                <img
                  src={afterImageURL}
                  height={150}
                  width={150}
                  onClick={() => toggleDetails(params.row)}
                  // style={{maxWidth:'160px', maxHeight: '150px', width:'100%'}}
                  style={{
                    objectFit: "contain",
                    border: `3px solid ${theme.findings.completed}`,
                  }}
                  alt={t('ui.imageAfter')}
                />
              )}
            </Stack>
          );
        },
      },
      {
        field: "DscOk",
        headerName: t('ui.actionTaken'),
        minWidth: 300,
        renderCell: (params) => {
          const completed = isFindingCompleted(params.row);
          const approved = isFindingApproved(params.row);
          const completedText = (
            <Typography>
              <Tooltip
                followCursor
                title={t('ui.completedOnDate', {date: DateFormat.monthNameDayYear(
                  params.row.CompletedDate,
                )})}
              >
                <DoneIcon />
              </Tooltip>{" "}
              {params.value}
            </Typography>
          );
          const approvedText = (
            <Typography>
              <Tooltip
                followCursor
                title={t('ui.completedOnDate', {date: DateFormat.monthNameDayYear(
                  params.row.ApproveDate,
                )})}
              >
                <DoneAllIcon />
              </Tooltip>{" "}
              {params.row.ApproveNote}
            </Typography>
          );

          if (approved) {
            return (
              <Stack>
                {completedText}
                {approvedText}
              </Stack>
            );
          } else if (completed) {
            return completedText;
          }
          return "";
        },
      },
      {
        headerName: smallScreen ? "" : t('ui.actions'),
        field: "actions",
        type: "actions",
        getActions: (params) => {
          const transferred = isFindingTransferred(params.row);
          const completed = isFindingCompleted(params.row);
          const approved = isFindingApproved(params.row);
          const currentUserId = LoginService.getUser().UserId;
          const isAnotherUser = params.row.InchargeUserId !== currentUserId;
          const isAdmin = currentUserIsAdmin();
          const isAuditor = currentUserIsAuditor();
          const canUserApprove = (finding) => {
            if (isAdmin)
              return true

            if (finding.ActivityType=="Audit" && isAuditor)
              return true

            return false

          }
          let actions = [
            <GridActionsCellItem
              color={completed ? "error" : "primary"}
              disabled={completed ? !canUserApprove(params.row) : transferred || isAnotherUser}
              icon={completed ? <CloseIcon /> : <ArrowForwardIcon />}
              onClick={() =>
                completed
                  ? console.log("doing nothing")
                  : toggleTransferFindingDialog(params.row)
              }
              label={t(completed ? "buttons.reject" : "buttons.transfer")}
              showInMenu={smallScreen}
            />,
            <GridActionsCellItem
              color="success"
              disabled={completed ? !canUserApprove(params.row): isAnotherUser}
              icon={completed ? <DoneAllIcon /> : <DoneIcon />}
              onClick={() =>
                completed
                  ? toggleTransferFindingDialog(params.row)
                  : toggleCompleteFindingDialog(params.row)
              }
              label={t(completed ? "buttons.confirm" : "buttons.complete")}
              showInMenu={smallScreen}
            />,
          ];
          if (deletePermission) {
            actions.push(
              <GridActionsCellItem
                color="error"
                icon={<DeleteIcon />}
                onClick={() => handleDelete(params.row.Id)}
                label={t('buttons.delete')}
                showInMenu={smallScreen}
              />,
            );
          }
          if (approved) {
            actions.shift();
            actions.shift();
          }
          return actions;
        },
        sortable: false,
      },
    ];
    setColumns(columns);
  }, [props.data]);

  useEffect(() => {
    dispatch(fetchFindings());
  }, []);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <ExportButton data={props.data} />
      </GridToolbarContainer>
    );
  }

  return props.autoHeight ? (
    <DataGrid
      rows={props.data}
      autoHeight
      columns={columns}
      components={{
        Toolbar: CustomToolbar,
      }}
      componentsProps={{
        toolbar: { printOptions: { disableToolbarButton: true } },
      }}
      // columnVisibilityModel={columnVisible}
      getRowId={(row) => row.Id}
      getRowHeight={() => "auto"}
      // pageSize={5}
      // rowsPerPageOptions={[5]}
      // checkboxSelection
    />
  ) : (
    <Grid container alignItems="flex-end" justifyContent="center">
      <Grid
        item
        xs={12}
        md={11}
        m={{ md: 2, xs: 0 }}
        style={{ height: 700 }}
        mt={{ xs: 1 }}
      >
        <DataGrid
          rows={props.data}
          columns={columns}
          components={{
            Toolbar: CustomToolbar,
          }}
          componentsProps={{
            toolbar: { printOptions: { disableToolbarButton: true } },
          }}
          // columnVisibilityModel={columnVisible}
          getRowId={(row) => row.Id}
          getRowHeight={() => "auto"}
          // pageSize={5}
          // rowsPerPageOptions={[5]}
          // checkboxSelection
        />
      </Grid>
    </Grid>
  );
};

export default FindingsTable;
