import {
  getTreeFromFlatData,
  SortableTreeWithoutDndContext as SortableTree,
} from "react-sortable-tree";
import "react-sortable-tree/style.css"; // This only needs to be imported once in your app

import { useState, useEffect } from "react";
import { Container, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Role from "./Role";
import Department from "./Department";
import InsertModal from "./InsertModal";
import UsersService from "../../services/UsersService";

import { useDispatch } from "react-redux";
import { showNotification } from "../../features/ui/notificationSlice";
import { showConfirmDialog } from "../../features/ui/confirmDialogSlice";
import { showConfirmDialogWithInput } from "../../features/ui/confirmDialogWithInputSlice";
import { currentUserIsAdmin, userHasPermission } from "../../utils/authFunctions";

const Tree = (props) => {
  const [data, setData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editedNode, setEditedNode] = useState(0);
  const service = props.service;
  const [update, setUpdate] = useState(false);
  const [users, setUsers] = useState([]);
  const [isRoleTree, setIsRoleTree] = useState(false);
  const [insertModalOpen, setInsertModalOpen] = useState(false);
  const dispatch = useDispatch();
  const addPermission = currentUserIsAdmin();
  const updatePermission = currentUserIsAdmin();
  const deletePermission = currentUserIsAdmin();

  useEffect(() => {
    if (update) {
      setUpdate(false);
    }
  }, [update]);

  useEffect(() => {
    setIsRoleTree(service.name === "Role");
    UsersService.getAllUsers().then((response) => setUsers(response));
  }, []);

  function refreshData() {
    service.getAll().then((response) => {
      const treeData = getTreeFromFlatData({
        flatData: response.map((node) => ({
          ...node,
          title: node.Adi,
          expanded: true,
        })),
        getKey: (node) => node.Id,
        getParentKey: (node) => node.master_id,
        rootKey: 0,
      });
      // console.log("-------tree-data-------");
      // function printNode(obj) {
      //   // if(obj.node.children === undefined){
      //   console.log(obj);
      //   // }
      // }
      // walk({
      //   treeData: treeData,
      //   getNodeKey: (node) => node.Id,
      //   callback: printNode,
      // });
      setData(treeData);
    });
  }
  useEffect(() => {
    refreshData();
  }, []);

  function handleMove(data) {
    if (!updatePermission) {
      showNotification(dispatch, "Bu öğeleri taşıma yetkiniz yok");
      refreshData();
      return;
    }
    const node = data.node;
    let [newParent, nodeId] = data.path.slice(-2);
    if (nodeId === undefined) {
      nodeId = newParent;
      newParent = 0;
    }
    if (newParent !== node.master_id) {
      service
        .update({ ...node, master_id: newParent })
        .then(showNotification(dispatch, `${service.name} moved succesfully!`))
        .catch(() => {
          showNotification(
            dispatch,
            `${service.name} could not be moved. Try again later.`,
            "danger",
          );
        })
        .finally(() => refreshData());
    }
  }

  async function handleDelete(node) {
    const message = `Are you sure you want to delete "${node.Adi} ${service.name}"?`;
    let confirmed;
    if (isRoleTree) confirmed = await showConfirmDialog(dispatch, message);
    else
      confirmed = await showConfirmDialogWithInput(dispatch, message, node.Adi);
    if (confirmed) {
      service
        .delete_(node.Id)
        .then(() => {
          showNotification(dispatch, `${service.name} deleted succesfully!`);
          refreshData();
        })
        .catch(() => {
          showNotification(
            dispatch,
            `${service.name} could not be deleted. Try again later.`,
            "danger",
          );
        })
        .finally(() => refreshData());
    }
  }

  function toggleAddMode(node, refresh = false) {
    setEditedNode(node);
    setInsertModalOpen(!insertModalOpen);
    if (refresh) refreshData();
  }

  function toggleEditMode(node, refresh = false) {
    setEditMode(!editMode);
    setEditedNode(node.Id);
    if (refresh) {
      refreshData();
    }
  }

  function render_title(node) {
    if (isRoleTree) {
      return (
        <Role
          update={update}
          updateCallback={() => toggleEditMode(node, true)}
          users={users}
          editing={editMode && node.Id === editedNode}
          currentNode={editedNode}
          node={node}
          key={node.Id}
        />
      );
    } else {
      return (
        <Department
          update={update}
          updateCallback={() => toggleEditMode(node, true)}
          users={users}
          editing={editMode && node.Id === editedNode}
          currentNode={editedNode}
          node={node}
          key={node.Id}
        />
      );
    }
  }

  function render_buttons(node) {
    let buttons = [];
    if (editMode && node.Id === editedNode) {
      buttons = [
        <IconButton color="primary" onClick={() => toggleEditMode(node)}>
          <CloseIcon />
        </IconButton>,
        <IconButton color="success" onClick={() => setUpdate(true)}>
          <CheckIcon />
        </IconButton>,
      ];
    } else {
      if (addPermission) {
        buttons.push(
          <IconButton onClick={() => toggleAddMode(node)} color="success">
            <AddIcon />
          </IconButton>,
        );
      }
      if (updatePermission) {
        buttons.push(
          <IconButton
            color="primary"
            disabled={editMode}
            onClick={() => toggleEditMode(node)}
          >
            <EditIcon />
          </IconButton>,
        );
      }
      if (deletePermission) {
        buttons.push(
          <IconButton
            color="error"
            disabled={editMode}
            onClick={() => handleDelete(node)}
          >
            <DeleteIcon />
          </IconButton>,
        );
      }
    }
    return buttons;
  }

  function calculateRowHeight({ node }) {
    if (isRoleTree) {
      return 90;
    } else {
      return editMode && node.Id === editedNode ? 120 : 90;
    }
  }

  return (
    <div>
      <InsertModal
        open={insertModalOpen}
        node={editedNode}
        onClose={toggleAddMode}
        service={service}
        isRole={isRoleTree}
      />

      <Container>
        <Typography variant="h4" align="center">
          {isRoleTree ? "DENETİM ROLLERİ" : "DEPARTMANLAR"}
        </Typography>
        <SortableTree
          treeData={data}
          isVirtualized={false}
          onChange={(treeData) => setData(treeData)}
          rowHeight={calculateRowHeight}
          getNodeKey={({ node }) => node.Id}
          onMoveNode={handleMove}
          generateNodeProps={({ node }) => ({
            title: render_title(node),
            // subtitle: render_subtitle(node),
            buttons: render_buttons(node),
          })}
        />
      </Container>
    </div>
  );
};

export default Tree;
