
import s_ve_palet_alani from "./color_codes/5s_ve_palet_alani.png";
import araclar_gerecer from "./color_codes/araclar_gerecer.png";
import atik from "./color_codes/atik.png";
import bitmis_urun_veya_proses_ciktisi from "./color_codes/bitmis_urun_veya_proses_ciktisi.png";
import forklift_yolu from "./color_codes/forklift_yolu.png";
import hammadde_veya_proses_girdisi from "./color_codes/hammadde_veya_proses_girdisi.png";
import tehlikeli_alan from "./color_codes/tehlikeli_alan.png";
import uygun_olmayan_materyal_urun from "./color_codes/uygun_olmayan_materyal_urun.png";
import yurume_koridoru from "./color_codes/yurume_koridoru.png";

export default [
  { name: "5S ve Palet Alanı", image: s_ve_palet_alani },
  { name: "Araçlar ve Gereçler", image: araclar_gerecer },
  { name: "Atık", image: atik },
  {
    name: "Bitmiş Ürün veya Proses Çıktısı",
    image: bitmis_urun_veya_proses_ciktisi,
  },
  { name: "Forklift Yolu", image: forklift_yolu },
  { name: "Hammadde veya Proses Çıktısı", image: hammadde_veya_proses_girdisi },
  { name: "Tehlikeli Alan", image: tehlikeli_alan },
  { name: "Uygun Olmayan Materyal Ürün", image: uygun_olmayan_materyal_urun },
  { name: "Yürüme Koridoru", image: yurume_koridoru },
];
