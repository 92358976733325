import UsersService from "../../services/UsersService";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchRoleUsers } from "../roleUsers/roleUsersSlice";
import fuzzysort from "fuzzysort";

export const fetchUsers = createAsyncThunk("user/fetchUsers", async () => {
  const response = await UsersService.getAllUsers();
  return response;
});

export const fetchSubDepUsers = createAsyncThunk(
  "user/fetchSubDepUsers",
  async (departmentId) => {
    const response = await UsersService.getSubDepUsers(departmentId);
    const uniqueArray = response.filter((obj, index, arr) => {
      return index === arr.findIndex((o) => o.Id === obj.Id);
    });
    return uniqueArray;
  },
);

export const addUser = createAsyncThunk("user/addUser", async (userData) => {
  const response = await UsersService.insertUser({ ...userData });
  return response;
});

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (userData) => {
    const response = await UsersService.updateUser({ ...userData });
    return response;
  },
);

export const addRole = createAsyncThunk(
  "user/addRole",
  async (roleData, thunkAPI) => {
    await UsersService.setRole({ ...roleData });
    await thunkAPI.dispatch(fetchRoleUsers);
    thunkAPI.dispatch(fetchUsers());
  },
);

export const deleteRole = createAsyncThunk(
  "user/deleteRole",
  async (roleUserId, thunkAPI) => {
    await UsersService.deleteRole(roleUserId);
    thunkAPI.dispatch(fetchUsers());
  },
);

export const deleteUser = createAsyncThunk("user/deleteUser", async (id) => {
  const response = await UsersService.deleteUser(id);
  if (response) return id;
  else return false;
});

export const fetchUsersWithPeriodicScores = createAsyncThunk(
  "user/fetchUsersWithPeriodicScores",
  async (year) => {
    const usersWithScores = await UsersService.getPeriodicScoresForUsers(
      year,
    );
    return Object.values(usersWithScores);
  },
);

export const fetchAuditorsWithAuditScores = createAsyncThunk(
  "user/fetchAuditorsWithAuditScores",
  async (year) => {
    const auditorWithScores = await UsersService.getAuditScoresForAuditors(
      year,
    );
    return Object.values(auditorWithScores);
  },
);

export const fetchUsersAuditPerformance = createAsyncThunk(
  "user/fetchUsersAuditPerformance ",
  async ({year, performance_type}) => {
    const response = await UsersService.getUserPerformance(
      year, performance_type
    );
    const table = {
      "MNG_AUDIT_PERFORMANCE": "managers",
      "INCHARGE_AUDIT_PERFORMANCE": "inchargeUsers",
      "AUDITOR_AUDIT_PERFORMANCE": "auditors",
      "PARTICIPANT_PERFORMANCE": "participants",
      "FINDING_PERFORMANCE": "findings"
    }
    const type = table[performance_type]

    return [type, response.Table]
  },
)



const initialState = {
  users: [],
  loading: false,
  error: "",
  subDepUsers: [],
  usersWithPeriodicScores: [],
  auditorsWithAuditScores: [],
  usersAuditPerformance: {"managers": [], "inchargeUsers": [], "auditors": [], "participants": [], "findings": []},
  usersVisibleToCurrentUser: []
};

const usersSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    fetchUsersVisibleToCurrentUser: (state, action) => {
      const visibleDepartments = action.payload;
      const userIds = []
      visibleDepartments.forEach(department => {
        userIds.push(...department.Users.split(',').map(Number))
      });
      state.usersVisibleToCurrentUser = [... new Set(userIds)].map(id=>state.users.find(u=>u.Id == id)).filter(u=>u)
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchUsers.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.loading = false;
      state.users = action.payload.sort((a, b) => {
        if (a.LongName > b.LongName) return 1;
        else if (a.LongName < b.LongName) return -1;
        else return 0;
      });
      state.error = "";
    });
    builder.addCase(fetchSubDepUsers.fulfilled, (state, action) => {
      state.subDepUsers = action.payload;
    });
    builder.addCase(fetchUsers.rejected, (state, action) => {
      state.loading = false;
      state.users = [];
      state.error = action.error.message;
    });

    builder.addCase(addUser.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addUser.fulfilled, (state, action) => {
      state.loading = false;
      state.users.push(action.payload[0]);
    });

    builder.addCase(deleteRole.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(deleteRole.fulfilled, (state, action) => {
      state.loading = false;
    });

    builder.addCase(deleteUser.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload !== false) {
        state.users.splice(
          state.users.findIndex((user) => user.Id === action.payload),
          1,
        );
      }
    });
    builder.addCase(fetchUsersWithPeriodicScores.fulfilled, (state, action) => {
      state.usersWithPeriodicScores = action.payload;
    });
    builder.addCase(fetchAuditorsWithAuditScores.fulfilled, (state, action) => {
      state.auditorsWithAuditScores = action.payload;
    });

    builder.addCase(fetchUsersAuditPerformance.fulfilled, (state, action) => {
      const [type, value] = action.payload;

      const groupedData = value.reduce((acc, current) => {
        const key = `${current.userID}-${current.M}`;
        if (!acc[key]) {
          acc[key] = { userId: current.userID, name: current.LongName, Cnt: 0, id: current.id, month: current.M, Score: 0, Photo: current.Photo, CompletedAudits: 0, Termin: 0};
        }
        acc[key].Score += current.Score;
        acc[key].Cnt += current.Cnt;
        acc[key].CompletedAudits += current.CompletedAudits;
        acc[key].Termin += current.Termin;
        return acc;
      }, {});

      const result = Object.values(groupedData).map(group => ({
        userID: group.userId,
        M: group.month,
        id: group.id,
        LongName: group.name,
        Cnt: group.Cnt,
        Score: group.Score,
        photo: group.Photo,
        Completed: group.CompletedAudits,
        Termin: group.Termin
      }));

      state.usersAuditPerformance[type] = result
    });

  },
});

export const selectUsers = (state) => state.user;
export const selectFilteredUsers = (state) => {
  const filterText = state.ui.listFilters.filterText;
  const result = fuzzysort.go(filterText, state.user.users, {
    keys: ["LongName", "email"],
    all: true,
    threshold: -999,
    // Create a custom combined score to sort by. -100 to the Email score makes it a worse match
    scoreFn: (a) =>
      Math.max(a[0] ? a[0].score : -1000, a[1] ? a[1].score - 100 : -1000),
  });
  return result;
};

export const selectUnitManagers = (state) =>
  state.user.users.filter((u) => u.UserRolesCode.split(",").includes("AL"));
export const selectUsersWithPeriodicScores = (state) =>
  state.user.usersWithPeriodicScores;
export const selectAuditorsWithAuditScores = (state) =>
  state.user.auditorsWithAuditScores;

export const selectUsersAuditPerformance = (state) =>
  state.user.usersAuditPerformance;
export const selectUsersVisibleToCurrentUser = (state) =>
  state.user.usersVisibleToCurrentUser;


export const { fetchUsersVisibleToCurrentUser } = usersSlice.actions;
export default usersSlice.reducer;
