import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { handleExportFindingsToPdf } from "../Findings/export-excel/ExportPdf";

export const createPDF = async (
  htmlTagToBePrinted,
  pageLayout,
  pdfTitle,
  findings,
) => {
  const pdf = new jsPDF(pageLayout, "pt", "a4", true);
  const data = await html2canvas(document.querySelector(htmlTagToBePrinted), {
    allowTaint: true,
    useCORS: true,
    scale: 2,
  });
  const img = data.toDataURL("image/png");
  const imgProperties = pdf.getImageProperties(img);
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
  pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight, "", "FAST");

  //
  if (findings != null) {
    pdf.addPage();
    await handleExportFindingsToPdf(findings, findings, pdf, null);
  }

  pdf.save(pdfTitle + ".pdf");
};
