import { useContext, useEffect, useState } from "react";
import { Tab, Tabs } from "@mui/material";
import FindingsComponent from "../components/Findings/FindingsComponent";
import { useDispatch } from "react-redux";
import { setFilter } from "../features/ui/findingFiltersSlice";
import { useTranslation } from "react-i18next";

const FindingsPage = () => {
  const [currentTab, setcurrentTab] = useState("Audit");
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const handleChange = (event, newValue) => {
    setcurrentTab(newValue);
    dispatch(setFilter.type(newValue));
  };
  useEffect(() => {
    dispatch(setFilter.type(currentTab));
  }, []);

  return (
    <div>
      <Tabs variant="scrollable" value={currentTab} onChange={handleChange}>
        <Tab label={t('findings.auditFindings')} value="Audit" />
        <Tab label={t('findings.fieldTourFindings')} value="FieldTour" />
        <Tab label={t('findings.workshopFindings')} value="Workshop" />
        <Tab label={t('findings.instantFindings')} value="Instant" />
      </Tabs>
      <FindingsComponent />
    </div>
  );
};

export default FindingsPage;
