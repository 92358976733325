import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectFilterUser, setFilterUser } from '../../features/ui/listFiltersSlice';
import { Autocomplete, TextField } from '@mui/material';

const UserSelector = (props) => {
  const user = useSelector(selectFilterUser)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setFilterUser(null));
  }, []);

  const handleChange = (event, val) => {
    dispatch(setFilterUser(val));
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <Autocomplete
          options={[...props.userList].sort((a, b) => {
            if (a.LongName > b.LongName) return 1;
            else if (a.LongName < b.LongName) return -1;
            else return 0;
          })}
          size="small"
          getOptionLabel={(d) => d.LongName}
          renderInput={(params) => <TextField {...params} label="User" />}
          value={user}
          onChange={handleChange}
        />
      </FormControl>
    </Box>
  );
}

export default UserSelector;
