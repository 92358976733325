import { TextField, Stack, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState, useEffect } from "react";

function isNumeric(value) {
  return /^-?\d+$/.test(value);
}
const DeleditableTextView = (props) => {
  const [editMode, setEditMode] = useState(false);
  const [value, setValue] = useState(props.value);
  const [error, setError] = useState(false);

  useEffect(() => {
    setValue(props.value);
    setError(false);
  }, [editMode]);

  function handleUpdate() {
    props.onUpdate(value).then(() => setEditMode(!editMode));
  }

  function handleChange(e) {
    console.log("target value is ", e.target.value);
    if (props.integerOnly && !isNumeric(e.target.value)) setError(true);
    else setError(false);
    setValue(e.target.value);
  }

  return editMode ? (
    <Stack direction="row">
      <TextField
        fullWidth
        error={error}
        helperText={error && "Only integer values are allowed"}
        size={props.size ? props.size : "medium"}
        type={props.integerOnly ? "number" : "text"}
        label={props.label}
        multiline={props.multiline}
        rows={4}
        value={value}
        onChange={handleChange}
      />
      <IconButton color="error" onClick={() => setEditMode(!editMode)}>
        <CloseIcon />
      </IconButton>
      <IconButton color="success" disabled={error} onClick={handleUpdate}>
        <CheckIcon />
      </IconButton>
    </Stack>
  ) : (
    <Stack direction="row">
      {props.children ? (
        props.children
      ) : (
        <TextField
          fullWidth
          size={props.size ? props.size : "medium"}
          label={props.label}
          value={props.value}
          multiline={props.multiline}
          rows={4}
        />
      )}
      {props.onUpdate && (
        <IconButton color="primary" onClick={() => setEditMode(!editMode)}>
          <EditIcon />
        </IconButton>
      )}
      {props.onDelete && (
        <IconButton color="error" onClick={props.onDelete}>
          <DeleteIcon />
        </IconButton>
      )}
    </Stack>
  );
};

export default DeleditableTextView;
