import { useContext, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useNavigate } from "react-router-dom";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import Container from "@mui/material/Container";
import DepartmentService from "../../services/DepartmentServices";
import Tree from "./Tree";
import {
  selectDepartments,
  selectComparableDepartments,
  selectDepartmentsVisibleToCurrentUser,
  selectFilteredDepartments,
} from "../../features/departments/departmentsSlice";
import { useSelector } from "react-redux";
import { Paper, Stack } from "@mui/material";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import PrettyDepartment from "../common/PrettyDepartment";
import { TabPanel } from "../../utils/commonFunctions";
import { useTranslation } from "react-i18next";
import Filter from "../common/Filter";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  [`&.${tableRowClasses.head}`]: {
    border: "2px solid black",
  },
  // hide last border
  "&:last-child td": {
    border: 0,
  },
}));

function DepartmentsList() {
  const departments = useSelector(selectFilteredDepartments);
  const navigate = useNavigate();
  const { t } = useTranslation()
  return (
    <TableContainer component={Paper}>
      <Filter placeholder={t('ui.filterDepartments')}/>
      <Table>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>{t('ui.department')}</StyledTableCell>
            <StyledTableCell align="right">{t('ui.score')}</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {[...departments]
            .sort((a, b) => {
              const aScore = a.obj.Score
              const bScore = b.obj.Score
              const aAdi = a.obj.Adi
              const bAdi = b.obj.Adi
              if (aScore < bScore) return 1;
              else if (aScore > bScore) return -1;
              else {
                if (aAdi > bAdi) return 1;
                else if (aAdi < bAdi) return -1;
                return 0;
              }
            })
            .map((match) => (
              <StyledTableRow
                key={match.obj.Id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                onClick={() => navigate(`/departments/${match.obj.Id}`)}
              >
                <StyledTableCell component="th" scope="row">
                  <Stack direction="row">
                    <LockOpenIcon />
                    <PrettyDepartment department={match.obj} match={match}/>
                  </Stack>
                </StyledTableCell>
                <TableCell align="right">
                  {(match.obj.Score / 100).toFixed(2)}
                </TableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const Departments = () => {
  const [currentTab, setcurrentTab] = useState("list");
  const { t } = useTranslation()

  const handleChange = (event, newValue) => {
    setcurrentTab(newValue);
  };
  return (
    <Container sx={{ borderBottom: 1, borderColor: "divider" }}>
      <Tabs value={currentTab} onChange={handleChange}>
        <Tab label={t('departments.treeView')} value="tree" />
        <Tab label={t('departments.listView')} value="list" />
      </Tabs>
      <TabPanel currentTab={currentTab} value="tree">
        <Tree service={DepartmentService} />
      </TabPanel>
      <TabPanel currentTab={currentTab} value="list">
        <DepartmentsList />
      </TabPanel>
    </Container>
  );
};

export default Departments;
