import React from "react";
import { Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import AuditCard from "./AuditCard";
import UpdateAuditDialog from "./UpdateAuditDialog";

export default function AuditCardsComponent({ audits }) {
  return (
    <Container>
      <Grid container spacing={1} sx={{ bgcolor: "background.paper" }}>
        {audits.map((audit) => (
          <Grid item key={audit.Id} xs={12} md={4}>
            {
              <AuditCard
                key={audit.Id}
                audit={audit}
                displayActions={true}
              ></AuditCard>
            }
          </Grid>
        ))}
      </Grid>
      <UpdateAuditDialog />
    </Container>
  );
}
