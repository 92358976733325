import {
  Autocomplete,
  FormHelperText,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectDepartments } from "../../features/departments/departmentsSlice";

export default function DepartmentSelect(props) {
  const departments = useSelector(selectDepartments);
  const [auditable, setAuditable] = useState(true);
  const options = auditable
    ? departments.filter((d) => d.Auditable)
    : departments;
  const readOnly = props.departmentId !== undefined;

  return (
    <Stack>
      <Stack alignItems="flex-end" direction="row">
        <Autocomplete
          fullWidth
          options={[...options].sort((a, b) => a.FullName > b.FullName)}
          getOptionLabel={(d) => {
            const split = d.FullName.split(" - ");
            return split.join(" > ");
          }}
          value={props.value}
          readOnly={readOnly}
          onChange={(event, newValue) => {
            props.onChange(newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Departman" variant="standard" />
          )}
        />
        {!readOnly && (
          <Switch
            checked={auditable}
            onChange={(event) => setAuditable(event.target.checked)}
          />
        )}
      </Stack>
      {!readOnly && (
        <FormHelperText>
          <b>{auditable ? "Denetlenebilir" : "Tüm"}</b> departmanlar
          listeleniyor.
        </FormHelperText>
      )}
    </Stack>
  );
}
