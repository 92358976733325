import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { selectFindingStats } from "../../features/ui/findingStatsSlice";

ChartJS.register(ArcElement, Tooltip, Legend);

export function DoughnutChart() {
  const stats = useSelector(selectFindingStats);

  const data = {
    labels: ["Termini Geçmiş", "Toplam Bulgu"],
    datasets: [
      {
        label: "#",
        data: [stats.NotCompetable, stats.Total - stats.NotCompetable],
        backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)"],
        borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
        borderWidth: 1,
      },
    ],
  };

  return <Doughnut style={{ margin: "50px" }} data={data} />;
}
