import * as React from "react";
import { useState } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import RepeatIcon from "@mui/icons-material/Repeat";
import Typography from "@mui/material/Typography";
import { Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useMediaQuery, useTheme } from "@mui/material";
import { Grid } from "@mui/material";
import InsertPeriodicAuditDialog from "../components/MainActivity/Audit/InsertPeriodicAuditDialog";

import { IconButton, Menu, MenuItem, ListItemIcon, Stack } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useSelector } from "react-redux";
import { selectPeriodicActivities } from "../features/audits/auditsSlice";
import { useDispatch } from "react-redux";
import { showConfirmDialog } from "../features/ui/confirmDialogSlice";
import { deleteAudit } from "../features/audits/auditsSlice";

const MoreActionsButton = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton sx={{ color: "black" }} onClick={handleMenuClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleMenuClose}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          Tekrar oluştur
        </MenuItem>
        <MenuItem
          onClick={() => {
            props.onDeleteCallback();
            handleMenuClose();
          }}
        >
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          Sil
        </MenuItem>
      </Menu>
    </>
  );
};

function PeriodicAuditsPage() {
  const periodicActivities = useSelector(selectPeriodicActivities);
  const [showInsertPeriodicAuditModal, setShowInsertPeriodicAuditModal] =
    useState(false);
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();

  return (
    <>
      <Typography align="center" variant="h4" component="h5">
        PERİYODİK AKTİVİTELER
      </Typography>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={5}>
          {showInsertPeriodicAuditModal && (
            <InsertPeriodicAuditDialog
              show={showInsertPeriodicAuditModal}
              onClose={() => setShowInsertPeriodicAuditModal(false)}
            />
          )}
          <Fab
            sx={{
              position: "fixed",
              bottom: (theme) => theme.spacing(2),
              right: (theme) => theme.spacing(2),
            }}
            variant={smallScreen ? "contained" : "extended"}
            color="primary"
            onClick={() => setShowInsertPeriodicAuditModal(true)}
          >
            <AddIcon /> {smallScreen ? "" : "Periodik Denetim Ekle"}
          </Fab>

          <Timeline position="right">
            {periodicActivities.map((activity) => (
              <TimelineItem key={activity.Id}>
                <TimelineOppositeContent
                  sx={{ m: "auto 0" }}
                  align="right"
                  variant="body2"
                  color="text.secondary"
                >
                  Başlangıç: {new Date(activity.StartDate).toDateString()}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineConnector />
                  <TimelineDot color="secondary">
                    <RepeatIcon />
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent
                  sx={{ py: "12px", px: 2 }}
                  onClick={() => null}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography>{activity.ActivityType}</Typography>
                    <MoreActionsButton
                      onDeleteCallback={async () => {
                        const confirmed = await showConfirmDialog(
                          dispatch,
                          "Periyodik denetim silinsin mi?",
                        );
                        if (confirmed == false) return;

                        deleteAudit(dispatch, activity.Id);
                      }}
                    />
                  </Stack>
                  <Typography>
                    Bitiş: {new Date(activity.DueDate).toDateString()}
                  </Typography>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </Grid>
      </Grid>
    </>
  );
}

export default PeriodicAuditsPage;
