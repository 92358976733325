import { MAIN_URL } from "./ServiceConstants";
import axios from "axios";

async function getDashboard() {
  return axios.post(MAIN_URL + "/Quality/GetDashboard");
}

const DashboardService = {
  getDashboard,
};

export default DashboardService;
