import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  Stack,
  CardActionArea,
  CardContent,
  CardActions,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import { Link } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { yellow } from "@mui/material/colors";
import {
  auditAboutToExpire,
  auditHasOpenFinding,
  isAuditCompleted,
  isAuditExpired,
} from "../../../utils/commonFunctions";
import { useDispatch, useSelector } from "react-redux";
import { deleteAudit } from "../../../features/audits/auditsSlice";
import { showNotification } from "../../../features/ui/notificationSlice";
import { showConfirmDialog } from "../../../features/ui/confirmDialogSlice";
import {
  getRoleCodeDepartments,
  getChildDepartmentsOfRoleCode,
  userHasPermission,
} from "../../../utils/authFunctions";
import { dialogActions } from "../../../features/ui/dialogsSlice";

import { currentUserIsAdmin } from "../../../utils/authFunctions";
import { LoginService } from "../../../services";
import { selectDepartments } from "../../../features/departments/departmentsSlice";
import { useTranslation } from "react-i18next";

const MoreActionsButton = ({ audit }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const deletePermission = userHasPermission("delete");
  const updatePermission = userHasPermission("update");
  const auditId = audit.Id;
  const { t } = useTranslation()

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteAudit = async (id) => {
    var result = await showConfirmDialog(
      dispatch,
      t('confirm.deleteAudit'),
    );
    if (result) {
      deleteAudit(dispatch, id)
        .then(() => {
          showNotification(dispatch, t('notification.auditDeletedSuccess'), "primary");
        })
        .catch(() => {
          showNotification(
            dispatch,
            t('notification.auditDeletedError'),
            "danger",
          );
        });
    }

    setAnchorEl(null);
  };

  const handleUpdateAudit = async (audit) => {
    dispatch(dialogActions.toggleAuditDetail(audit));
    setAnchorEl(null);
  };

  const handleViewReport = () => {
    setAnchorEl(null);
    navigate("/auditreport/" + auditId);
  };

  return (
    <>
      <IconButton
        sx={{ color: "primary.contrastText" }}
        onClick={handleMenuClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {updatePermission && audit.ActivityType == "Audit" &&
        audit.CompleteDate == null && (
          <MenuItem onClick={() => handleUpdateAudit(audit)}>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            {t('buttons.update')}
          </MenuItem>
        )}
        {deletePermission && (
          <MenuItem onClick={() => handleDeleteAudit(auditId)}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            {t('buttons.delete')}
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default function TCard({ audit, displayActions }) {
  const navigate = useNavigate();
  const { t } = useTranslation()

  const currentUserId = LoginService.getUser().UserId;
  const isAdmin = currentUserIsAdmin();
  const isAuditor = audit.AuditorId == currentUserId;
  const isResponsibleUser = audit.InchargeUserId == currentUserId;
  const completed = isAuditCompleted(audit);
  // const departments = useSelector(selectDepartments);
  // const viewAbleChildDepartments = getChildDepartmentsOfRoleCode(departments, "YO");

  const enabled = displayActions && (isAdmin || isAuditor || (completed && isResponsibleUser))
;

  const color = auditHasOpenFinding(audit)
    ? yellow[700]
    : isAuditCompleted(audit)
    ? "success.main"
    : isAuditExpired(audit)
    ? "error.main"
    : auditAboutToExpire(audit)
    ? "warning.main"
    : "info.main";

  function getActivityTypeHeader() {
    if (["Audit", "Workshop", "FieldTour", "Meeting"].includes(audit.ActivityType)) {
      return t(`tcard.header.${audit.ActivityType}`)
    }
    else return t('tcard.header.unknown');
  }

  function getOpenDetailsText() {
    if (["Audit", "Workshop", "FieldTour", "Meeting"].includes(audit.ActivityType)) {
      return t(`tcard.openActivity.${audit.ActivityType}`)
    }
    else return t('tcard.openActivity.unknown');
  }

  function isFieldVisible(fieldName) {
    if (fieldName == "Department") {
      if (audit.ActivityType == "FieldTour" || audit.ActivityType == "Meeting")
        return false;
    }

    if (fieldName == "InchargeUser") {
      if (audit.ActivityType == "FieldTour" || audit.ActivityType == "Meeting" || audit.ActivityType == "Workshop")
        return false;
    }

    return true;
  }

  return (
    <>
      {audit.Department && (
        <Card
          sx={{
            width: "100%",
            backgroundColor: `${color}`,
            color: `white`,
          }}
        >
          <CardContent sx={{ p: 2, "&:last-child": { pb: 1 }, mb: 0 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                variant="body1"
                component="div"
                style={{ textAlign: "center" }}
              >
                { getActivityTypeHeader() + " No: " + audit.Id }
              </Typography>
              <Stack direction="row" alignItems="center">
                <Typography
                  variant="body1"
                  component="div"
                  style={{ textAlign: "right" }}
                >
                  {audit.FindingCompleted + "/" + audit.FindingTotal}
                </Typography>
                <MoreActionsButton audit={audit} />
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      )}
      <Card sx={{ width: "80%", marginX: "auto" }}>
        <CardContent>
          {audit.ActivityType=="Audit" && audit.CompleteDate && (
            <Typography variant="body2" color="text.secondary">
              <b>{t('ui.auditScore')}:</b> {audit.Score / 100}
            </Typography>
          )}
          {isFieldVisible("Department") &&
          <Typography variant="body2" color="text.secondary">
            <b>{t('ui.unit')}:</b> {audit.Department}
          </Typography>}
          {isFieldVisible("InchargeUser") && 
          <Typography variant="body2" color="text.secondary">
            <b>{t('ui.responsibleUser')}:</b>
            <Link to={"/users/" + audit.InchargeUserId}>
              {audit.InchargeUser}
            </Link>
          </Typography>}
          <Typography variant="body2" color="text.secondary">
            <b>{t(audit.ActivityType == "Meeting" || audit.ActivityType == "Workshop" ? 'ui.moderator': 'ui.auditor')}:</b>
            <Link to={"/users/" + audit.AuditorId}>{audit.Auditor}</Link>
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <b>{t('ui.plannedDate')}:</b>{" "}
            {new Date(audit.StartDate).toLocaleDateString()}
          </Typography>
          {audit.CompleteDate && (
            <Typography variant="body2" color="text.secondary">
              <b>{t('ui.completeDate')}:</b>{" "}
              {new Date(audit.CompleteDate).toLocaleDateString()}
            </Typography>
          )}
          <Typography variant="body2" color="text.secondary">
            <b>{t('ui.creationDate')}:</b>{" "}
            {new Date(audit.SystemDate).toLocaleDateString()}
          </Typography>
        </CardContent>
        {enabled && (
          <CardActions>
            <Button
              onClick={async () => {
                navigate("/audits/" + audit.Id);
              }}
              size="small"
              color="primary"
              // disabled={!enabled}
            >
             {getOpenDetailsText()} 
            </Button>
          </CardActions>
        )}
      </Card>
    </>
  );
}
