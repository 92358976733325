import * as React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
    selectDepartmentsWithPeriodicScores,
    selectDepartmentsWithPeriodicScoresYear,
} from "../../features/departments/departmentsSlice";
import { getChildren, months, sumSubArrayValues } from "../../utils/commonFunctions";

import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
    Title,
} from "chart.js";
import { Radar } from "react-chartjs-2";
import { Paper, Stack } from "@mui/material";
import { getRadarChartData } from "../../utils/commonFunctions";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { getKins } from "../../utils/commonFunctions";
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import MyTitle from "../common/Title"

ChartJS.register(
    Title,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
);

const options = (timePeriod) => {
    return {
        scale: {
            ticks: {
                beginAtZero: true,
                stepSize: 1,
            },
            r: {
                angleLines: {
                    display: false,
                },
                suggestedMin: 0,
                suggestedMax: 5,
            },
        },
        plugins: {
            legend: {
                display: true,
            },
            title: {
                display: false,
                text: `${timePeriod} 5S Skoru`,
            },
        },
    }
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: '#ff0000',
    },
}));

const BorderLinearProgressCompare = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: '#00ff00',
    },
}));

const Department5SPoints = ({ parent, currentMonth, yearly, currentYear }) => {
    const [compareDepartment, setCompareDepartment] = React.useState("");
    const [viewCompare, setViewCompare] = React.useState(false)
    const { t } = useTranslation()

    const departmentsWithPeriodicScores = useSelector(
        selectDepartmentsWithPeriodicScoresYear,
    );
    const sumValues = sumSubArrayValues(departmentsWithPeriodicScores, "Id")
    const correctData = yearly ? sumValues : departmentsWithPeriodicScores[currentMonth]
    const currentDepartmentData = correctData.find(d => d.Id == parent.Id)
    const currentDepartmentDataCorrected = {...currentDepartmentData, 
        S1: currentDepartmentData.CompletedAuditCnt ? currentDepartmentData.S1 / currentDepartmentData.CompletedAuditCnt : 0,
        S2: currentDepartmentData.CompletedAuditCnt ? currentDepartmentData.S2 / currentDepartmentData.CompletedAuditCnt : 0,
        S3: currentDepartmentData.CompletedAuditCnt ? currentDepartmentData.S3 / currentDepartmentData.CompletedAuditCnt : 0,
        S4: currentDepartmentData.CompletedAuditCnt ? currentDepartmentData.S4 / currentDepartmentData.CompletedAuditCnt : 0,
        S5: currentDepartmentData.CompletedAuditCnt ? currentDepartmentData.S5 / currentDepartmentData.CompletedAuditCnt : 0,
        S6: currentDepartmentData.CompletedAuditCnt ? currentDepartmentData.S6 / currentDepartmentData.CompletedAuditCnt : 0}
    const compareDepartmentData = correctData.find(d => d.Id == compareDepartment.Id)
    const compareDepartmentDataCorrected = compareDepartmentData ? {...compareDepartmentData, 
        S1: compareDepartmentData.CompletedAuditCnt ? compareDepartmentData.S1 / compareDepartmentData.CompletedAuditCnt : 0,
        S2: compareDepartmentData.CompletedAuditCnt ? compareDepartmentData.S2 / compareDepartmentData.CompletedAuditCnt : 0,
        S3: compareDepartmentData.CompletedAuditCnt ? compareDepartmentData.S3 / compareDepartmentData.CompletedAuditCnt : 0,
        S4: compareDepartmentData.CompletedAuditCnt ? compareDepartmentData.S4 / compareDepartmentData.CompletedAuditCnt : 0,
        S5: compareDepartmentData.CompletedAuditCnt ? compareDepartmentData.S5 / compareDepartmentData.CompletedAuditCnt : 0,
        S6: compareDepartmentData.CompletedAuditCnt ? compareDepartmentData.S6 / compareDepartmentData.CompletedAuditCnt : 0} : compareDepartmentData
    const availableDepartments = getKins(correctData, parent);

    function calculateAverage(d) {
        const S1 = d.S1 || 0;
        const S2 = d.S2 || 0;
        const S3 = d.S3 || 0;
        const S4 = d.S4 || 0;
        const S5 = d.S5 || 0;
        const S6 = d.S6 || 0;

        return (S1 + S2 + S3 + S4 + S5 + S6) / (100 * 6) || 0;
    }

    const getSProgressBar = (title, value, compareValue) => {
        value > 100 ? value = 100 : value = value
        compareValue > 100 ? compareValue = 100 : compareValue = compareValue
        return <Box>
            <Typography variant="body2" color="text.secondary">{title}</Typography>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <BorderLinearProgress variant="determinate"
                        value={value}
                        thickness={100} />
                </Box>
                <Typography variant="body2" color="text.secondary">{(value?value/20:0).toFixed(2)}</Typography>
            </Box>


            {viewCompare &&
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: '100%', mr: 1 }}>
                        <BorderLinearProgressCompare variant="determinate"
                        value={compareValue} />
                        </Box>
                        <Typography variant="body2" color="text.secondary">{(compareValue? compareValue/20:0).toFixed(2)}</Typography>
                    </Box>}


        </Box>
    }

    function getChartData() {
        const rdata = getRadarChartData(currentDepartmentDataCorrected);
        if (compareDepartment != "") {
            const compareRadarData = getRadarChartData(compareDepartmentDataCorrected);
            const ds = {
                label: compareDepartment.Adi,
                data: compareRadarData.datasets[0].data,
                backgroundColor: "rgba(0, 255, 0, 0.5)",
                borderColor: "rgba(0, 255, 0, 1)",
                borderWidth: 1,
            };
            rdata.datasets.push(ds);
        }
        return rdata
    }

    const handleChange = (event) => {
        if (event.target.value == "") {
            setViewCompare(false)
        }
        else {
            setViewCompare(true)
            const dep = correctData.find(d => d.Id == event.target.value)
            setCompareDepartment(dep);
        }


    };

   const info = t(yearly? 'titles.departments5SPointsYearly': 'titles.departments5SPointsMonthly', {currentMonth: t(`months.${currentMonth}`), currentDepartment: currentDepartmentData.Adi})
   const score = calculateAverage(currentDepartmentDataCorrected).toFixed(1)
  const title = `${info}: ${score}`


    return (
        <Paper elevation={12}>
            <Stack >
          <MyTitle title={title} month={currentMonth} year={currentYear} yearly={yearly} />
          
   
                   <Stack direction="row" justifyContent="space-evenly">
                <Stack direction="column">
                    {correctData && (
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel id="demo-select-small-label">{t('ui.compare')}</InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={compareDepartment.Id}
                                label={t('ui.compare')}
                                onChange={handleChange}
                            >
                                <MenuItem value={""}>
                                    <em>{t('ui.noComparison')}</em>
                                </MenuItem>
                                {availableDepartments.map((d) => {
                                    return <MenuItem value={d.Id}>{d.Adi}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>
                    )}

                    <Radar options={options(yearly ? "Yıllık" : `${months[currentMonth]} Ayı`)} data={getChartData()} />

                </Stack>
                <Stack direction="column" m={5} sx={{ width: '25%' }}>
                    {getSProgressBar(t('fiveSActivities.typesLower.tdys'), currentDepartmentDataCorrected.S1 / 5, compareDepartmentDataCorrected?.S1 / 5)}
                    {getSProgressBar(t('fiveSActivities.typesLower.orgn'), currentDepartmentDataCorrected.S2 / 5, compareDepartmentDataCorrected?.S2 / 5)}
                    {getSProgressBar(t('fiveSActivities.typesLower.clns'), currentDepartmentDataCorrected.S3 / 5, compareDepartmentDataCorrected?.S3 / 5)}
                    {getSProgressBar(t('fiveSActivities.typesLower.stdn'), currentDepartmentDataCorrected.S4 / 5, compareDepartmentDataCorrected?.S4 / 5)}
                    {getSProgressBar(t('fiveSActivities.typesLower.dcln'), currentDepartmentDataCorrected.S5 / 5, compareDepartmentDataCorrected?.S5 / 5)}
                    {getSProgressBar(t('fiveSActivities.typesLower.isg'), currentDepartmentDataCorrected.S6 / 5, compareDepartmentDataCorrected?.S6 / 5)}
                </Stack>
            </Stack>
            </Stack>
           
        </Paper>
    )
};

export default Department5SPoints;
