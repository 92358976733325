import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  inChargeUserId: null,
  department: null,
  status: null, // past, upcoming, completed, approved
  type: null, // Audit, Instant, Workshop, FieldTour
};

const findingFiltersSlice = createSlice({
  name: "findingFilters",
  initialState,
  reducers: {
    setInchargeUserId: (state, action) => {
      state.inChargeUserId = action.payload;
    },
    setDepartment: (state, action) => {
      state.department = action.payload;
    },

    setStatus: (state, action) => {
      state.status = action.payload;
    },

    setType: (state, action) => {
      state.type = action.payload;
    },
  },
});

const { setInchargeUserId, setDepartment, setStatus, setType } =
  findingFiltersSlice.actions;
export const setFilter = {
  inChargeUserId: setInchargeUserId,
  department: setDepartment,
  status: setStatus,
  type: setType,
};
export const selectFindingFilters = (state) => state.ui.findingFilters;
export default findingFiltersSlice.reducer;
