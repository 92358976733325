import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  FormControl,
  InputLabel,
  Stack,
  TextField,
  MenuItem,
  Select,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import Typography from "@mui/material/Typography";
import FindingsService from "../../services/FindingsService";
import {
  MagicDropZoneComponent,
  uploadImage,
} from "../../utils/commonFunctions";
import { useDispatch, useSelector } from "react-redux";
import { selectDepartments } from "../../features/departments/departmentsSlice";
import { selectDialog, dialogActions } from "../../features/ui/dialogsSlice";
import DepartmentUserSelect from "../common/DepartmentUserSelect";
import { useTranslation } from "react-i18next";

export default function InsertFindingDialog(props) {
  const dialogVisibility = useSelector(selectDialog);
  const departments = useSelector(selectDepartments);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const defaultValues = {
    inChargeUserId: props.user || "",
    department:
      departments.find((item) => item.Id === props.departmentId) || null,
    date: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000),
    fiveSType: "",
    description: "",
    image: "",
  };

  const [formValues, setFormValues] = useState(defaultValues);
  const [progress, setProgress] = useState(0);
  const { t } = useTranslation()

  function hideDialog() {
    dispatch(dialogActions.toggleInsertFinding(0));
    setFormValues(defaultValues);
    setError(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formValues.image && !props.nophoto) {
      return;
    }

    let imageId = null;
    if (!props.nophoto) {
      imageId = await uploadImage(formValues.image, setProgress);
    }

    const obj = {
      ...formValues,
      mainActivityId: props.mainActivityId,
      activityQuestionId: dialogVisibility.questionId,
      departmentId: formValues.department.Id,
      image: imageId,
    };
    delete obj["department"];
    console.log(obj);
    const response = FindingsService.insertFinding({ ...obj }).then(() => {
      props.callback(response);
      hideDialog();
      setProgress(0);
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  return (
    <div>
      <Dialog open={dialogVisibility.insertFinding} fullWidth>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={Boolean(progress)}
        >
          <CircularProgress
            variant="determinate"
            value={progress}
            color="primary"
          />
        </Backdrop>
        <DialogTitle>{t('findings.addFinding')}</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Stack gap={2}>
              <DepartmentUserSelect
                {...props}
                required={true}
                error={error}
                valueDepartment={formValues.department}
                onChangeDepartment={(event, newValue) => {
                  setFormValues({ ...formValues, department: newValue });
                }}
                valueUser={formValues.inChargeUserId}
                onChangeUser={(value) =>
                  setFormValues({ ...formValues, inChargeUserId: value })
                }
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={t('ui.deadlineDate')}
                  inputFormat="DD/MM/YYYY"
                  value={formValues.date}
                  onChange={(date) => {
                    setFormValues({ ...formValues, date: date });
                  }}
                  renderInput={(params) => <TextField required {...params} />}
                />
              </LocalizationProvider>
              {(dialogVisibility.questionId === undefined ||
                dialogVisibility.questionId == 0) && (
                <div>
                  <FormControl fullWidth>
                    <InputLabel>{t('ui.fiveSType')}</InputLabel>
                    <Select
                      size="small"
                      label={t('ui.fiveSType')}
                      name="fiveSType"
                      required
                      error={error && !formValues.fiveSType}
                      value={formValues.fiveSType}
                      onChange={handleInputChange}
                    >
                      <MenuItem key={null} value="">
                        None
                      </MenuItem>
                      <MenuItem key="c" value="TERTIP">
                        {t('fiveSActivities.types.TERTIP')}
                      </MenuItem>
                      <MenuItem key="b" value="DUZEN">
                        {t('fiveSActivities.types.DUZEN')}
                      </MenuItem>
                      <MenuItem key="a" value="TEMIZLIK">
                        {t('fiveSActivities.types.TEMIZLIK')}
                      </MenuItem>
                      <MenuItem key="e" value="STANDARTLASTIRMA">
                        {t('fiveSActivities.types.STANDARTLASTIRMA')}
                      </MenuItem>
                      <MenuItem key="d" value="DISIPLIN">
                        {t('fiveSActivities.types.DISIPLIN')}
                      </MenuItem>
                      <MenuItem key="f" value="ISG">
                        {t('fiveSActivities.types.ISG')}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              )}
              {!props.nophoto && (
                <div>
                  <Typography>{t('ui.photo')}</Typography>
                  <MagicDropZoneComponent
                    setImage={(image) =>
                      setFormValues({ ...formValues, image: image })
                    }
                  />
                  {error && !formValues.image && (
                    <Typography color="error">{t('ui.photoWarning')}</Typography>
                  )}
                </div>
              )}
              <TextField
                label={t('ui.desc')}
                name="description"
                onChange={handleInputChange}
                multiline
                required
                error={error && !formValues.description}
                rows={4}
                value={formValues.description}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="secondary" onClick={hideDialog}>
              {t('buttons.cancel')}
            </Button>
            <Button
              variant="outlined"
              type="submit"
              onClick={() => setError(true)}
            >
              {t('buttons.ok')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
