import { GridToolbarExportContainer } from "@mui/x-data-grid";
import { ExportMenuItem } from "./ExportMenuItem";
import * as React from "react";
import { ExportPdf } from "./ExportPdf";

export function ExportButton(props) {
  return (
    <GridToolbarExportContainer {...props}>
      <ExportMenuItem {...props} />
      <ExportPdf {...props} />
    </GridToolbarExportContainer>
  );
}
