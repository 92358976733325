import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AuditFilter from "./AuditFilterBar";

export function FilterAuditsDialog(props) {
  return (
    <div>
      <Dialog open={props.show} fullWidth>
        <DialogTitle>Denetimleri Filtrele</DialogTitle>
        <DialogContent>
          <AuditFilter />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={props.onClose}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
