import { useSelector } from "react-redux";
import { selectWorkshops } from "../../features/audits/auditsSlice";
import AuditListComponent from "../MainActivity/Audit/AuditListComponent";

const Workshop = () => {
  const workshops = useSelector(selectWorkshops);

  return (
    <div>
      <AuditListComponent audits={workshops} />
    </div>
  );
};

export default Workshop;
