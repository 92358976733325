const config = {
  columnNames: [
    "Bulgu No",
    "Departman",
    "Sorumlu Kişi",
    "Denetçi",
    "İlgili Soru",
    "Açıklama",
    "Aksiyon",
    "Oluşturulma Tarihi",
    "Termin Tarihi",
    "Tamamlanma Tarihi",
  ],
  keys: [
    "Id",
    "Department",
    "InchargeUser",
    "AuditorUser",
    "question",
    "Dsc",
    "DscOk",
    "SystemDate",
    "DueDate",
    "CompletedDate",
  ],
  pdfHeaders: [
    { header: "Bulgu No", dataKey: "Id" },
    { header: "Sorumlu / Denetçi", dataKey: "InchargeUser" },
    { header: "Departman", dataKey: "Department" },
    { header: "İlgili Soru", dataKey: "question" },
    { header: "Açıklama", dataKey: "Dsc" },
    { header: "Aksiyon", dataKey: "DscOk" },
    { header: "Oluşturulma Tarihi", dataKey: "SystemDate" },
    { header: "Termin / Tamamlanma", dataKey: "DueDate" },
    { header: "Öncesi", dataKey: "imagebefore" },
    { header: "Sonrası", dataKey: "imageafter" },
  ],
  fileName: "Bulgular.xlsx",
  sheetName: "Bulgular",
};

export { config };
