import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Typography, Stack } from "@mui/material";
import { Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { DateFormat } from "../../../utils/DateFormat";
import { useDispatch } from "react-redux";
import { deleteAudit } from "../../../features/audits/auditsSlice";
import { showNotification } from "../../../features/ui/notificationSlice";
import { showConfirmDialog } from "../../../features/ui/confirmDialogSlice";
import {
  currentUserIsAdmin,
  userHasPermission,
} from "../../../utils/authFunctions";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { red } from "@mui/material/colors";
import UpdateAuditDialog from "./UpdateAuditDialog";
import { dialogActions } from "../../../features/ui/dialogsSlice";
import { LoginService } from "../../../services";
import { isAuditCompleted } from "../../../utils/commonFunctions";
import { useTranslation } from "react-i18next";

export default function AuditListComponent({ audits }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const deletePermission = userHasPermission("delete");
  const updatePermission = userHasPermission("update");
  const { t } = useTranslation()

  function getOpenDetailsText(activityType) {
    if (["Audit", "Workshop", "FieldTour", "Meeting"].includes(activityType)) {
      return t(`tcard.openActivity.${activityType}`)
    }
    else return t('tcard.openActivity.unknown');
  }

  const toggleDetails = (audit) => {
    dispatch(dialogActions.toggleAuditDetail(audit));
  };

  const handleDeleteAudit = async (id) => {
    var result = await showConfirmDialog(
      dispatch,
      t('confirm.deleteAudit'),
    );
    if (result) {
      deleteAudit(dispatch, id)
        .then(() => {
          showNotification(dispatch, t('notification.auditDeletedSuccess'), "primary");
        })
        .catch(() => {
          showNotification(
            dispatch,
            t('notification.auditDeletedError'),
            "danger",
          );
        });
    }
  };

  function isFieldVisible(fieldName) {
    if (!audits || audits.length == 0)
      return true;

    const audit = audits[0]

    if (fieldName == "Department") {
      if (audit.ActivityType == "FieldTour" || audit.ActivityType == "Meeting")
        return false;
    }

    if (fieldName == "InchargeUser") {
      if (audit.ActivityType == "FieldTour" || audit.ActivityType == "Meeting" || audit.ActivityType == "Workshop")
        return false;
    }

    if (fieldName == "Score") {
      if (audit.ActivityType!="Audit")
        return false;
    }

    return true;
  }

  function getAuditorHeaderText(fieldName) {
      if (!audits || audits.length == 0)
        return t('ui.auditor');
      const audit = audits[0]
      return t(audit.ActivityType == "Meeting" || audit.ActivityType == "Workshop" ? 'ui.moderator': 'ui.auditor')
  }

  const columns = [
    {
      visible:true,
      field: "Id",
      headerName: "Id",
      filterable: false,
      maxWidth: 60,
      renderCell: (params) => (
        <Typography
          className="pointer"
          variant="p"
          onClick={async () => {
            navigate("/audits/" + params.row.Id);
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      visible:true,
      headerName: getAuditorHeaderText(),
      field: "Auditor",
      minWidth: 150,
    },
    {
      visible:isFieldVisible("InchargeUser"),
      headerName: t('ui.responsibleUser'),
      field: "InchargeUser",
      minWidth: 150,
    },
    {
      visible:isFieldVisible("Department"),
      headerName: t('ui.department'),
      field: "Department",
      minWidth: 150,
    },
    {
      visible:true,
      headerName: t('ui.plannedDate'),
      field: "StartDate",
      minWidth: 150,
      renderCell: (params) => DateFormat.dayMonthYear(params.value),
    },
    {
      visible:isFieldVisible("Score"),
      headerName: t('ui.score'),
      field: "Score",
      minWidth: 100,
      renderCell: (params) => (params.value ? params.value / 100 : 0),
    },
    {
      visible:true,
      headerName: t('ui.status'),
      field: "CompleteDate",
      minWidth: 150,
      renderCell: (params) => (t(params.value ? "ui.completed" : "ui.notCompleted")),
    },
    {
      visible:true,
      headerName: t('ui.actions'),
      minWidth: 200,
      renderCell: (params) => {
        const currentUserId = LoginService.getUser().UserId;
        const isAdmin = currentUserIsAdmin();
        const isAuditor = params.row.AuditorId == currentUserId;
        const isResponsibleUser = params.row.InchargeUserId == currentUserId;
        const completed = isAuditCompleted(params.row);
        const enabled =
          isAdmin || isAuditor || (completed && isResponsibleUser);

        return (
          <div>
            <Stack direction="row" spacing={3}>
              <Button
                variant="contained"
                disabled={!enabled}
                onClick={async () => {
                  navigate("/audits/" + params.row.Id);
                }}
              >
                {getOpenDetailsText(params.row.ActivityType)}
              </Button>

              {updatePermission && 
              params.row.ActivityType == "Audit" && 
              params.row.CompleteDate == null && (
                <IconButton
                  aria-label="update"
                  onClick={async () => {
                    toggleDetails(params.row);
                  }}
                >
                  <EditIcon color="primary" />
                </IconButton>
              )}

              {deletePermission && (
                <IconButton
                  aria-label="delete"
                  onClick={async () => {
                    handleDeleteAudit(params.row.Id);
                  }}
                >
                  <DeleteIcon sx={{ color: red[500] }} />
                </IconButton>
              )}
            </Stack>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <DataGrid
        autoHeight
        rows={audits}
        columns={columns.filter(col => col.visible)}
        getRowId={(row) => row.Id}
        getRowHeight={() => "auto"}
      />
      <UpdateAuditDialog />
    </>
  );
}
