import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import DepartmentService from "../../services/DepartmentServices.js";
import LoginService from "../../services/LoginServices.js";
import PortalStatisticsService from "../../services/PortalStatisticsService.js";
import {
  getChildren,
  getDescendants,
  parentDepartments,
} from "../../utils/commonFunctions.js";
import fuzzysort from "fuzzysort";

const initialState = {
  allDepartments: [],
  comparableDepartments: [],
  departmentsVisibleToCurrentUser: [],
  departmentsOfCurrentUser: [],
  departmentsWithPeriodicScores: [],
  departmentsWithPeriodicScoresYear: [],
};

const departmentsSlice = createSlice({
  name: "departments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDepartments.fulfilled, (state, action) => {
      const currentUser = LoginService.getUser();
      const roles = currentUser.roles;

      let comparableDepartments = [];
      let departmentsVisibleToCurrentUser = []
      state.departmentsOfCurrentUser = roles.map(role=>action.payload.find(d=>d.Id == role.DepartmentId))
      for (let index = 0; index < roles.length; index++) {
        const role = roles[index];

        if (role.RoleCode === "DE") {
          continue;
        }
        if (role.RoleCode === "MO") {
          state.allDepartments = action.payload;
          state.comparableDepartments = action.payload;
          state.departmentsVisibleToCurrentUser = action.payload;
          return;
        }

        if (role.RoleCode === "" || role.DepartmentId == 0) {
          continue;
        }

        const currentDepartment = action.payload.find(
          (d) => d.Id == role.DepartmentId,
        );
        const siblings = getChildren(
          action.payload,
          currentDepartment.master_id,
        );
        const descendants = getDescendants(
          action.payload,
          currentDepartment.Id,
        );
        const parents = parentDepartments(action.payload, currentDepartment.Id);
        comparableDepartments.push(...parents);
        comparableDepartments.push(...siblings);
        comparableDepartments.push(...descendants);
        departmentsVisibleToCurrentUser.push(...descendants);
        departmentsVisibleToCurrentUser.push(currentDepartment)
      }

      state.allDepartments = action.payload;

      const uniqueDepartments = [...new Map(comparableDepartments.map(v => [v.Id, v])).values()]
      const uniqueDepartments2 = [...new Map(departmentsVisibleToCurrentUser.map(v => [v.Id, v])).values()]
      state.comparableDepartments = uniqueDepartments;
      state.departmentsVisibleToCurrentUser = uniqueDepartments2;
    });

    builder.addCase(
      fetchDepartmentsWithPeriodicScores.fulfilled,
      (state, action) => {
        state.departmentsWithPeriodicScores = action.payload;
      },
    );

    builder.addCase(
      fetchDepartmentsWithPeriodicScoresYear.fulfilled,
      (state, action) => {
        state.departmentsWithPeriodicScoresYear = Object.values(action.payload);
      },
    );
  },
});

export const fetchDepartments = createAsyncThunk(
  "departments/fetchDepartments",
  async () => {
    const response = await DepartmentService.getAll();
    const scores =
      await PortalStatisticsService.getPeriodicScoresForDepartments();

    // update department scores with score service
    const updatedDepartmentsWithScores = response.map((o1) => {
      const d = scores.Table.filter((o2) => o2.Id === o1.Id)[0];
      return d
        ? {
            ...o1,
            S1: d.S1 / d.AuditCnt,
            S2: d.S2 / d.AuditCnt,
            S3: d.S3 / d.AuditCnt,
            S4: d.S4 / d.AuditCnt,
            S5: d.S5 / d.AuditCnt,
            S6: d.S6 / d.AuditCnt,
            Score: d.AuditCnt
              ? (d.S1 + d.S2 + d.S3 + d.S4 + d.S5 + d.S6) / (6 * d.AuditCnt)
              : 0,
            AuditCnt: d.AuditCnt ? d.AuditCnt : 0,
          }
        : o1;
    });

    return updatedDepartmentsWithScores;
  },
);

export const fetchDepartmentsWithPeriodicScores = createAsyncThunk(
  "departments/fetchDepartmentsWithPeriodicScores",
  async ({ start, end }) => {
    const response = await DepartmentService.getAll();
    const scores =
      await PortalStatisticsService.getPeriodicScoresForDepartments(start, end);

    // update department scores with score service
    const updatedDepartmentsWithScores = response.map((o1) => {
      const d = scores.Table.filter((o2) => o2.Id === o1.Id)[0];
      return d
        ? {
            ...o1,
            ...d,
            S1: d.S1 / d.AuditCnt,
            S2: d.S2 / d.AuditCnt,
            S3: d.S3 / d.AuditCnt,
            S4: d.S4 / d.AuditCnt,
            S5: d.S5 / d.AuditCnt,
            S6: d.S6 / d.AuditCnt,
            Score: d.AuditCnt
              ? (d.S1 + d.S2 + d.S3 + d.S4 + d.S5 + d.S6) / (6 * d.AuditCnt)
              : 0,
            AuditCnt: d.AuditCnt ? d.AuditCnt : 0,
          }
        : o1;
    });

    return updatedDepartmentsWithScores;
  },
);

export const fetchDepartmentsWithPeriodicScoresYear = createAsyncThunk(
  "departments/fetchDepartmentsWithPeriodicScoresYear",
  async (year) => {
    const response = await DepartmentService.getAll();
    const scores =
      await PortalStatisticsService.getPeriodicScoresForDepartmentsYear(year);
    const updatedDepartmentsWithScores = Object.values(scores).map((year) =>
      year.map((o2) => {
        const d = response.filter((o1) => o1.Id == o2.Id)[0];
        return {
          ...d,
          ...o2,
          S1: o2.S1,
          S2: o2.S2,
          S3: o2.S3,
          S4: o2.S4,
          S5: o2.S5,
          S6: o2.S6,
          AuditCnt: o2.AuditCnt ? o2.AuditCnt : 0,
        };
      }),
    );
    return updatedDepartmentsWithScores;
  },
);

export const selectDepartments = (state) => state.departments.allDepartments;
export const selectComparableDepartments = (state) =>
  state.departments.comparableDepartments;

export const selectDepartmentsVisibleToCurrentUser = (state) =>
  state.departments.departmentsVisibleToCurrentUser;


export const selectFilteredDepartments = (state) => {
  const filterText = state.ui.listFilters.filterText;
  const result = fuzzysort.go(filterText, state.departments.departmentsVisibleToCurrentUser, {
    keys: ["Adi", "FullName"],
    all: true,
    threshold: -999,
    // Create a custom combined score to sort by. -100 to the Email score makes it a worse match
    scoreFn: (a) =>
      Math.max(a[0] ? a[0].score : -1000, a[1] ? a[1].score - 100 : -1000),
  });
  return result;
};

export const selectDepartmentsOfCurrentUser = (state) =>
  state.departments.departmentsOfCurrentUser;
export const selectDepartmentsWithPeriodicScores = (state) =>
  state.departments.departmentsWithPeriodicScores;
export const selectDepartmentsWithPeriodicScoresYear = (state) =>
  state.departments.departmentsWithPeriodicScoresYear;

export default departmentsSlice.reducer;
