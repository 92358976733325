import { useSelector } from "react-redux";
import {
  selectDepartmentsWithPeriodicScores,
  selectDepartmentsWithPeriodicScoresYear,
} from "../../features/departments/departmentsSlice";
import { getChildren, months, sumSubArrayValues } from "../../utils/commonFunctions";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import { FormControlLabel, Paper, Switch } from "@mui/material";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import MyTitle from "../common/Title"

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin,
);



const options = (/* title,  */clickHandler, stacked) => {
  return {

    onClick: clickHandler,
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      // title: {
      //   display: true,
      //   text: title,
      // },

      //   annotation: {
      //     annotations: {
      //       line1: {
      //         // Indicates the type of annotation
      //         type: "line",
      //         yMin: 4,
      //         yMax: 4,
      //         backgroundColor: "rgba(255, 99, 132, 0.25)",
      //       },
      //       label1: {
      //         type: "label",
      //         yValue: 4.2,
      //         backgroundColor: "rgba(245,245,245, 0.25)",
      //         content: ["Minimum Hedef"],
      //         font: {
      //           size: 18,
      //         },
      //       },
      //     },
      //   },
    },
    scales: {
        x: {
          stacked: stacked,
        },
        y: {
          stacked: stacked,
        },
      },
  }
};

const SubDepartmentsAuditCounts = ({ parent, currentMonth, yearly, onDepartmentChange , currentYear}) => {
  const { t } = useTranslation()
  const departmentsWithPeriodicScores = useSelector(
    selectDepartmentsWithPeriodicScoresYear,
  );
  const sumValues = sumSubArrayValues(departmentsWithPeriodicScores, "Id")
  const correctData = yearly ? sumValues : departmentsWithPeriodicScores[currentMonth]
  const [stacked, setStacked] = useState(true)
  const data = getChildren(
    correctData,
    parent.Id
  );
  const self = correctData.find(
    (d) => d.Id == parent.Id
  );
  data.unshift(self);

  const ref = useRef(null)


  function clickHandler(evt) {
    const points = ref.current.getElementsAtEventForMode(evt, 'nearest', { intersect: true }, true);

    if (points.length) {
      const firstPoint = points[0];
      const value = ref.current.data.datasets[firstPoint.datasetIndex].ids[firstPoint.index]
      onDepartmentChange(value, value == self.Id)
    }
  }

  function prepareData() {
    return data.map((d) => {
      return { id: d.Id, label: d.Adi, value: d.AuditCnt, notCompleted: d.AuditCnt - d.CompletedAuditCnt, completed: d.CompletedAuditCnt };
    });
  }

  function getChartData() {
    const unsortedData = prepareData()
    const firstItem = unsortedData[0]
    const chartData =  unsortedData.slice(1).sort((a, b) => {
      if (a.value > b.value) return -1;
      else if (a.value < b.value) return 1;
      else return 0;
    });
    chartData.unshift(firstItem)

    return {
      labels: chartData.map((d) => d.label),
      datasets: [
        {
          label: t('ui.shortHeaders.wasCompleted'),
          data: chartData.map((d) => d.completed),
          ids: chartData.map((d) => d.id),
          borderColor: "rgb(185, 250, 70)",
          backgroundColor: "rgba(185,235,73, 0.5)",
          maxBarThickness: 100,
        },
        {
          label: t('ui.shortHeaders.wasNotCompleted'),
          data: chartData.map((d) => d.notCompleted),
          ids: chartData.map((d) => d.id),
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(241,67,63, 0.5)",
          maxBarThickness: 100,
        },
      ],
    };
  }
  const title = t(yearly ? 'titles.subDepAuditCountsYearly' : 'titles.subDepAuditCountsMonthly', {currentMonth: t(`months.${currentMonth}`)})
  console.log('current month is ', currentMonth)

  return data.length ? (
    <Paper elevation={12}>
      <MyTitle title={title} month={currentMonth} year={currentYear} yearly={yearly}/>
      <FormControlLabel sx={{float: "right"}} control={<Switch defaultChecked value={stacked} onChange={(e)=>setStacked(e.target.checked)}/>} label={t('ui.stackedGraph')} />
      <Bar ref={ref} options={options(clickHandler, stacked)} data={getChartData()} />
    </Paper>
  ) : (
    <div></div>
  );
};

export default SubDepartmentsAuditCounts;
