import { useEffect, useState } from "react";
import { Button, Typography, Stack, Grid, Switch } from "@mui/material";
import { AuditService } from "../../../services";
import InsertFindingDialog from "../../Findings/InsertFindingDialog";
import DetailsDialog from "../../Findings/DetailsDialog";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import FindingsService from "../../../services/FindingsService";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { dialogActions } from "../../../features/ui/dialogsSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentAudit } from "../../../features/audits/currentAuditSlice";
import { fetchCurrentAudit } from "../../../features/audits/currentAuditSlice";
import FindingsList from "../../Findings/FindingsList";
import { setAuditQuestionNA } from "../../../features/audits/currentAuditSlice";
import { red } from '@mui/material/colors';
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

function AuditQuestionComponent({ show, onClose, onNext, onPrevious }) {
  const { auditInfo, data, currentQuestionIndex, previousAuditFindingsToBeApproved } =
    useSelector(selectCurrentAudit);
  const [mainQuestion, setMainQuestion] = useState([]);
  const [auditFindings, setAuditFindings] = useState([]);
  const [subQuestions, setSubQuestions] = useState([]);
  const [isNA, setIsNA] = useState(false);
  const [needsRender, setNeedsRender] = useState(false);
  const { t } = useTranslation()

  const dispatch = useDispatch();

  const handleClose = () => onClose();
  const handleNext = () => onNext();

  const toggleInsertFindingDialog = () =>
    dispatch(dialogActions.toggleInsertFinding(data[currentQuestionIndex].Id));

  function SubQuestion(q) {
    function updateSubQuestionResult(id, value) {
      AuditService.setAuditResponseValue(id, value)
        .then(() =>
          AuditService.getAuditQuestionById(data[currentQuestionIndex].Id),
        )
        .then((response) => {
          setMainQuestion(response.Table);
          setSubQuestions(response.Table1);
          setAuditFindings(response.Table2);
          dispatch(fetchCurrentAudit(auditInfo.Id));
        });
    }

    return (
      <Stack key={q.AQId}>
        <FormControl key={q.AQId}>
          <FormLabel id="demo-row-radio-buttons-group-label">
            {q.question}
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={(event, value) => updateSubQuestionResult(q.AQId, value)}
            value={q.Response ? q.Response.toString() : "0"}
          >
            <FormControlLabel
              value="1"
              control={<Radio />}
              label="Evet"
              disabled={auditInfo.CompleteDate}
            />
            <FormControlLabel
              value="2"
              control={<Radio />}
              label="Hayır"
              disabled={auditInfo.CompleteDate}
            />
            <FormControlLabel
              value="3"
              control={<Radio />}
              label="N/A"
              disabled={auditInfo.CompleteDate}
            />
          </RadioGroup>
        </FormControl>
      </Stack>
    );
  }

  useEffect(() => {
    let mainQuestionId = data[currentQuestionIndex].Id;
    AuditService.getAuditQuestionById(mainQuestionId).then((response) => {
      setMainQuestion(response.Table);
      setSubQuestions(response.Table1);
      setAuditFindings(response.Table2);
      setIsNA(response.Table[0].Response == -1);
    });
  }, [currentQuestionIndex, auditInfo, needsRender]);

  return (
    <>
      <DetailsDialog />

      <Dialog open={show} fullWidth>
        <DialogTitle>
          <Stack>
            <Stack direction="horizontal" justifyContent="space-between">
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                {mainQuestion.length > 0 && mainQuestion[0].question}
              </Typography>

              <FormControlLabel
                control={
                  <Switch
                    disabled={auditInfo.CompleteDate}
                    checked={isNA}
                    onChange={(e) => {
                      dispatch(
                        setAuditQuestionNA({
                          id: mainQuestion[0].AQId,
                          value: e.target.checked,
                        }),
                      ).then(() => setNeedsRender(!needsRender));
                    }}
                    name="gilad"
                  />
                }
                label="N/A"
              />
              {mainQuestion[0]?.PrevId &&

              <Tooltip title={t('audits.approvePreviousFindings')}>
              <WarningAmberIcon sx={{ color: red[500] }} />
              </Tooltip>


              }
            </Stack>

            {!isNA && (
              <Typography>
                Score:{" "}
                {mainQuestion.length > 0 && mainQuestion[0].Response / 100}
              </Typography>
            )}
          </Stack>
        </DialogTitle>
        {!isNA && (
          <DialogContent>
            
            {auditInfo.CompleteDate == null &&
            mainQuestion[0]?.PrevId && 
            previousAuditFindingsToBeApproved.filter(f=>mainQuestion[0].QId==f.QuestionId)?.length > 0 && (
              <>
                <Grid item xs={12} md={4}>
                  <h4>Önceki Denetimlerden Onay Bekleyen Bulgular</h4>
                  <FindingsList data={previousAuditFindingsToBeApproved.filter(f=>mainQuestion[0].QId==f.QuestionId)} listView={true} />
                </Grid>
              </>
            )}

            <Stack>
            <h4>Alt Sorular:</h4>
              {subQuestions.map((subquestion) => SubQuestion(subquestion))}
            </Stack>

            <Button
              disabled={auditInfo.CompleteDate || isNA}
              className="m-3"
              variant="contained"
              onClick={toggleInsertFindingDialog}
            >
              Bulgu Ekle
            </Button>

            {auditFindings?.length > 0 && (
              <>
                <Grid item xs={12} md={4}>
                  <h4>Soru İçin Açılan Bulgular</h4>
                  <FindingsList data={auditFindings} listView={true} />
                </Grid>
              </>
            )}


          </DialogContent>
        )}
        <DialogActions>
          {currentQuestionIndex > 0 && (
            <Button variant="contained" className="m-3" onClick={onPrevious}>
              {t('audits.previousQuestion')}
            </Button>
          )}
          {currentQuestionIndex < data.length - 1 && (
            <Button variant="contained" className="m-3" onClick={handleNext}>
              {t('audits.nextQuestion')}
            </Button>
          )}
          <Button variant="contained" className="m-3" onClick={handleClose}>
              {t('buttons.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AuditQuestionComponent;
