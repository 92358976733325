import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoginService } from "../../services";
import AuditService from "../../services/AuditService";
import { userHasPermission } from "../../utils/authFunctions";
import {
  auditAboutToExpire,
  auditHasOpenFinding,
  getDescendants,
  isAuditCompleted,
  isAuditExpired,
} from "../../utils/commonFunctions";

import { DateFormat } from "../../utils/DateFormat";
import { dialogActions } from "../ui/dialogsSlice";

const initialState = {
  periodicAudits: [],
  nonCompleteAudits: [],
  allAudits: [],
};

export default function auditsReducer(state = initialState, action) {
  switch (action.type) {
    case "audits/auditAdded": {
      return state;
    }
    case "audits/periodicAuditAdded": {
      return state;
    }
    case "audits/updateAudit/fulfilled": {
      return state;
    }
    case "audits/auditsLoaded": {
      const user = LoginService.getUser();
      const userId = user.UserId;
      const userName = user.LongName;
      const viewOthersPermission = userHasPermission("viewOthers");
      if (!viewOthersPermission) {
        return {
          ...state,
          allAudits: action.payload.filter(
            (a) => a.InchargeUser == userName || a.AuditorId == userId
          ),
        };
      }
      return { ...state, allAudits: action.payload };
    }
    case "audits/periodicAuditsLoaded": {
      return { ...state, periodicAudits: action.payload };
    }

    case "audits/nonCompleteAuditsLoaded": {
      return { ...state, nonCompleteAudits: action.payload };
    }
    default:
      return state;
  }
}

function getFilterType(audit) {
  const type = auditHasOpenFinding(audit)
    ? "1"
    : isAuditCompleted(audit)
    ? "2"
    : isAuditExpired(audit)
    ? "3"
    : auditAboutToExpire(audit)
    ? "4"
    : "5";

  return type;
}

export async function fetchAudits(dispatch, getState) {
  const start = getState().ui.auditView.start;
  const end = getState().ui.auditView.end;
  const response = await AuditService.getAudits(
    DateFormat.convert2ddmmyyyy(start),
    DateFormat.convert2ddmmyyyy(end)
  );
  dispatch({ type: "audits/auditsLoaded", payload: response.Table });
}

export async function fetchNonCompleteAudits(dispatch, getState) {
  const response = await AuditService.getNonCompleteAudits();
  dispatch({
    type: "audits/nonCompleteAuditsLoaded",
    payload: response.Table.sort(
      (a, b) => new Date(b.StartDate) - new Date(a.StartDate)
    ),
  });
}

export async function fetchPeriodicAudits(dispatch, getState) {
  const response = await AuditService.getPeriodicAudits();
  dispatch({
    type: "audits/periodicAuditsLoaded",
    payload: response.Table.sort(
      (a, b) => new Date(b.StartDate) - new Date(a.StartDate)
    ),
  });
}

export async function addPeriodicAudit(dispatch, values) {
  await AuditService.createPeriodicAudit(values);
  fetchPeriodicAudits(dispatch, null);
}

export async function addAudit(dispatch, values) {
  await AuditService.createNewAudit(values);
  dispatch(fetchAudits);
}

export async function deleteAudit(dispatch, id) {
  await AuditService.deleteAuditById(id);
  dispatch(fetchAudits);
  fetchPeriodicAudits(dispatch, null);
}

export const updateAudit = createAsyncThunk(
  "audits/updateAudit",
  async (data, thunkAPI) => {
    const response = await AuditService.updateAudits(data);
    thunkAPI.dispatch(fetchAudits);
    thunkAPI.dispatch(dialogActions.updateCurrentAudit(response.Table[0]));
    return response[0];
  }
);

function filterByFilterType(state, audits) {
  const filterType = state.ui.auditView.filter;
  return audits.filter((audit) => {
    if (filterType == 0) return true;
    else return getFilterType(audit) === filterType;
  });
}

function filterByDepartment(state, audits) {
  const selectedDepartmentId = state.ui.auditView.selectedDepartment?.Id;
  const descendants = getDescendants(
    state.departments.allDepartments,
    selectedDepartmentId
  );
  return audits.filter((audit) => {
    const isDescendant =
      descendants.findIndex((d) => d.Id == audit.DepartmentId) != -1;
    return (
      audit.MasterId != -1 &&
      audit.DepartmentId &&
      (selectedDepartmentId == undefined ||
        audit.DepartmentId == selectedDepartmentId ||
        isDescendant)
    );
  });
}

function filterByAllFilters(state, audits) {
  return filterByFilterType(state, filterByDepartment(state, audits));
}

export const selectAudits = (state) => {
  return filterByAllFilters(state, state.audits.allAudits).filter(
    (a) => a.ActivityType == "Audit"
  );
};

export const selectNonCompleteAudits = (state) => {
  return filterByDepartment(state, state.audits.nonCompleteAudits).filter(
    (a) => a.ActivityType == "Audit"
  );
};

export const selectPeriodicActivities = (state) => state.audits.periodicAudits;
export const selectFieldTours = (state) => {
  return filterByAllFilters(state, state.audits.allAudits).filter(
    (a) => a.ActivityType == "FieldTour"
  );
};

export const selectNonCompleteFieldTours = (state) => {
  return filterByDepartment(state, state.audits.nonCompleteAudits).filter(
    (a) => a.ActivityType == "FieldTour"
  );
};

export const selectWorkshops = (state) => {
  return filterByAllFilters(state, state.audits.allAudits).filter(
    (a) => a.ActivityType == "Workshop"
  );
};

export const selectNonCompleteWorkshops = (state) => {
  return filterByDepartment(state, state.audits.nonCompleteAudits).filter(
    (a) => a.ActivityType == "Workshop"
  );
};

export const selectMeetings = (state) => {
  return filterByAllFilters(state, state.audits.allAudits).filter(
    (a) => a.ActivityType == "Meeting"
  );
};

export const selectNonCompleteMeetings = (state) => {
  return filterByDepartment(state, state.audits.nonCompleteAudits).filter(
    (a) => a.ActivityType == "Meeting"
  );
};
